import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '@mui/material'
import { ScheduleShiftHistory, ScheduleUserDayShiftHistoryEntryType } from '../../api/response'
import { formatDateTime, formatTime } from '../../utils/formatDate'
import AddIcon from '@mui/icons-material/AddOutlined'
import RestoreIcon from '@mui/icons-material/RestoreOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import ReplaceEditIcon from '@mui/icons-material/ContentCutOutlined'
import ReplaceDeleteIcon from '@mui/icons-material/PersonRemoveOutlined'
import ReplaceAddIcon from '@mui/icons-material/PersonAddOutlined'
import dayjs from 'dayjs'

const strings = {
    label: {
        title: 'Historia',
        content: (date: string, fullName: string): string => `Historia zmian z dnia ${date} dla ${fullName}`,
        added: 'Pozycja dodana',
        edited: 'Edycja',
        deleted: 'Pozycja usunięta/ukryta',
        restored: 'Pozycja przywrócona',
        replacementAdded: 'Zamiana - Pozycja dodana',
        replacementEdited: 'Zamiana - Edycja',
        replacementDeleted: 'Zamiana - Pozycja usunięta',
    },
    button: {
        close: 'Zamknij',
    },
}

export interface ScheduleShiftHistoryDialogData {
    date: dayjs.Dayjs
    user: { id: number; fullName: string }
    items: ScheduleShiftHistory[]
}

interface OwnProps {
    readonly open: boolean
    readonly data: ScheduleShiftHistoryDialogData
    readonly closeDialog: () => void
}

const ScheduleShiftHistoryDialog: React.FunctionComponent<OwnProps> = ({ open, data, closeDialog }) => {
    const { date, user, items } = data

    return (
        <Dialog
            open={open}
            maxWidth="xs"
            fullWidth={true}
            onClose={closeDialog}
        >
            <DialogTitle>{strings.label.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{strings.label.content(date.format('YYYY-MM-DD'), user.fullName)}</DialogContentText>
                <List>
                    {items.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                {item.entryType === ScheduleUserDayShiftHistoryEntryType.Added && (
                                    <Tooltip title={strings.label.added}>
                                        <AddIcon />
                                    </Tooltip>
                                )}
                                {item.entryType === ScheduleUserDayShiftHistoryEntryType.Edited && (
                                    <Tooltip title={strings.label.edited}>
                                        <EditIcon />
                                    </Tooltip>
                                )}
                                {item.entryType === ScheduleUserDayShiftHistoryEntryType.Removed && (
                                    <Tooltip title={strings.label.deleted}>
                                        <DeleteIcon />
                                    </Tooltip>
                                )}
                                {item.entryType === ScheduleUserDayShiftHistoryEntryType.Restored && (
                                    <Tooltip title={strings.label.restored}>
                                        <RestoreIcon />
                                    </Tooltip>
                                )}
                                {item.entryType === ScheduleUserDayShiftHistoryEntryType.ReplacementAdded && (
                                    <Tooltip title={strings.label.replacementAdded}>
                                        <ReplaceAddIcon />
                                    </Tooltip>
                                )}
                                {item.entryType === ScheduleUserDayShiftHistoryEntryType.ReplacementEdited && (
                                    <Tooltip title={strings.label.replacementEdited}>
                                        <ReplaceEditIcon />
                                    </Tooltip>
                                )}
                                {item.entryType === ScheduleUserDayShiftHistoryEntryType.ReplacementRemoved && (
                                    <Tooltip title={strings.label.replacementDeleted}>
                                        <ReplaceDeleteIcon />
                                    </Tooltip>
                                )}
                            </ListItemIcon>
                            <ListItemText
                                primary={`${formatTime(item.start)} - ${formatTime(item.end)}`}
                                secondary={`${formatDateTime(item.createdAt)}: ${item.user.firstName} ${item.user.lastName}`}
                            />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>{strings.button.close}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(ScheduleShiftHistoryDialog)
