import { ListItemButton, ListItemIcon, ListItemText, SvgIconProps } from '@mui/material'
import React from 'react'
import { MenuItemType } from '../model/MenuItemType'

interface OwnProps {
    onChange: (menuItem: MenuItemType, path: string) => void
    isSelected: boolean
    SvgIcon: React.ComponentType<SvgIconProps>
    menuItem: MenuItemType
    path: string
}

const DrawerListItem: React.FunctionComponent<OwnProps> = ({ onChange, isSelected, menuItem, SvgIcon, path }) => {
    const onListItemClick = React.useCallback((): void => {
        onChange(menuItem, path)
    }, [onChange, menuItem, path])

    return (
        <ListItemButton
            onClick={onListItemClick}
            selected={isSelected}
        >
            <ListItemIcon>
                <SvgIcon />
            </ListItemIcon>
            <ListItemText primary={menuItem} />
        </ListItemButton>
    )
}

export default React.memo(DrawerListItem)
