import { DateTime } from '../DateTime'
import { Location, LocationRequestType } from './Location'

export interface HistoryLog {
    readonly id: number
    readonly type: HistoryLogType
    readonly location: Location | null
    readonly details: HistoryLogDetails
    readonly createdAt: DateTime
}

export interface HistoryLogDetails {
    userId?: number
    userFullName?: string
    branchId?: number
    branchName?: string
    branchIsMain?: boolean
    notificationType?: NotificationType
    sms?: boolean
    body?: string
    phoneToken?: string
    userPhoneToken?: string
    duplicateUserId?: number
    locationRequestType?: LocationRequestType
}

export enum HistoryLogType {
    userLoggedIn = 'user_logged_in',
    userLoggedOut = 'user_logged_out',
    invalidPhoneToken = 'invalid_phone_token',
    phoneTokenReset = 'phone_token_reset',
    branchEntry = 'branch_entry',
    branchExit = 'branch_exit',
    notificationSent = 'notification_sent',
    userLocate = 'user_locate',
}

export enum NotificationType {
    shiftAutoEnd = 'shift_auto_end',
    shiftEndReminder = 'shift_end_reminder',
    taskCreated = 'task_created',
    taskLate = 'task_late',
    requestLocation = 'request_location',
    silentRequestLocation = 'silent_request_location',
}
