export interface Holiday {
    readonly id: number
    readonly name: string
    readonly date: Date
    readonly type: HolidayTypeId
}

export interface HolidayType {
    readonly id: HolidayTypeId
    readonly name: string
}

export enum HolidayTypeId {
    National = 'national',
    Other = 'other',
}

export const holidayTypes: HolidayType[] = [
    { id: HolidayTypeId.National, name: 'Państwowe' },
    { id: HolidayTypeId.Other, name: 'Inne' },
]

export const holidayTypeName = (typeId: HolidayTypeId): string => {
    for (const type of holidayTypes) {
        if (type.id === typeId) {
            return type.name
        }
    }

    return ''
}

export const holidayType = (typeId: HolidayTypeId): HolidayType => {
    for (const type of holidayTypes) {
        if (type.id === typeId) {
            return type
        }
    }

    return holidayTypes[0]
}
