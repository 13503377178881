import React, { useCallback } from 'react'
import { TableCell, TableRow, Tooltip, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { formatDate } from '../../utils/formatDate'
import { UserBranch } from '../../api/response'

const strings = {
    button: {
        delete: 'Usuń',
        edit: 'Edytuj',
    },
}

interface OwnProps {
    readonly additionalBranch: UserBranch
    readonly editUserBranch: (additionalBranch: UserBranch) => void
    readonly deleteUserBranch: (additionalBranch: UserBranch) => void
}

const UserBranchTableRow: React.FunctionComponent<OwnProps> = ({ additionalBranch, editUserBranch, deleteUserBranch }) => {
    const handleEdit = useCallback(() => {
        editUserBranch(additionalBranch)
    }, [additionalBranch])

    const handleDelete = useCallback(() => {
        deleteUserBranch(additionalBranch)
    }, [additionalBranch])

    return (
        <TableRow hover={true}>
            <TableCell>{additionalBranch.id}</TableCell>
            <TableCell>{additionalBranch.branch.name}</TableCell>
            <TableCell>
                {formatDate(additionalBranch.start)} - {formatDate(additionalBranch.end)}
            </TableCell>
            <TableCell>
                <Tooltip title={strings.button.edit}>
                    <IconButton onClick={handleEdit}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={strings.button.delete}>
                    <IconButton onClick={handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}

export default React.memo(UserBranchTableRow)
