import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useStateDispatchContext } from '../../state/stateContext'
import { changeMenuItem } from '../../state/menuItemActions'
import { MenuItemType } from '../../model/MenuItemType'
import { setTitle } from '../../state/titleActions'
import { API } from '../../api/api'
import { handleApiError } from '../../state/apiErrorActions'
import { Button, CircularProgress, Paper, Stack, TextField, styled } from '@mui/material'
import { useStableNavigate } from '../../routes/StableNavigateContext'
import { routesDetails } from '../../routes/routesDetails'
import _ from 'lodash'
import Validator from 'validator'
import { ForbiddenPlaceRequest } from '../../api/request'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { ForbiddenPlace } from '../../api/response'
import { showSnackbarMessage } from '../../state/messageActions'

const strings = {
    button: {
        refresh: 'Odśwież',
        save: 'Zapisz',
    },
    error: {
        nameLength: 'Wymagana wartość w przedziale <1, 255> znaków',
        nameRequired: 'Nazwa jest wymagana',
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
    label: {
        name: 'Nazwa',
    },
    message: {
        added: 'Nowe miejsce zostało dodane',
        edited: 'Miejsce zostało zaktualizowane',
    },
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
}))

enum FormFieldNames {
    Name = 'name',
}

interface FormErrors {
    name?: string
}

const ForbiddenPlaceDetailsPage: React.FunctionComponent = () => {
    const { appDispatch } = useStateDispatchContext()
    const navigate = useStableNavigate()
    const { id } = useParams()

    const [loading, setLoading] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)
    const [errors, setErrors] = useState<FormErrors>({})
    const [name, setName] = useState<string>('')

    const forbiddenPlaceId = useMemo(() => {
        return parseInt(id ?? '0', 10)
    }, [id])

    const loadForbiddenPlace = useCallback(() => {
        if (forbiddenPlaceId === 0) {
            setLoaded(true)

            return
        }

        if (loading) {
            return
        }

        setLoading(true)
        setRefresh(false)
        API.forbiddenPlaces
            .details(forbiddenPlaceId)
            .then((forbiddenPlace) => {
                setName(forbiddenPlace.name)
                setLoaded(true)
            })
            .catch((error) => {
                setRefresh(true)
                appDispatch(handleApiError(error))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [forbiddenPlaceId, loading])

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        switch (event.currentTarget.name) {
            case FormFieldNames.Name: {
                setName(event.target.value)
                break
            }
        }
    }, [])

    function handleSaveClick(): void {
        const errors: FormErrors = {}

        if (Validator.isEmpty(name)) {
            errors.name = strings.error.nameRequired
        } else if (!Validator.isLength(name, { min: 1, max: 128 })) {
            errors.name = strings.error.nameLength
        }

        setErrors(errors)

        if (!_.isEmpty(errors)) {
            return
        }

        const request = new ForbiddenPlaceRequest(name)

        appDispatch(setInProgress())

        const promise: Promise<ForbiddenPlace> =
            forbiddenPlaceId !== 0 ? API.forbiddenPlaces.edit(forbiddenPlaceId, request) : API.forbiddenPlaces.add(request)

        promise
            .then((response) => {
                appDispatch(setSuccess())

                if (forbiddenPlaceId === 0) {
                    appDispatch(showSnackbarMessage(strings.message.added))
                    navigate(routesDetails.authenticated.forbiddenPlace.to(response.id), { replace: true })
                } else {
                    appDispatch(showSnackbarMessage(strings.message.edited))
                }
            })
            .catch((error) => {
                appDispatch(setFailure())
                appDispatch(handleApiError(error))

                if (error === null) {
                    appDispatch(showSnackbarMessage(strings.error.unknown))
                }
            })
    }

    useEffect(() => {
        appDispatch(changeMenuItem(MenuItemType.ForbiddenPlaces))
        appDispatch(setTitle(MenuItemType.ForbiddenPlaces))
    }, [])

    useEffect(() => {
        const number = parseInt(id ?? '0', 10)
        if (isNaN(number)) {
            navigate(routesDetails.authenticated.forbiddenPlace.to(0), { replace: true })
        } else {
            loadForbiddenPlace()
        }
    }, [])

    return (
        <StyledPaper elevation={2}>
            {loading && (
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Stack>
            )}

            {refresh && !loading && (
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <Button
                        onClick={loadForbiddenPlace}
                        variant="contained"
                    >
                        {strings.button.refresh}
                    </Button>
                </Stack>
            )}

            {loaded && (
                <Stack
                    direction="column"
                    spacing={2}
                    ml={3}
                    mr={3}
                >
                    <TextField
                        id={FormFieldNames.Name}
                        name={FormFieldNames.Name}
                        value={name}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.name}
                        margin="none"
                        onChange={onTextFieldChange}
                        helperText={errors.name}
                        error={errors.name !== undefined}
                    />

                    <Stack
                        direction="row"
                        justifyContent="left"
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSaveClick}
                        >
                            {strings.button.save}
                        </Button>
                    </Stack>
                </Stack>
            )}
        </StyledPaper>
    )
}

export default React.memo(ForbiddenPlaceDetailsPage)
