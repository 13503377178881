import React from 'react'
import GlobalAppBar from './GlobalAppBar'
import AppDrawer from './AppDrawer'
import { Box, styled } from '@mui/material'
import { Outlet } from 'react-router'
import { logout, useStateContext, useStateDispatchContext } from '../state/stateContext'
import { API } from '../api/api'
import { setAccount } from '../state/accountActions'
import _ from 'lodash'
import localStore from '../state/LocalStore'
import { handleApiError } from '../state/apiErrorActions'
import { ApiError } from '../api/ApiError'
import { showDialogMessage } from '../state/messageActions'

export const mainPadding = 3

const strings = {
    error: {
        account403: 'Brak dostępu do panelu, nastąpi wylogowanie.',
        account401: 'Sesja wygasła, nastąpi wylogowanie.',
        account400: 'Wystąpił błąd w trakcie aktualizacji konta, nastąpi wylogowanie.',
    },
}

const RootDiv = styled('div')({
    display: 'flex',
})

const ContentMain = styled('main')(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(mainPadding),
}))

const ToolbarDiv = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
}))

const AppCanvas: React.FunctionComponent = () => {
    const { sessionState } = useStateContext()
    const { appDispatch, sessionDispatch } = useStateDispatchContext()

    if (sessionState.auth === null) {
        return <Outlet />
    }

    React.useEffect(() => {
        API.account
            .me()
            .then((account) => {
                if (!_.isEqual(account, sessionState.account)) {
                    localStore.storeAccount(account)
                    sessionDispatch(setAccount(account))
                }
            })
            .catch((error) => {
                let handled = false
                if (error instanceof ApiError) {
                    if (error.status >= 400 && error.status < 500) {
                        let message = strings.error.account400
                        if (error.status === 401) {
                            message = strings.error.account401
                        } else if (error.status === 403) {
                            message = strings.error.account403
                        }

                        appDispatch(
                            showDialogMessage(message, () => {
                                logout(sessionDispatch)
                            })
                        )
                        handled = true
                    }
                }

                if (!handled) {
                    appDispatch(handleApiError(error))
                }
            })
    }, [sessionState.account])

    return (
        <RootDiv>
            <GlobalAppBar />
            <AppDrawer />
            <ContentMain>
                <ToolbarDiv />
                <Outlet />
            </ContentMain>
        </RootDiv>
    )
}

export default React.memo(AppCanvas)

export const withAppCanvas = <P extends {}>(Component: React.ComponentType<P>): React.FunctionComponent<P> => {
    const appCanvas: React.FunctionComponent<P> = (props: P): React.ReactElement => (
        <RootDiv>
            <GlobalAppBar />
            <AppDrawer />
            <ContentMain>
                <ToolbarDiv />
                <Component {...(props as P)} />
            </ContentMain>
        </RootDiv>
    )

    return React.memo(appCanvas)
}
