import React, { useCallback, useState } from 'react'
import { ScheduleUser, ScheduleUserStatus, UserDayShift } from '../../api/response'
import dayjs from 'dayjs'
import { ListItemIcon, ListItemText, Menu, MenuItem, MenuList, PopoverPosition, styled } from '@mui/material'
import { ScheduleShiftDialogData } from './ScheduleShiftDialog'
import ScheduleTableBodyDay from './ScheduleTableBodyDay'
import _ from 'lodash'
import { ScheduleDayDialogData } from './ScheduleDayDialog'
import { UserShiftDialogData } from './UserShiftDialog'
import { SplitUserShiftDialogData } from './SplitUserShiftDialog'
import ExportIcon from '@mui/icons-material/FileDownloadOutlined'
import { ScheduleShiftHistoryDialogData } from './ScheduleShiftHistoryDialog'
import { UserShiftHistoryDialogData } from './UserShiftHistoryDialog'

const strings = {
    button: {
        export: 'Eksportuj harmonogram',
    },
}

const StyledUserName = styled('th')<{ fired: boolean }>(({ theme, fired }) => ({
    ...theme.typography.body1,
    textAlign: 'right',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: '#eeeeee',
    textDecoration: fired ? 'line-through' : 'none',
}))

const StyledDisabledDay = styled('td')({
    backgroundColor: '#000',
})

interface OwnProps {
    readonly scheduleId: string
    readonly date: dayjs.Dayjs
    readonly showAll: boolean
    readonly user: ScheduleUser
    readonly dayColors: string[]
    readonly editableDays: boolean[]
    readonly canShowHistory: boolean
    readonly canAddUserShifts: boolean
    readonly canEditUserShifts: boolean
    readonly canHideUserShifts: boolean
    readonly canEndUserShifts: boolean
    readonly canExportUserSchedule: boolean
    readonly openScheduleShiftHistoryDialog: (data: ScheduleShiftHistoryDialogData) => void
    readonly openUserShiftHistoryDialog: (data: UserShiftHistoryDialogData) => void
    readonly openDayDialog: (data: ScheduleDayDialogData) => void
    readonly openScheduleShiftDialog: (data: ScheduleShiftDialogData) => void
    readonly openUserShiftDialog: (data: UserShiftDialogData) => void
    readonly openSplitUserShiftDialog: (data: SplitUserShiftDialogData) => void
    readonly exportUserSchedule: (user: ScheduleUser) => void
}

const ScheduleTableBodyRow: React.FunctionComponent<OwnProps> = ({
    scheduleId,
    date,
    showAll,
    user,
    dayColors,
    editableDays,
    canShowHistory,
    canAddUserShifts,
    canEditUserShifts,
    canHideUserShifts,
    canEndUserShifts,
    canExportUserSchedule,
    openScheduleShiftHistoryDialog,
    openUserShiftHistoryDialog,
    openDayDialog,
    openScheduleShiftDialog,
    openUserShiftDialog,
    openSplitUserShiftDialog,
    exportUserSchedule,
}) => {
    const [menuPosition, setMenuPosition] = useState<null | PopoverPosition>(null)
    const [dayShiftUpdaters, setDayShiftUpdaters] = useState<Map<number, (shift: UserDayShift) => void>>(new Map())

    const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault()
        event.stopPropagation()
        setMenuPosition({ top: event.clientY + 10, left: event.clientX + 10 })
    }, [])

    const closeMenu = useCallback(() => {
        setMenuPosition(null)
    }, [])

    const handleExport = useCallback((): void => {
        setMenuPosition(null)

        exportUserSchedule(user)
    }, [user])

    const registerDayUpdater = useCallback((day: number, updater: (shift: UserDayShift) => void): void => {
        setDayShiftUpdaters((value) => {
            const map = new Map(value)
            map.set(day, updater)

            return map
        })
    }, [])

    const updateDay = useCallback(
        (shift: UserDayShift): void => {
            const date = shift.edited?.start ?? shift.start
            const dayShiftUpdater = dayShiftUpdaters.get(date.getDate())

            if (dayShiftUpdater === undefined) {
                return
            }

            dayShiftUpdater(shift)
        },
        [dayShiftUpdaters]
    )

    return (
        <>
            <tr>
                <StyledUserName
                    fired={user.status !== ScheduleUserStatus.Active}
                    onClick={canExportUserSchedule ? handleOpenMenu : undefined}
                >
                    {user.firstName} {user.lastName}
                </StyledUserName>

                {_.range(1, date.daysInMonth() + 1, 1).map((day, index) => {
                    if (user.disabledDays.includes(day)) {
                        return <StyledDisabledDay key={index}>&nbsp;</StyledDisabledDay>
                    }

                    return (
                        <ScheduleTableBodyDay
                            key={index}
                            scheduleId={scheduleId}
                            user={user}
                            date={date.date(day)}
                            showAll={showAll}
                            dayColor={dayColors[day - 1]}
                            isEditable={editableDays[day - 1]}
                            canShowHistory={canShowHistory}
                            canAddUserShifts={canAddUserShifts}
                            canEditUserShifts={canEditUserShifts}
                            canHideUserShifts={canHideUserShifts}
                            canEndUserShifts={canEndUserShifts}
                            openScheduleShiftHistoryDialog={openScheduleShiftHistoryDialog}
                            openUserShiftHistoryDialog={openUserShiftHistoryDialog}
                            openDayDialog={openDayDialog}
                            openScheduleShiftDialog={openScheduleShiftDialog}
                            openUserShiftDialog={openUserShiftDialog}
                            openSplitUserShiftDialog={openSplitUserShiftDialog}
                            registerDayUpdater={registerDayUpdater}
                            updateDay={updateDay}
                        />
                    )
                })}
            </tr>

            <Menu
                open={menuPosition !== null}
                anchorPosition={menuPosition ?? undefined}
                anchorReference="anchorPosition"
                onClose={closeMenu}
                autoFocus={false}
            >
                <MenuList disablePadding={true}>
                    <MenuItem onClick={handleExport}>
                        <ListItemIcon>
                            <ExportIcon />
                        </ListItemIcon>
                        <ListItemText>{strings.button.export}</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    )
}

export default React.memo(ScheduleTableBodyRow)
