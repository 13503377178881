import React, { useEffect, useState } from 'react'
import { ScheduleUserDayShift, ScheduleUserDayShiftFlag } from '../../api/response'
import dayjs, { Dayjs } from 'dayjs'
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { TimePicker, TimeValidationError } from '@mui/x-date-pickers'
import { useCallback } from 'react'
import { useStateDispatchContext } from '../../state/stateContext'
import { API } from '../../api/api'
import { ScheduleShiftRequest } from '../../api/request'
import { handleApiError } from '../../state/apiErrorActions'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals'

const strings = {
    label: {
        title: 'Okres pracy',
        content: (date: string, fullName: string): string => `Ustaw dane okresu pracy dla ${fullName} w ${date}`,
        morning: 'Poranna',
        night: 'Nocna',
        training: 'Szkolenie',
        late: 'Spóźnienie',
        note: 'Uwagi',
        flags: 'Dodatkowe oznaczenia',
        from: 'Od:',
        to: 'Do:',
        nextDay: 'Okres na przełomie dni',
    },
    button: {
        cancel: 'Anuluj',
        save: 'Zapisz',
    },
}

enum FormFieldNames {
    From = 'from',
    To = 'to',
    Morning = 'morning',
    Night = 'night',
    Training = 'training',
    Late = 'late',
    Note = 'note',
}

export interface ScheduleShiftDialogData {
    scheduleId: string
    date: dayjs.Dayjs
    user: { id: number; fullName: string }
    shift: ScheduleUserDayShift | null
    onSuccess: (shift: ScheduleUserDayShift) => void
}

interface OwnProps {
    readonly open: boolean
    readonly data: ScheduleShiftDialogData
    readonly closeDialog: () => void
}

const ScheduleShiftDialog: React.FunctionComponent<OwnProps> = ({ open, data, closeDialog }) => {
    const { date, user, onSuccess } = data

    const [startTime, setStartTime] = useState<Dayjs | null>(null)
    const [endTime, setEndTime] = useState<Dayjs | null>(null)
    const [morningFlag, setMorningFlag] = useState<boolean>(false)
    const [nightFlag, setNightFlag] = useState<boolean>(false)
    const [trainingFlag, setTrainingFlag] = useState<boolean>(false)
    const [lateFlag, setLateFlag] = useState<boolean>(false)
    const [note, setNote] = useState<string>('')

    const { appDispatch } = useStateDispatchContext()

    useEffect(() => {
        const shift = data.shift

        if (open && shift !== null) {
            setStartTime(dayjs(shift.start))
            setEndTime(dayjs(shift.end))
            setMorningFlag(shift.flags.includes(ScheduleUserDayShiftFlag.Morning))
            setNightFlag(shift.flags.includes(ScheduleUserDayShiftFlag.Night))
            setTrainingFlag(shift.flags.includes(ScheduleUserDayShiftFlag.Training))
            setLateFlag(shift.flags.includes(ScheduleUserDayShiftFlag.Late))
            setNote(shift.note ?? '')
        } else {
            setStartTime(null)
            setEndTime(null)
            setMorningFlag(false)
            setNightFlag(false)
            setTrainingFlag(false)
            setLateFlag(false)
            setNote('')
        }
    }, [open, data])

    const onStartTimeChange = useCallback((value: Dayjs | null, context: FieldChangeHandlerContext<TimeValidationError>) => {
        if (value && (!context.validationError || context.validationError === 'minutesStep')) {
            setStartTime(value)
        }
    }, [])

    const onEndTimeChange = useCallback(
        (value: Dayjs | null, context: FieldChangeHandlerContext<TimeValidationError>) => {
            if (value && (!context.validationError || context.validationError === 'minutesStep')) {
                let date = value

                if (!date.isSame(startTime, 'day')) {
                    date = dayjs(startTime).set('hour', value.hour()).set('minute', value.minute()).set('second', 0)
                }

                if (!date.isAfter(startTime, 'minute')) {
                    date = date.add(1, 'day')
                }

                setEndTime(date)
            }
        },
        [startTime]
    )

    const onChackboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        switch (event.currentTarget.name) {
            case FormFieldNames.Morning: {
                setMorningFlag(checked)
                break
            }
            case FormFieldNames.Night: {
                setNightFlag(checked)
                break
            }
            case FormFieldNames.Training: {
                setTrainingFlag(checked)
                break
            }
            case FormFieldNames.Late: {
                setLateFlag(checked)
                break
            }
        }
    }, [])

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setNote(event.target.value)
    }, [])

    function validateTime(time: Dayjs | null): Dayjs | null {
        if (time !== null && time.minute() !== 0 && time.minute() !== 30) {
            if (time.minute() <= 15) {
                return time.minute(0)
            } else if (time.minute() <= 45) {
                return time.minute(30)
            } else if (time.hour() < 23) {
                return time.add(1, 'hour').minute(0)
            }

            return time.minute(30)
        }

        return time
    }

    function validateTimeValues(): void {
        setStartTime(validateTime(startTime))
        setEndTime(validateTime(endTime))
    }

    function handleSaveClick(): void {
        const requestStartTime = validateTime(startTime)
        const requestEndTime = validateTime(endTime)

        setStartTime(requestStartTime)
        setEndTime(requestEndTime)

        if (requestStartTime === null || requestEndTime === null) {
            return
        }

        const flags: ScheduleUserDayShiftFlag[] = []
        if (data.shift !== null && data.shift.flags.includes(ScheduleUserDayShiftFlag.Checked)) {
            flags.push(ScheduleUserDayShiftFlag.Checked)
        }
        if (data.shift !== null && data.shift.flags.includes(ScheduleUserDayShiftFlag.Absence)) {
            flags.push(ScheduleUserDayShiftFlag.Absence)
        }

        if (morningFlag) {
            flags.push(ScheduleUserDayShiftFlag.Morning)
        }
        if (nightFlag) {
            flags.push(ScheduleUserDayShiftFlag.Night)
        }
        if (trainingFlag) {
            flags.push(ScheduleUserDayShiftFlag.Training)
        }
        if (lateFlag) {
            flags.push(ScheduleUserDayShiftFlag.Late)
        }

        const request = new ScheduleShiftRequest(
            data.user.id,
            data.date.date(),
            requestStartTime.format('HH:mm'),
            requestEndTime.format('HH:mm'),
            note.length > 0 ? note : null,
            flags
        )

        const shift = data.shift
        const promise: Promise<ScheduleUserDayShift> =
            shift !== null ? API.schedule.shift.update(data.scheduleId, shift.id, request) : API.schedule.shift.create(data.scheduleId, request)

        appDispatch(setInProgress())

        promise
            .then((shift) => {
                onSuccess(shift)
                appDispatch(setSuccess())
                closeDialog()
            })
            .catch((error) => {
                appDispatch(setFailure())
                appDispatch(handleApiError(error))
            })
    }

    return (
        <Dialog
            open={open}
            disableEscapeKeyDown={true}
        >
            <DialogTitle>{strings.label.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{strings.label.content(date.format('YYYY-MM-DD'), user.fullName)}</DialogContentText>
                <Stack
                    direction="column"
                    spacing={2}
                >
                    <Stack
                        mt={2}
                        direction="row"
                        alignItems="stretch"
                        spacing={2}
                    >
                        <TimePicker
                            ampm={false}
                            ampmInClock={false}
                            label={strings.label.from}
                            format="HH:mm"
                            minutesStep={30}
                            timeSteps={{ hours: 1, minutes: 30 }}
                            sx={{ width: '100%' }}
                            onChange={onStartTimeChange}
                            value={startTime}
                            slotProps={{
                                textField: {
                                    onBlur: validateTimeValues,
                                },
                            }}
                        />

                        <TimePicker
                            ampm={false}
                            sx={{ width: '100%' }}
                            ampmInClock={false}
                            label={strings.label.to}
                            format="HH:mm"
                            minutesStep={30}
                            timeSteps={{ hours: 1, minutes: 30 }}
                            onChange={onEndTimeChange}
                            slotProps={{
                                textField: {
                                    helperText:
                                        startTime !== null &&
                                        endTime !== null &&
                                        (!endTime.isAfter(startTime, 'minutes') || !endTime.isSame(startTime, 'date'))
                                            ? strings.label.nextDay
                                            : null,
                                    onBlur: validateTimeValues,
                                },
                            }}
                            value={endTime}
                        />
                    </Stack>

                    <Box>
                        <Typography>{strings.label.flags}</Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={morningFlag}
                                        id={FormFieldNames.Morning}
                                        name={FormFieldNames.Morning}
                                        onChange={onChackboxChange}
                                    />
                                }
                                label={strings.label.morning}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={nightFlag}
                                        id={FormFieldNames.Night}
                                        name={FormFieldNames.Night}
                                        onChange={onChackboxChange}
                                    />
                                }
                                label={strings.label.night}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={trainingFlag}
                                        id={FormFieldNames.Training}
                                        name={FormFieldNames.Training}
                                        onChange={onChackboxChange}
                                    />
                                }
                                label={strings.label.training}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={lateFlag}
                                        id={FormFieldNames.Late}
                                        name={FormFieldNames.Late}
                                        onChange={onChackboxChange}
                                    />
                                }
                                label={strings.label.late}
                            />
                        </FormGroup>
                    </Box>

                    <TextField
                        id={FormFieldNames.Note}
                        name={FormFieldNames.Note}
                        value={note}
                        multiline={false}
                        required={false}
                        fullWidth={true}
                        label={strings.label.note}
                        inputProps={{ maxlength: 255 }}
                        margin="none"
                        onChange={onTextFieldChange}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>{strings.button.cancel}</Button>
                <Button
                    onClick={handleSaveClick}
                    disabled={startTime === null || endTime === null}
                >
                    {strings.button.save}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(ScheduleShiftDialog)
