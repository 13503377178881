import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Snackbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { MessageDisplayType, hideMessage } from '../../state/messageActions'
import { useStateContext, useStateDispatchContext } from '../../state/stateContext'

const strings = {
    button: {
        ok: 'OK',
    },
}

interface OwnProps {
    message: string
    clear: () => void
}

const MessageDialog: React.FunctionComponent<OwnProps> = ({ message, clear }) => {
    const onDialogOkClick = React.useCallback((): void => {
        clear()
    }, [clear])

    return (
        <Dialog
            open={true}
            disableEscapeKeyDown={true}
        >
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDialogOkClick}>{strings.button.ok}</Button>
            </DialogActions>
        </Dialog>
    )
}

const MessageSnackbar: React.FunctionComponent<OwnProps> = ({ message, clear }) => {
    const onMessageClose = React.useCallback((): void => {
        clear()
    }, [clear])

    return (
        <Snackbar
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
            }}
            open={message !== null}
            autoHideDuration={6000}
            onClose={onMessageClose}
            message={<span>{message}</span>}
            action={[
                <IconButton
                    key="close"
                    color="inherit"
                    onClick={onMessageClose}
                >
                    <CloseIcon />
                </IconButton>,
            ]}
        />
    )
}

const MessageView: React.FunctionComponent = () => {
    const { appState } = useStateContext()
    const { appDispatch } = useStateDispatchContext()

    const { message } = appState

    const clearMessage = React.useCallback((): void => {
        appDispatch(hideMessage())

        if (message !== null && message.handler !== undefined) {
            message.handler()
        }
    }, [message])

    if (message === null) {
        return null
    } else if (message.type === MessageDisplayType.Dialog) {
        return (
            <MessageDialog
                message={message.message}
                clear={clearMessage}
            />
        )
    } else if (message.type === MessageDisplayType.Snackbar) {
        return (
            <MessageSnackbar
                message={message.message}
                clear={clearMessage}
            />
        )
    }
    return null
}

export default React.memo(MessageView)
