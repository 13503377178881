import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../state/stateContext'
import { Box, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Tooltip } from '@mui/material'
import { Branch, JobPositionUsers, UserInfo } from '../../api/response'
import { API } from '../../api/api'
import AddIcon from '@mui/icons-material/Add'

const strings = {
    label: {
        branch: 'Oddział',
        user: 'Użytkownik',
    },
    button: {
        add: 'Dodaj zadanie',
    },
}

interface OwnProps {
    readonly updateBranchId: (branchId: number) => void
    readonly updateUserId: (userId: number) => void
    readonly openAddDialog: () => void
    readonly branchId: number
    readonly userId: number | null
}

const TasksHeader: React.FunctionComponent<OwnProps> = ({ updateBranchId, updateUserId, openAddDialog, branchId, userId }) => {
    const { sessionState } = useStateContext()

    const [branches, setBranches] = useState<Branch[]>(() => [sessionState.account!.branch])
    const [jobPositions, setJobPositions] = useState<JobPositionUsers[]>(() => [])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        API.tasks.branches().then((response) => {
            setBranches(response)
        })
    }, [])

    useEffect(() => {
        setLoading(true)
        setJobPositions([])

        API.tasks
            .users(branchId)
            .then((users) => {
                setJobPositions(users)

                if (userId === null && users.length > 0 && users[0].users.length > 0) {
                    updateUserId(users[0].users[0].id)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }, [branchId])

    return (
        <Stack
            direction="row"
            spacing={1}
            ml={3}
            mr={3}
        >
            <Box sx={{ mt: 1 }}>
                <Tooltip title={strings.button.add}>
                    <IconButton onClick={openAddDialog}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            <Box sx={{ minWidth: 280 }}>
                <FormControl fullWidth={true}>
                    <InputLabel>{strings.label.branch}</InputLabel>
                    <Select
                        id="branch"
                        name="branch"
                        value={branchId.toString()}
                        label={strings.label.branch}
                        onChange={(event: SelectChangeEvent): void => {
                            updateBranchId(parseInt(event.target.value))
                        }}
                    >
                        {branches.map(
                            (value: Branch, index: number): React.ReactNode => (
                                <MenuItem
                                    key={index}
                                    value={value.id}
                                >
                                    {value.name}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
            </Box>

            {loading && (
                <Box>
                    <CircularProgress />
                </Box>
            )}

            {jobPositions.length > 0 && (
                <Box sx={{ minWidth: 280 }}>
                    <FormControl fullWidth={true}>
                        <InputLabel>{strings.label.user}</InputLabel>
                        <Select
                            id="user"
                            name="user"
                            value={userId?.toString() ?? ''}
                            label={strings.label.user}
                            onChange={(event: SelectChangeEvent): void => {
                                updateUserId(parseInt(event.target.value))
                            }}
                        >
                            {jobPositions.map((jobPosition: JobPositionUsers, groupIndex: number): React.ReactNode => {
                                const group = (
                                    <MenuItem
                                        disabled={true}
                                        key={groupIndex}
                                        value={-1}
                                    >
                                        {jobPosition.name}
                                    </MenuItem>
                                )

                                const items = jobPosition.users.map(
                                    (user: UserInfo, itemIndex: number): React.ReactNode => (
                                        <MenuItem
                                            key={`${groupIndex}.${itemIndex}`}
                                            value={user.id}
                                        >
                                            {user.firstName} {user.lastName}
                                        </MenuItem>
                                    )
                                )

                                return [group, ...items]
                            })}
                        </Select>
                    </FormControl>
                </Box>
            )}
        </Stack>
    )
}

export default React.memo(TasksHeader)
