import _ from 'lodash'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import GuestRoute from '../routes/GuestRoute'
import AuthenticatedRoute from './AuthenticatedRoute'
import { routesDetails } from './routesDetails'
import AppCanvas from '../components/AppCanvas'
import { StableNavigateContextProvider } from './StableNavigateContext'

const AppRoutes: React.FunctionComponent = () => (
    <BrowserRouter>
        <StableNavigateContextProvider>
            <Routes>
                <Route element={<AppCanvas />}>
                    {_.values(routesDetails.authenticated).map((route, index) => (
                        <Route
                            key={index}
                            caseSensitive={true}
                            index
                            path={route.path}
                            element={
                                <AuthenticatedRoute hasAccess={route.hasAccess}>
                                    <route.Component />
                                </AuthenticatedRoute>
                            }
                        />
                    ))}
                </Route>
                {_.values(routesDetails.guest).map((route, index) => (
                    <Route
                        key={index}
                        caseSensitive={true}
                        path={route.path}
                        element={
                            <GuestRoute>
                                <route.Component />
                            </GuestRoute>
                        }
                    />
                ))}
                <Route
                    caseSensitive={true}
                    path={routesDetails.error.unauthorized.path}
                    element={<routesDetails.error.unauthorized.Component />}
                />
                <Route
                    path="*"
                    element={<routesDetails.error.notFound.Component />}
                />
            </Routes>
        </StableNavigateContextProvider>
    </BrowserRouter>
)

export default React.memo(AppRoutes)
