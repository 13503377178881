import { Grid, Typography } from '@mui/material'
import React from 'react'

const strings = {
    error: {
        notFound: '404 - Wybrana strona nie istnieje',
    },
}

const NotFoundPage: React.FunctionComponent = () => (
    <Grid
        container={true}
        justifyContent="center"
    >
        <Grid item={true}>
            <Typography variant="h5">{strings.error.notFound}</Typography>
        </Grid>
    </Grid>
)

export default React.memo(NotFoundPage)
