import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useStateContext } from '../../state/stateContext'
import {
    Box,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    Tooltip,
} from '@mui/material'
import { Branch } from '../../api/response'
import dayjs from 'dayjs'
import _ from 'lodash'
import { API } from '../../api/api'
import { MouseEvent } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos'
import ExportIcon from '@mui/icons-material/SaveAltOutlined'

const strings = {
    label: {
        branch: 'Oddział',
        year: 'Rok',
        month: 'Miesiąc',
        showAll: 'Pokaż wszystkie',
    },
    button: {
        prevMonth: 'Poprzedni miesiąc',
        nextMonth: 'Następny miesiąc',
        export: 'Eksportuj',
    },
}

interface Month {
    readonly id: number
    readonly name: string
}

const months: Month[] = [
    { id: 1, name: 'Styczeń' },
    { id: 2, name: 'Luty' },
    { id: 3, name: 'Marzec' },
    { id: 4, name: 'Kwiecień' },
    { id: 5, name: 'Maj' },
    { id: 6, name: 'Czerwiec' },
    { id: 7, name: 'Lipiec' },
    { id: 8, name: 'Sierpień' },
    { id: 9, name: 'Wrzesień' },
    { id: 10, name: 'Październik' },
    { id: 11, name: 'Listopad' },
    { id: 12, name: 'Grudzień' },
]

interface OwnProps {
    readonly updateYear: (year: number) => void
    readonly updateMonth: (month: number) => void
    readonly updateBranchId: (branchId: number) => void
    readonly updateShowAll: (showAll: boolean) => void
    readonly year: number
    readonly month: number
    readonly branchId: number
    readonly canShowAll: boolean
    readonly showAll: boolean
    readonly canExportSchedule: boolean
    readonly exportSchedule: () => void
}

const ScheduleHeader: React.FunctionComponent<OwnProps> = ({
    updateYear,
    updateMonth,
    updateBranchId,
    updateShowAll,
    year,
    month,
    branchId,
    canShowAll,
    showAll,
    canExportSchedule,
    exportSchedule,
}) => {
    const today = useMemo(() => dayjs().startOf('day'), [])
    const years = useMemo(() => _.range(2023, today.year() + 2, 1), [])

    const { sessionState } = useStateContext()

    const [branches, setBranches] = useState<Branch[]>(() => [sessionState.account!.branch])

    function onPrevMonthClick(event: MouseEvent<HTMLButtonElement>): void {
        event.preventDefault()

        const newDate = dayjs()
            .year(year)
            .month(month - 1)
            .date(1)
            .add(-1, 'month')

        if (years.includes(newDate.year())) {
            updateYear(newDate.year())
            updateMonth(newDate.month() + 1)
        }
    }

    function onNextMonthClick(event: MouseEvent<HTMLButtonElement>): void {
        event.preventDefault()

        const newDate = dayjs()
            .year(year)
            .month(month - 1)
            .date(1)
            .add(1, 'month')

        if (years.includes(newDate.year())) {
            updateYear(newDate.year())
            updateMonth(newDate.month() + 1)
        }
    }

    const onSwitchChange = useCallback((_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        updateShowAll(checked)
    }, [])

    useEffect(() => {
        API.schedule.branches().then((response) => {
            setBranches(response)
        })
    }, [])

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            ml={3}
            mr={3}
        >
            <Stack
                direction="row"
                alignItems="center"
                spacing={1}
            >
                <Tooltip title={strings.button.prevMonth}>
                    <IconButton
                        onClick={onPrevMonthClick}
                        size="large"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>

                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth={true}>
                        <InputLabel>{strings.label.year}</InputLabel>
                        <Select
                            id="year"
                            name="year"
                            value={year.toString()}
                            label={strings.label.year}
                            onChange={(event: SelectChangeEvent): void => {
                                updateYear(parseInt(event.target.value))
                            }}
                        >
                            {years.map(
                                (value: number, index: number): React.ReactNode => (
                                    <MenuItem
                                        key={index}
                                        value={value}
                                    >
                                        {value}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth={true}>
                        <InputLabel>{strings.label.month}</InputLabel>
                        <Select
                            id="month"
                            name="month"
                            value={month.toString()}
                            label={strings.label.month}
                            onChange={(event: SelectChangeEvent): void => {
                                updateMonth(parseInt(event.target.value))
                            }}
                        >
                            {months.map(
                                (value: Month, index: number): React.ReactNode => (
                                    <MenuItem
                                        key={index}
                                        value={value.id}
                                    >
                                        {value.name}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </Box>

                <Tooltip title={strings.button.nextMonth}>
                    <IconButton
                        onClick={onNextMonthClick}
                        size="large"
                    >
                        <ArrowForwardIcon />
                    </IconButton>
                </Tooltip>

                {canShowAll && (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showAll}
                                onChange={onSwitchChange}
                            />
                        }
                        label={strings.label.showAll}
                    />
                )}
            </Stack>

            <Stack
                direction="row"
                alignItems="center"
                spacing={1}
            >
                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth={true}>
                        <InputLabel>{strings.label.branch}</InputLabel>
                        <Select
                            id="branch"
                            name="branch"
                            value={branchId.toString()}
                            label={strings.label.branch}
                            onChange={(event: SelectChangeEvent): void => {
                                updateBranchId(parseInt(event.target.value))
                            }}
                        >
                            {branches.map(
                                (value: Branch, index: number): React.ReactNode => (
                                    <MenuItem
                                        key={index}
                                        value={value.id}
                                    >
                                        {value.name}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </Box>

                {canExportSchedule && (
                    <Tooltip title={strings.button.export}>
                        <IconButton
                            onClick={exportSchedule}
                            size="large"
                        >
                            <ExportIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Stack>
        </Stack>
    )
}

export default React.memo(ScheduleHeader)
