import React, { useCallback, useState } from 'react'
import { ScheduleUserDayShift, ScheduleUserDayShiftFlag } from '../../api/response'
import { Stack, Container, Tooltip, PopoverPosition, Menu, MenuItem, ListItemIcon, ListItemText, MenuList, Paper } from '@mui/material'
import MorningIcon from '@mui/icons-material/LightModeOutlined'
import NightIcon from '@mui/icons-material/DarkModeOutlined'
import TrainingIcon from '@mui/icons-material/SchoolOutlined'
import LateIcon from '@mui/icons-material/WatchLaterOutlined'
import NoteIcon from '@mui/icons-material/InfoOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import AcceptIcon from '@mui/icons-material/Check'
import DiscardIcon from '@mui/icons-material/Close'
import RestoreIcon from '@mui/icons-material/RestoreOutlined'
import HistoryIcon from '@mui/icons-material/ManageSearchOutlined'
import ReplaceIcon from '@mui/icons-material/UpdateOutlined'
import { useDrag } from 'react-dnd'
import { formatTime } from '../../utils/formatDate'

const colors = {
    white: '#fff',
    black: '#000',
    rossoCorsa: '#d50000',
}

const strings = {
    label: {
        morning: 'Poranna',
        night: 'Nocna',
        training: 'Szkolenie',
        late: 'Spóźnienie',
        replacement: 'Zamiana',
    },
    button: {
        edit: 'Edytuj',
        delete: 'Usuń',
        restore: 'Przywróć',
        history: 'Historia',
        cancel: 'Anuluj',
    },
}

interface OwnProps {
    readonly shift: ScheduleUserDayShift
    readonly isEditable: boolean
    readonly canShowHistory: boolean
    readonly deleteShift: (shift: ScheduleUserDayShift) => void
    readonly restoreShift: (shift: ScheduleUserDayShift) => void
    readonly editShift: (shift: ScheduleUserDayShift) => void
    readonly showHistory: (shift: ScheduleUserDayShift) => void
}

const ScheduleTableBodyDayShift: React.FunctionComponent<OwnProps> = ({
    shift,
    isEditable,
    canShowHistory,
    deleteShift,
    restoreShift,
    editShift,
    showHistory,
}) => {
    const [, drag] = useDrag(
        () => ({
            type: 'shift',
            item: shift,
        }),
        [shift]
    )

    const [deleteMode, setDeleteMode] = useState<boolean>(false)
    const [restoreMode, setRestoreMode] = useState<boolean>(false)
    const [menuPosition, setMenuPosition] = useState<null | PopoverPosition>(null)

    const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>): void => {
        setMenuPosition({ top: event.clientY + 10, left: event.clientX + 10 })
    }, [])

    const closeMenu = useCallback(() => {
        setMenuPosition(null)
        setDeleteMode(false)
        setRestoreMode(false)
    }, [])

    const handleEdit = useCallback((): void => {
        setMenuPosition(null)

        editShift(shift)
    }, [shift, editShift])

    const handleDelete = useCallback((): void => {
        setDeleteMode(true)
    }, [])

    const handleRestore = useCallback((): void => {
        setRestoreMode(true)
    }, [])

    const handleAccept = useCallback((): void => {
        if (deleteMode) {
            deleteShift(shift)
        } else if (restoreMode) {
            restoreShift(shift)
        }

        closeMenu()
    }, [shift, deleteMode, restoreMode])

    const handleShowHistory = useCallback((): void => {
        setMenuPosition(null)

        showHistory(shift)
    }, [shift, showHistory])

    return (
        <>
            <Paper
                ref={drag}
                sx={{
                    marginLeft: 2,
                    marginRight: 2,
                    marginTop: 1,
                    marginBottom: 2,
                    background: shift.flags.includes(ScheduleUserDayShiftFlag.Absence) ? colors.rossoCorsa : colors.white,
                    color: shift.flags.includes(ScheduleUserDayShiftFlag.Absence) ? colors.white : colors.black,
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    onClick={isEditable || canShowHistory ? handleOpenMenu : undefined}
                    mr={1}
                    p={1}
                >
                    <Container style={{ textDecoration: shift.isActive ? undefined : 'line-through' }}>
                        {formatTime(shift.start)} - {formatTime(shift.end)}
                    </Container>

                    {shift.flags.includes(ScheduleUserDayShiftFlag.Morning) && (
                        <Tooltip title={strings.label.morning}>
                            <MorningIcon />
                        </Tooltip>
                    )}
                    {shift.flags.includes(ScheduleUserDayShiftFlag.Night) && (
                        <Tooltip title={strings.label.night}>
                            <NightIcon />
                        </Tooltip>
                    )}
                    {shift.flags.includes(ScheduleUserDayShiftFlag.Training) && (
                        <Tooltip title={strings.label.training}>
                            <TrainingIcon />
                        </Tooltip>
                    )}
                    {shift.flags.includes(ScheduleUserDayShiftFlag.Late) && (
                        <Tooltip title={strings.label.late}>
                            <LateIcon />
                        </Tooltip>
                    )}
                    {!!shift.note && (
                        <Tooltip title={shift.note}>
                            <NoteIcon />
                        </Tooltip>
                    )}
                    {shift.flags.includes(ScheduleUserDayShiftFlag.Replacement) && (
                        <Tooltip title={strings.label.replacement}>
                            <ReplaceIcon />
                        </Tooltip>
                    )}
                </Stack>
            </Paper>

            <Menu
                open={menuPosition !== null}
                anchorPosition={menuPosition ?? undefined}
                anchorReference="anchorPosition"
                onClose={closeMenu}
                autoFocus={false}
            >
                {!deleteMode && shift.isActive && (
                    <MenuList disablePadding={true}>
                        {canShowHistory && (
                            <MenuItem onClick={handleShowHistory}>
                                <ListItemIcon>
                                    <HistoryIcon />
                                </ListItemIcon>
                                <ListItemText>{strings.button.history}</ListItemText>
                            </MenuItem>
                        )}
                        {isEditable && (
                            <MenuItem onClick={handleEdit}>
                                <ListItemIcon>
                                    <EditIcon />
                                </ListItemIcon>
                                <ListItemText>{strings.button.edit}</ListItemText>
                            </MenuItem>
                        )}
                        {isEditable && (
                            <MenuItem onClick={handleDelete}>
                                <ListItemIcon>
                                    <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText>{strings.button.delete}</ListItemText>
                            </MenuItem>
                        )}
                    </MenuList>
                )}

                {!restoreMode && !shift.isActive && (
                    <MenuList disablePadding={true}>
                        <MenuItem onClick={handleShowHistory}>
                            <ListItemIcon>
                                <HistoryIcon />
                            </ListItemIcon>
                            <ListItemText>{strings.button.history}</ListItemText>
                        </MenuItem>
                        {/* hide item */}
                        {false && (
                            <MenuItem onClick={handleRestore}>
                                <ListItemIcon>
                                    <RestoreIcon />
                                </ListItemIcon>
                                <ListItemText>{strings.button.restore}</ListItemText>
                            </MenuItem>
                        )}
                    </MenuList>
                )}

                {(deleteMode || restoreMode) && (
                    <MenuList disablePadding={true}>
                        <MenuItem onClick={handleAccept}>
                            <ListItemIcon>
                                <AcceptIcon />
                            </ListItemIcon>
                            {deleteMode && <ListItemText>{strings.button.delete}</ListItemText>}
                            {restoreMode && <ListItemText>{strings.button.restore}</ListItemText>}
                        </MenuItem>
                        <MenuItem onClick={closeMenu}>
                            <ListItemIcon>
                                <DiscardIcon />
                            </ListItemIcon>
                            <ListItemText>{strings.button.cancel}</ListItemText>
                        </MenuItem>
                    </MenuList>
                )}
            </Menu>
        </>
    )
}

export default React.memo(ScheduleTableBodyDayShift)
