import React, { useCallback, useMemo, useState } from 'react'
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    PopoverPosition,
    Stack,
    Tooltip,
    Typography,
    styled,
} from '@mui/material'
import { TaskDetails, TaskState } from '../../api/response'
import { formatDateTime, formatTime } from '../../utils/formatDate'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import StartIcon from '@mui/icons-material/Start'
import EndIcon from '@mui/icons-material/KeyboardTab'
import IdleIcon from '@mui/icons-material/HourglassEmpty'
import CompletedIcon from '@mui/icons-material/TaskAlt'
import InProgressIcon from '@mui/icons-material/Sync'
import DeniedIcon from '@mui/icons-material/DoDisturb'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import AcceptIcon from '@mui/icons-material/Check'
import DiscardIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/EditOutlined'

const strings = {
    label: {
        idle: 'Oczekiwanie',
        inProgress: 'W realizacji',
        completed: 'Wykonano',
        denied: 'Odrzucono',
        comments: 'Powód odmowy:',
        startedAt: 'Rozpoczęcie:',
        endedAt: 'Zakończenie:',
        locationUnknown: 'Brak lokalizacji',
    },
    button: {
        refresh: 'Odśwież',
        edit: 'Edytuj',
        delete: 'Usuń',
        startLocation: 'Zobacz lokalizację startu',
        endLocation: 'Zobacz lokalizację końca',
        cancel: 'Anuluj',
    },
}

const colors = {
    apple: '#6ebe48',
    rossoCorsa: '#d50000',
    brightGray: '#393f51',
}

const StyledIconButton = styled(IconButton, { shouldForwardProp: (prop) => !(prop as string).startsWith('$') })<{
    $hasValue: boolean
}>(({ $hasValue }) => ({
    color: $hasValue ? colors.apple : colors.rossoCorsa,
}))

interface OwnProps {
    readonly task: TaskDetails
    readonly editTask: (task: TaskDetails) => void
    readonly deleteTask: (task: TaskDetails) => void
}

const TasksListItem: React.FunctionComponent<OwnProps> = ({ task, editTask, deleteTask }) => {
    const [menuPosition, setMenuPosition] = useState<null | PopoverPosition>(null)

    const date = useMemo(() => {
        if (task.end !== null) {
            return `${formatDateTime(task.start)} - ${formatTime(task.end)}`
        }

        return formatDateTime(task.start)
    }, [task])

    const state = useMemo(() => {
        if (task.state === TaskState.Idle) {
            return (
                <Tooltip title={strings.label.idle}>
                    <IdleIcon />
                </Tooltip>
            )
        }

        if (task.state === TaskState.InProgress) {
            return (
                <Tooltip title={strings.label.inProgress}>
                    <InProgressIcon />
                </Tooltip>
            )
        }

        if (task.state === TaskState.Completed) {
            return (
                <Tooltip title={strings.label.completed}>
                    <CompletedIcon />
                </Tooltip>
            )
        }

        if (task.state === TaskState.Denied) {
            return (
                <Tooltip title={strings.label.denied}>
                    <DeniedIcon />
                </Tooltip>
            )
        }

        return null
    }, [task])

    const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>): void => {
        setMenuPosition({ top: event.clientY + 10, left: event.clientX + 10 })
    }, [])

    const handleEdit = useCallback(() => {
        editTask(task)
    }, [task])

    const handleDelete = useCallback(() => {
        setMenuPosition(null)
        deleteTask(task)
    }, [task])

    const closeMenu = useCallback(() => {
        setMenuPosition(null)
    }, [])

    const openStartLocation = useCallback(
        (event: React.MouseEvent<HTMLElement>): void => {
            event.preventDefault()
            event.stopPropagation()

            if (task.startLocation !== null) {
                window.open(`https://www.google.com/maps?q=${task.startLocation.latitude},${task.startLocation.longitude}`, '_blank', 'noreferrer')
            }
        },
        [task]
    )

    const openEndLocation = useCallback(
        (event: React.MouseEvent<HTMLElement>): void => {
            event.preventDefault()
            event.stopPropagation()

            if (task.endLocation !== null) {
                window.open(`https://www.google.com/maps?q=${task.endLocation.latitude},${task.endLocation.longitude}`, '_blank', 'noreferrer')
            }
        },
        [task]
    )

    return (
        <>
            <Card>
                <CardHeader
                    avatar={state}
                    action={
                        <Tooltip title={task.id}>
                            <InfoIcon />
                        </Tooltip>
                    }
                    title={task.status.name}
                    subheader={date}
                />
                <CardContent>
                    <Stack spacing={1}>
                        <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                        >
                            {task.title}
                        </Typography>
                        {!!task.description && (
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                component={'pre'}
                            >
                                {task.description}
                            </Typography>
                        )}
                        {!!task.comments && (
                            <Typography
                                variant="body1"
                                color="error.main"
                            >
                                {strings.label.comments} {task.comments}
                            </Typography>
                        )}
                        {!!task.startedAt && (
                            <Typography variant="body1">
                                {strings.label.startedAt} {formatDateTime(task.startedAt)}
                            </Typography>
                        )}
                        {!!task.endedAt && (
                            <Typography variant="body1">
                                {strings.label.endedAt} {formatDateTime(task.endedAt)}
                            </Typography>
                        )}
                    </Stack>
                </CardContent>
                <CardActions>
                    {task.state === TaskState.Idle && (
                        <Tooltip title={strings.button.edit}>
                            <IconButton onClick={handleEdit}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {task.state === TaskState.Idle && (
                        <Tooltip title={strings.button.delete}>
                            <IconButton onClick={handleOpenMenu}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    )}

                    <Tooltip title={task.startLocation ? task.startLocation.address ?? strings.button.startLocation : strings.label.locationUnknown}>
                        <StyledIconButton
                            $hasValue={!!task.startLocation}
                            onClick={task.startLocation ? openStartLocation : undefined}
                        >
                            <StartIcon />
                        </StyledIconButton>
                    </Tooltip>

                    <Tooltip title={task.endLocation ? task.endLocation.address ?? strings.button.endLocation : strings.label.locationUnknown}>
                        <StyledIconButton
                            $hasValue={!!task.endLocation}
                            onClick={task.endLocation ? openEndLocation : undefined}
                        >
                            <EndIcon />
                        </StyledIconButton>
                    </Tooltip>
                </CardActions>
            </Card>

            <Menu
                open={menuPosition !== null}
                anchorPosition={menuPosition ?? undefined}
                anchorReference="anchorPosition"
                onClose={closeMenu}
                autoFocus={false}
            >
                <MenuList disablePadding={true}>
                    <MenuItem onClick={handleDelete}>
                        <ListItemIcon>
                            <AcceptIcon />
                        </ListItemIcon>
                        <ListItemText>{strings.button.delete}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={closeMenu}>
                        <ListItemIcon>
                            <DiscardIcon />
                        </ListItemIcon>
                        <ListItemText>{strings.button.cancel}</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    )
}

export default React.memo(TasksListItem)
