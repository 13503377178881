import React, { useCallback, useMemo, useState } from 'react'
import { UserDayShiftFlag, UserDayShift } from '../../api/response'
import { Stack, Container, PopoverPosition, Menu, MenuItem, ListItemIcon, ListItemText, MenuList, Paper, Tooltip, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/EditOutlined'
import SplitIcon from '@mui/icons-material/CallSplitOutlined'
import HideIcon from '@mui/icons-material/VisibilityOffOutlined'
import { formatPeriod, formatTime } from '../../utils/formatDate'
import StartIcon from '@mui/icons-material/Start'
import EndIcon from '@mui/icons-material/KeyboardTab'
import HistoryIcon from '@mui/icons-material/ManageSearchOutlined'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import AlarmOnIcon from '@mui/icons-material/AlarmOnOutlined'
import RestoreIcon from '@mui/icons-material/RestoreOutlined'
import DoneIcon from '@mui/icons-material/DoneOutlined'
import AcceptIcon from '@mui/icons-material/Check'
import DiscardIcon from '@mui/icons-material/Close'
import WrongLocationIcon from '@mui/icons-material/WrongLocationOutlined'
import styled from '@emotion/styled'

const colors = {
    amber: '#ffbf00',
    apple: '#6ebe48',
    rossoCorsa: '#d50000',
    brightGray: '#393f51',
    festival: '#f0cc48',
    blue: '#1d49c7',
    lightBlue: '#add8e6',
}

const strings = {
    label: {
        locationUnknown: 'Brak lokalizacji',
        locationOutsideBranch: 'Lokalizacja poza oddziałem',
    },
    shift: {
        ongoing: 'W trakcie',
        expired: 'Zmiana "przeterminowana"',
        manualEntry: 'Zmiana dodana ręcznie w panelu',
        outsideBranchNoOrders: 'Chwilowo poza oddziałem bez zlecenia',
        systemNoLocation: 'Brak lokalizacji po automatycznym żądaniu lokalizacji',
        panelNoLocation: 'Brak lokalizacji po ręcznym żądaniu lokalizacji',
        branchCheckExpired: 'Brak zlokalizowania się po losowym żądaniu lokalizacji w oddziale',
        leftBranchNotAllowed: 'Opuszczono oddział',
        leftBranchWithoutOrder: 'Opuszczono oddział nie dodając zlecenia',
        leftBranchTooLong: 'Po dodaniu zlecenia za długo poza oddziałem',
        orderNotSyncedInTime: 'Zdjęcia do zlecenia nie zostały wysłane w wymaganym czasie',
        locationAccessForeground: 'Brak wymaganego dostępu do lokalizacji',
        locationAccessBackground: 'Zmieniono/cofnięto dostęp do lokalizacji',
        orderFormRelocation: 'W formularzu zlecenia wybrano status relokacji',
        manualEnd: 'Ręcznie zakończono w panelu',
        locationCheckOutsideBranch: 'Użytkownik zlokalizował się poza oddziałem',
        startAdditionalBranch: 'Rozpoczęcie w oddziale dodatkowym',
        endAdditionalBranch: 'Zakończenie w oddziale dodatkowym',
        startInvalidTime: 'Błędny czas na telefonie',
        endInvalidTime: 'Błędny czas na telefonie',
        mockLocation: 'Imitowana lokalizacja',
        homeLocation: 'Przebywanie w miejscu zamieszkania',
    },
    button: {
        edit: 'Edytuj',
        split: 'Podziel',
        approve: 'Zaliczone',
        undoApprove: 'Cofnij zaliczenie',
        hide: 'Ukryj',
        restore: 'Przywróć',
        history: 'Historia',
        end: 'Zakończ',
        startLocation: 'Kliknij, aby zobaczyć lokalizację startu',
        endLocation: 'Kliknij, aby zobaczyć lokalizację końca',
        cancel: 'Anuluj',
    },
}

const StyledIconButton = styled(IconButton, { shouldForwardProp: (prop) => !prop.startsWith('$') })<{
    $hasValue: boolean
    $isApproved: boolean
    $hasWarning: boolean
    $isEdited: boolean
    $isSplit: boolean
    $isAdditionalBranch: boolean
}>(({ $hasValue, $isApproved, $hasWarning, $isEdited, $isAdditionalBranch }) => ({
    color: $hasValue
        ? $isApproved || $hasWarning || $isEdited
            ? $isAdditionalBranch
                ? colors.blue
                : '#ffffff'
            : $isAdditionalBranch
            ? colors.lightBlue
            : colors.apple
        : colors.rossoCorsa,
}))

interface OwnProps {
    readonly shift: UserDayShift
    readonly isEditable: boolean
    readonly canShowHistory: boolean
    readonly canEditUserShifts: boolean
    readonly canHideUserShifts: boolean
    readonly canEndUserShifts: boolean
    readonly editShift: (shift: UserDayShift) => void
    readonly splitShift: (shift: UserDayShift) => void
    readonly hideShift: (shift: UserDayShift) => void
    readonly restoreShift: (shift: UserDayShift) => void
    readonly endShift: (shift: UserDayShift) => void
    readonly approveUserShift: (shift: UserDayShift, flag: boolean) => void
    readonly showHistory: (shift: UserDayShift) => void
}

const ScheduleTableBodyUserDayShift: React.FunctionComponent<OwnProps> = ({
    shift,
    isEditable,
    canShowHistory,
    canEditUserShifts,
    canHideUserShifts,
    canEndUserShifts,
    editShift,
    splitShift,
    hideShift,
    restoreShift,
    endShift,
    approveUserShift,
    showHistory,
}) => {
    const [hideMode, setHideMode] = useState<boolean>(false)
    const [restoreMode, setRestoreMode] = useState<boolean>(false)
    const [endMode, setEndMode] = useState<boolean>(false)
    const [approveMode, setApproveMode] = useState<boolean>(false)
    const [undoApproveMode, setUndoApproveMode] = useState<boolean>(false)
    const [menuPosition, setMenuPosition] = useState<null | PopoverPosition>(null)

    const start = useMemo(() => {
        if (shift.edited !== null) {
            return formatTime(shift.edited.start)
        }

        return formatTime(shift.start)
    }, [shift])

    const end = useMemo(() => {
        if (shift.edited !== null) {
            return `${formatTime(shift.edited.end)} (${formatPeriod(shift.edited.period)})`
        }

        if (shift.end === null) {
            return '‒‒:‒‒'
        }

        return `${formatTime(shift.end)} (${formatPeriod(shift.period)})`
    }, [shift])

    const info = useMemo(() => {
        if (shift.edited === null) {
            return null
        }

        const start = formatTime(shift.start)
        let end: string | null = '‒‒:‒‒'

        if (shift.end !== null) {
            end = formatTime(shift.end)
            const period = formatPeriod(shift.period)

            end = `${end} (${period})`
        }

        return `${start} - ${end}`
    }, [shift])

    const startTitle = useMemo(() => {
        const messages = []

        if (shift.flags.includes(UserDayShiftFlag.StartAdditionalBranch)) {
            messages.push(strings.shift.startAdditionalBranch)
        }

        if (shift.flags.includes(UserDayShiftFlag.StartInvalidTime)) {
            messages.push(strings.shift.startInvalidTime)
        }

        messages.push(shift.startLocation ? shift.startLocation.address ?? strings.button.startLocation : strings.label.locationUnknown)

        if (messages.length === 1) {
            return <div>{messages[0]}</div>
        }

        return (
            <ul style={{ padding: 0, paddingLeft: 10, marginTop: 0, marginBottom: 0 }}>
                {messages.map((message, index) => (
                    <li key={index}>{message}</li>
                ))}
            </ul>
        )
    }, [shift])

    const endTitle = useMemo(() => {
        const messages = []

        if (shift.end === null) {
            messages.push(strings.shift.ongoing)
        }
        if (shift.flags.includes(UserDayShiftFlag.Expired)) {
            messages.push(strings.shift.expired)
        }

        if (shift.flags.includes(UserDayShiftFlag.ManualEntry)) {
            messages.push(strings.shift.manualEntry)
        }

        if (shift.flags.includes(UserDayShiftFlag.OutsideBranchNoOrders)) {
            messages.push(strings.shift.outsideBranchNoOrders)
        }

        if (shift.flags.includes(UserDayShiftFlag.SystemNoLocation)) {
            messages.push(strings.shift.systemNoLocation)
        }

        if (shift.flags.includes(UserDayShiftFlag.PanelNoLocation)) {
            messages.push(strings.shift.panelNoLocation)
        }

        if (shift.flags.includes(UserDayShiftFlag.BranchCheckExpired)) {
            messages.push(strings.shift.branchCheckExpired)
        }

        if (shift.flags.includes(UserDayShiftFlag.LeftBranchNotAllowed)) {
            messages.push(strings.shift.leftBranchNotAllowed)
        }

        if (shift.flags.includes(UserDayShiftFlag.LeftBranchWithoutOrder)) {
            messages.push(strings.shift.leftBranchWithoutOrder)
        }

        if (shift.flags.includes(UserDayShiftFlag.LeftBranchTooLong)) {
            messages.push(strings.shift.leftBranchTooLong)
        }

        if (shift.flags.includes(UserDayShiftFlag.OrderNotSyncedInTime)) {
            messages.push(strings.shift.orderNotSyncedInTime)
        }

        if (shift.flags.includes(UserDayShiftFlag.LocationAccessForeground)) {
            messages.push(strings.shift.locationAccessForeground)
        }

        if (shift.flags.includes(UserDayShiftFlag.LocationAccessBackground)) {
            messages.push(strings.shift.locationAccessBackground)
        }

        if (shift.flags.includes(UserDayShiftFlag.OrderFormRelocation)) {
            messages.push(strings.shift.orderFormRelocation)
        }

        if (shift.flags.includes(UserDayShiftFlag.ManualEnd)) {
            messages.push(strings.shift.manualEnd)
        }

        if (shift.flags.includes(UserDayShiftFlag.LocationCheckOutsideBranch)) {
            messages.push(strings.shift.locationCheckOutsideBranch)
        }

        if (shift.flags.includes(UserDayShiftFlag.EndAdditionalBranch)) {
            messages.push(strings.shift.endAdditionalBranch)
        }

        if (shift.flags.includes(UserDayShiftFlag.EndInvalidTime)) {
            messages.push(strings.shift.endInvalidTime)
        }

        if (shift.flags.includes(UserDayShiftFlag.MockLocation)) {
            messages.push(strings.shift.mockLocation)
        }

        if (shift.flags.includes(UserDayShiftFlag.HomeLocation)) {
            messages.push(strings.shift.homeLocation)
        }

        messages.push(shift.endLocation ? shift.endLocation.address ?? strings.button.endLocation : strings.label.locationUnknown)

        if (messages.length === 1) {
            return <div>{messages[0]}</div>
        }

        return (
            <ul style={{ padding: 0, paddingLeft: 10, marginTop: 0, marginBottom: 0 }}>
                {messages.map((message, index) => (
                    <li key={index}>{message}</li>
                ))}
            </ul>
        )
    }, [shift])

    const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault()
        event.stopPropagation()

        setMenuPosition({ top: event.clientY + 10, left: event.clientX + 10 })
    }, [])

    const closeMenu = useCallback(() => {
        setMenuPosition(null)
        setHideMode(false)
        setRestoreMode(false)
        setEndMode(false)
        setApproveMode(false)
        setUndoApproveMode(false)
    }, [])

    const handleEdit = useCallback((): void => {
        setMenuPosition(null)

        editShift(shift)
    }, [shift, editShift])

    const handleSplit = useCallback((): void => {
        setMenuPosition(null)

        splitShift(shift)
    }, [shift, splitShift])

    const handleHide = useCallback((): void => {
        setHideMode(true)
    }, [])

    const handleRestore = useCallback((): void => {
        setRestoreMode(true)
    }, [])

    const handleEnd = useCallback((): void => {
        setEndMode(true)
    }, [])

    const handleApprove = useCallback((): void => {
        setApproveMode(true)
    }, [])

    const handleUndoApprove = useCallback((): void => {
        setUndoApproveMode(true)
    }, [])

    const handleAccept = useCallback((): void => {
        if (hideMode) {
            hideShift(shift)
        } else if (endMode) {
            endShift(shift)
        } else if (approveMode) {
            approveUserShift(shift, true)
        } else if (undoApproveMode) {
            approveUserShift(shift, false)
        } else if (restoreMode) {
            restoreShift(shift)
        }

        closeMenu()
    }, [shift, hideMode, endMode, approveMode, undoApproveMode, restoreMode])

    const handleShowHistory = useCallback((): void => {
        setMenuPosition(null)

        showHistory(shift)
    }, [shift, showHistory])

    const openStartLocation = useCallback(
        (event: React.MouseEvent<HTMLElement>): void => {
            event.preventDefault()
            event.stopPropagation()

            if (shift.startLocation !== null) {
                window.open(`https://www.google.com/maps?q=${shift.startLocation.latitude},${shift.startLocation.longitude}`, '_blank', 'noreferrer')
            }
        },
        [shift]
    )

    const openEndLocation = useCallback(
        (event: React.MouseEvent<HTMLElement>): void => {
            event.preventDefault()
            event.stopPropagation()

            if (shift.endLocation !== null) {
                window.open(`https://www.google.com/maps?q=${shift.endLocation.latitude},${shift.endLocation.longitude}`, '_blank', 'noreferrer')
            }
        },
        [shift]
    )

    return (
        <>
            <Paper
                sx={{
                    marginLeft: 2,
                    marginRight: 2,
                    marginTop: 1,
                    marginBottom: 2,
                    background: shift.flags.includes(UserDayShiftFlag.Approved)
                        ? colors.apple
                        : shift.flags.includes(UserDayShiftFlag.StartTooEarly) || shift.flags.includes(UserDayShiftFlag.EndTooLate)
                        ? colors.amber
                        : shift.flags.includes(UserDayShiftFlag.Split) || shift.flags.includes(UserDayShiftFlag.Splitted)
                        ? colors.festival
                        : shift.edited !== null
                        ? colors.apple
                        : colors.brightGray,
                    color:
                        shift.flags.includes(UserDayShiftFlag.Approved) ||
                        shift.edited !== null ||
                        shift.flags.includes(UserDayShiftFlag.Split) ||
                        shift.flags.includes(UserDayShiftFlag.Splitted)
                            ? '#000000'
                            : '#ffffff',
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    onClick={
                        (isEditable && (canEditUserShifts || canHideUserShifts || (!shift.end && canEndUserShifts))) || canShowHistory
                            ? handleOpenMenu
                            : undefined
                    }
                    mr={1}
                    p={1}
                >
                    {shift.flags.includes(UserDayShiftFlag.Approved) && <DoneIcon />}

                    <Container style={{ textDecoration: shift.isHidden ? 'line-through' : undefined }}>
                        {start} - {end}
                    </Container>

                    {info !== null && (
                        <Tooltip title={info}>
                            <InfoIcon />
                        </Tooltip>
                    )}

                    {shift.flags.includes(UserDayShiftFlag.StartOutsideBranch) && (
                        <Tooltip title={strings.label.locationOutsideBranch}>
                            <WrongLocationIcon style={{ color: '#ff0000' }} />
                        </Tooltip>
                    )}
                    <Tooltip title={startTitle}>
                        <StyledIconButton
                            $hasValue={!!shift.startLocation}
                            $isApproved={shift.flags.includes(UserDayShiftFlag.Approved)}
                            $hasWarning={shift.flags.includes(UserDayShiftFlag.StartTooEarly) || shift.flags.includes(UserDayShiftFlag.EndTooLate)}
                            $isEdited={!!shift.edited}
                            $isSplit={shift.flags.includes(UserDayShiftFlag.Split) || shift.flags.includes(UserDayShiftFlag.Splitted)}
                            $isAdditionalBranch={shift.flags.includes(UserDayShiftFlag.StartAdditionalBranch)}
                            onClick={shift.startLocation ? openStartLocation : undefined}
                        >
                            <StartIcon />
                        </StyledIconButton>
                    </Tooltip>

                    <Tooltip title={endTitle}>
                        <StyledIconButton
                            $hasValue={!!shift.endLocation}
                            $isApproved={shift.flags.includes(UserDayShiftFlag.Approved)}
                            $hasWarning={shift.flags.includes(UserDayShiftFlag.StartTooEarly) || shift.flags.includes(UserDayShiftFlag.EndTooLate)}
                            $isEdited={!!shift.edited}
                            $isSplit={shift.flags.includes(UserDayShiftFlag.Split) || shift.flags.includes(UserDayShiftFlag.Splitted)}
                            $isAdditionalBranch={shift.flags.includes(UserDayShiftFlag.EndAdditionalBranch)}
                            onClick={shift.endLocation ? openEndLocation : undefined}
                        >
                            <EndIcon />
                        </StyledIconButton>
                    </Tooltip>

                    {shift.flags.includes(UserDayShiftFlag.EndOutsideBranch) && (
                        <Tooltip title={strings.label.locationOutsideBranch}>
                            <WrongLocationIcon style={{ color: '#ff0000' }} />
                        </Tooltip>
                    )}
                </Stack>
            </Paper>

            <Menu
                open={menuPosition !== null}
                anchorPosition={menuPosition ?? undefined}
                anchorReference="anchorPosition"
                onClose={closeMenu}
                autoFocus={false}
            >
                {!(hideMode || endMode || approveMode || undoApproveMode) && !shift.isHidden && (
                    <MenuList disablePadding={true}>
                        {canShowHistory && (
                            <MenuItem onClick={handleShowHistory}>
                                <ListItemIcon>
                                    <HistoryIcon />
                                </ListItemIcon>
                                <ListItemText>{strings.button.history}</ListItemText>
                            </MenuItem>
                        )}
                        {!!shift.end && isEditable && canEditUserShifts && (
                            <MenuItem onClick={handleEdit}>
                                <ListItemIcon>
                                    <EditIcon />
                                </ListItemIcon>
                                <ListItemText>{strings.button.edit}</ListItemText>
                            </MenuItem>
                        )}
                        {!!shift.end && isEditable && canEditUserShifts && (
                            <MenuItem onClick={handleSplit}>
                                <ListItemIcon>
                                    <SplitIcon />
                                </ListItemIcon>
                                <ListItemText>{strings.button.split}</ListItemText>
                            </MenuItem>
                        )}
                        {!!shift.end && isEditable && canEditUserShifts && !shift.flags.includes(UserDayShiftFlag.Approved) && (
                            <MenuItem onClick={handleApprove}>
                                <ListItemIcon>
                                    <AlarmOnIcon />
                                </ListItemIcon>
                                <ListItemText>{strings.button.approve}</ListItemText>
                            </MenuItem>
                        )}
                        {!!shift.end && isEditable && canEditUserShifts && shift.flags.includes(UserDayShiftFlag.Approved) && (
                            <MenuItem onClick={handleUndoApprove}>
                                <ListItemIcon>
                                    <RestoreIcon />
                                </ListItemIcon>
                                <ListItemText>{strings.button.undoApprove}</ListItemText>
                            </MenuItem>
                        )}
                        {!!shift.end && isEditable && canHideUserShifts && (
                            <MenuItem onClick={handleHide}>
                                <ListItemIcon>
                                    <HideIcon />
                                </ListItemIcon>
                                <ListItemText>{strings.button.hide}</ListItemText>
                            </MenuItem>
                        )}
                        {!shift.end && isEditable && canEndUserShifts && (
                            <MenuItem onClick={handleEnd}>
                                <ListItemIcon>
                                    <EndIcon />
                                </ListItemIcon>
                                <ListItemText>{strings.button.end}</ListItemText>
                            </MenuItem>
                        )}
                    </MenuList>
                )}

                {!restoreMode && shift.isHidden && (
                    <MenuList disablePadding={true}>
                        <MenuItem onClick={handleShowHistory}>
                            <ListItemIcon>
                                <HistoryIcon />
                            </ListItemIcon>
                            <ListItemText>{strings.button.history}</ListItemText>
                        </MenuItem>
                        {isEditable && canHideUserShifts && (
                            <MenuItem onClick={handleRestore}>
                                <ListItemIcon>
                                    <RestoreIcon />
                                </ListItemIcon>
                                <ListItemText>{strings.button.restore}</ListItemText>
                            </MenuItem>
                        )}
                    </MenuList>
                )}

                {(hideMode || endMode || approveMode || undoApproveMode || restoreMode) && (
                    <MenuList disablePadding={true}>
                        <MenuItem onClick={handleAccept}>
                            <ListItemIcon>
                                <AcceptIcon />
                            </ListItemIcon>
                            {hideMode && <ListItemText>{strings.button.hide}</ListItemText>}
                            {endMode && <ListItemText>{strings.button.end}</ListItemText>}
                            {approveMode && <ListItemText>{strings.button.approve}</ListItemText>}
                            {undoApproveMode && <ListItemText>{strings.button.undoApprove}</ListItemText>}
                            {restoreMode && <ListItemText>{strings.button.restore}</ListItemText>}
                        </MenuItem>
                        <MenuItem onClick={closeMenu}>
                            <ListItemIcon>
                                <DiscardIcon />
                            </ListItemIcon>
                            <ListItemText>{strings.button.cancel}</ListItemText>
                        </MenuItem>
                    </MenuList>
                )}
            </Menu>
        </>
    )
}

export default React.memo(ScheduleTableBodyUserDayShift)
