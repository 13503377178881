import React, { useEffect } from 'react'
import { clearMenuItem } from '../../state/menuItemActions'
import { clearTitle } from '../../state/titleActions'
import { useStateContext, useStateDispatchContext } from '../../state/stateContext'

const HomePage: React.FunctionComponent = () => {
    const { appState } = useStateContext()
    const { appDispatch } = useStateDispatchContext()

    useEffect(() => {
        const { menuItem, title } = appState

        if (menuItem !== null) {
            appDispatch(clearMenuItem())
        }

        if (title !== null) {
            appDispatch(clearTitle())
        }
    }, [])

    return null
}

export default React.memo(HomePage)
