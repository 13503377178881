import React, { MutableRefObject, PropsWithChildren } from 'react'
import { NavigateFunction, useNavigate } from 'react-router'

const StableNavigateContext = React.createContext<MutableRefObject<NavigateFunction> | null>(null)

const StableNavigateContextProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
    const navigate = useNavigate()
    const navigateRef = React.useRef(navigate)

    return <StableNavigateContext.Provider value={navigateRef}>{children}</StableNavigateContext.Provider>
}

const useStableNavigate = (): NavigateFunction => {
    const navigateRef = React.useContext(StableNavigateContext)
    if (navigateRef === null || navigateRef.current === null) {
        throw new Error('StableNavigate context is not initialized')
    }

    return navigateRef.current
}

export { StableNavigateContext, StableNavigateContextProvider, useStableNavigate }
