import React from 'react'
import { Box, CircularProgress, Portal, styled } from '@mui/material'
import { useStateContext } from '../../state/stateContext'

const StyledBoxContainer = styled(Box)(() => ({
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1300,
}))

const StyledBoxBackground = styled(Box)({
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    height: '100%',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    willChange: 'opacity',
    zIndex: -1,
})

const StyledBoxProgress = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    background: theme.palette.common.white,
    borderRadius: 5,
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'column',
    height: 100,
    justifyContent: 'center',
    position: 'relative',
    width: 100,
}))

function Loading(): React.ReactElement | null {
    const { appState } = useStateContext()

    if (appState.progress > 0) {
        return (
            <Portal>
                <StyledBoxContainer>
                    <StyledBoxBackground />
                    <StyledBoxProgress>
                        <CircularProgress />
                    </StyledBoxProgress>
                </StyledBoxContainer>
            </Portal>
        )
    }

    return null
}

export default React.memo(Loading)
