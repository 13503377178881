import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField } from '@mui/material'
import { useCallback } from 'react'
import { API } from '../../api/api'
import { ScheduleDayRequest } from '../../api/request'
import { handleApiError } from '../../state/apiErrorActions'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { useStateDispatchContext } from '../../state/stateContext'

const strings = {
    label: {
        title: 'Uwagi do dnia',
        content: (date: string, fullName: string): string => `Ustaw uwagi do dnia ${date} dla ${fullName}`,
        note: 'Uwagi',
    },
    button: {
        cancel: 'Anuluj',
        save: 'Zapisz',
    },
}

enum FormFieldNames {
    Note = 'note',
}

export interface ScheduleDayDialogData {
    scheduleId: string
    date: dayjs.Dayjs
    user: { id: number; fullName: string }
    note: string | null
    onSuccess: (note: string | null) => void
}

interface OwnProps {
    readonly open: boolean
    readonly data: ScheduleDayDialogData
    readonly closeDialog: () => void
}

const ScheduleDayDialog: React.FunctionComponent<OwnProps> = ({ open, data, closeDialog }) => {
    const { date, user, onSuccess } = data

    const [note, setNote] = useState<string>('')

    const { appDispatch } = useStateDispatchContext()

    useEffect(() => {
        const note = data.note

        if (open && note !== null) {
            setNote(note)
        } else {
            setNote('')
        }
    }, [open, data])

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setNote(event.target.value)
    }, [])

    function handleSaveClick(): void {
        const request = new ScheduleDayRequest(data.user.id, data.date.date(), note.length > 0 ? note : null)

        appDispatch(setInProgress())

        API.schedule
            .day(data.scheduleId, request)
            .then(() => {
                onSuccess(request.note)
                appDispatch(setSuccess())
                closeDialog()
            })
            .catch((error) => {
                appDispatch(setFailure())
                appDispatch(handleApiError(error))
            })
    }

    return (
        <Dialog
            open={open}
            disableEscapeKeyDown={true}
        >
            <DialogTitle>{strings.label.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{strings.label.content(date.format('YYYY-MM-DD'), user.fullName)}</DialogContentText>
                <Stack
                    direction="column"
                    spacing={2}
                >
                    <TextField
                        id={FormFieldNames.Note}
                        name={FormFieldNames.Note}
                        value={note}
                        multiline={false}
                        required={false}
                        fullWidth={true}
                        inputProps={{ maxlength: 255 }}
                        label={strings.label.note}
                        margin="none"
                        onChange={onTextFieldChange}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>{strings.button.cancel}</Button>
                <Button onClick={handleSaveClick}>{strings.button.save}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(ScheduleDayDialog)
