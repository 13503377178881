import React, { useCallback } from 'react'
import { TableCell, TableRow, Tooltip, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Car } from '../../api/response'

const strings = {
    button: {
        delete: 'Usuń',
        edit: 'Edytuj',
    },
}

interface OwnProps {
    readonly car: Car
    readonly editCar: (item: Car) => void
    readonly deleteCar: (item: Car) => void
}

const CarTableRow: React.FunctionComponent<OwnProps> = ({ car, editCar, deleteCar }) => {
    const handleEdit = useCallback(() => {
        editCar(car)
    }, [car])

    const handleDelete = useCallback(() => {
        deleteCar(car)
    }, [car])

    return (
        <TableRow hover={true}>
            <TableCell>{car.id}</TableCell>
            <TableCell>{car.name}</TableCell>
            <TableCell>{car.licensePlate}</TableCell>
            <TableCell>
                <>
                    <Tooltip title={strings.button.edit}>
                        <IconButton onClick={handleEdit}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={strings.button.delete}>
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            </TableCell>
        </TableRow>
    )
}

export default React.memo(CarTableRow)
