import React, { useCallback, useEffect, useState } from 'react'
import { changeMenuItem } from '../../state/menuItemActions'
import { setTitle } from '../../state/titleActions'
import { useStateContext, useStateDispatchContext } from '../../state/stateContext'
import { MenuItemType } from '../../model/MenuItemType'
import { Button, CircularProgress, Paper, Stack, styled } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { API } from '../../api/api'
import { handleApiError } from '../../state/apiErrorActions'
import { TaskDetails } from '../../api/response'
import TasksUsersHeader from './TasksHeader'
import { mainPadding } from '../../components/AppCanvas'
import TasksListItem from './TasksListItem'
import { BottomScrollListener } from 'react-bottom-scroll-listener'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import TaskDialog, { TaskDialogData } from './TaskDialog'

const strings = {
    button: {
        refresh: 'Odśwież',
    },
}

enum QueryParam {
    BranchId = 'branchId',
    UserId = 'userId',
}

const getParamNumberValue = (searchParams: URLSearchParams, queryParam: QueryParam, defaultValue: number): number => {
    let param = parseInt(searchParams.get(queryParam) ?? '')
    if (isNaN(param)) {
        param = defaultValue
    }

    return param
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
}))

const StyledStack = styled(Stack)(({ theme }) => ({
    margin: theme.spacing(mainPadding),
}))

const TasksPage: React.FunctionComponent = () => {
    const { sessionState } = useStateContext()
    const { appDispatch } = useStateDispatchContext()
    const [searchParams, setSearchParams] = useSearchParams()

    const [branchId, setBranchId] = useState<number>(() => getParamNumberValue(searchParams, QueryParam.BranchId, sessionState.account!.branch.id))
    const [userId, setUserId] = useState<number | null>(() => getParamNumberValue(searchParams, QueryParam.UserId, sessionState.account!.id))
    const [tasks, setTasks] = useState<TaskDetails[] | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadMore, setLoadMore] = useState<boolean>(false)
    const [taskDialogOpen, setTaskDialogOpen] = useState<boolean>(false)
    const [taskDialogData, setTaskDialogData] = useState<TaskDialogData | null>(null)

    const loadTasks = useCallback(() => {
        if (userId === null || loading) {
            return
        }

        const limit = 50

        setLoading(true)
        API.tasks
            .tasks(userId, limit, tasks?.length)
            .then((newTasks) => {
                if (tasks === null) {
                    setTasks(newTasks)
                } else {
                    setTasks(tasks.concat(newTasks))
                }
                setLoadMore(newTasks.length === limit)
            })
            .catch((error) => {
                appDispatch(handleApiError(error))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [userId, tasks, loading])

    const updateBranchId = useCallback((newValue: number) => {
        setBranchId(newValue)
        setUserId(null)
        setTasks(null)

        setSearchParams(
            (prev) => {
                prev.set(QueryParam.BranchId, newValue.toString())
                prev.delete(QueryParam.UserId)

                return prev
            },
            { replace: true }
        )
    }, [])

    const updateUserId = useCallback((newValue: number) => {
        setUserId(newValue)
        setTasks(null)

        setSearchParams(
            (prev) => {
                prev.set(QueryParam.UserId, newValue.toString())

                return prev
            },
            { replace: true }
        )
    }, [])

    const openAddTaskDialog = useCallback(() => {
        setTaskDialogOpen(true)
        setTaskDialogData({
            userId: userId!!,
            task: null,
            onSuccess() {
                setTasks(null)
                loadTasks()
            },
        })
    }, [userId])

    const openEditTaskDialog = useCallback(
        (task: TaskDetails): void => {
            setTaskDialogOpen(true)
            setTaskDialogData({
                userId: userId!!,
                task: task,
                onSuccess(updatedTask) {
                    setTasks((tasks) => {
                        if (tasks === null) {
                            return null
                        }

                        return tasks.map((value) => {
                            if (value.id === updatedTask.id) {
                                return updatedTask
                            }

                            return value
                        })
                    })
                },
            })
        },
        [userId]
    )

    const deleteTask = useCallback((task: TaskDetails): void => {
        appDispatch(setInProgress())
        API.tasks
            .delete(task.id)
            .then(() => {
                setTasks((value) => {
                    if (value === null) {
                        return value
                    }

                    return value.filter((value) => value.id !== task.id)
                })

                appDispatch(setSuccess())
            })
            .catch((error) => {
                appDispatch(handleApiError(error))
                appDispatch(setFailure())
            })
    }, [])

    const closeTaskDialog = useCallback(() => {
        setTaskDialogOpen(false)
    }, [])

    useEffect(() => {
        appDispatch(changeMenuItem(MenuItemType.Tasks))
        appDispatch(setTitle(MenuItemType.Tasks))
    }, [])

    useEffect(() => {
        loadTasks()
    }, [userId])

    return (
        <>
            <BottomScrollListener
                // eslint-disable-next-line no-empty-function
                onBottom={loadMore ? loadTasks : (): void => {}}
                offset={300}
                debounce={100}
                debounceOptions={{ leading: false, trailing: true }}
                triggerOnNoScroll={false}
            >
                <StyledPaper elevation={2}>
                    <TasksUsersHeader
                        updateBranchId={updateBranchId}
                        updateUserId={updateUserId}
                        openAddDialog={openAddTaskDialog}
                        branchId={branchId}
                        userId={userId}
                    />

                    {!!tasks && (
                        <StyledStack
                            direction="column"
                            spacing={2}
                        >
                            {tasks.map((task, index) => (
                                <TasksListItem
                                    key={index}
                                    task={task}
                                    editTask={openEditTaskDialog}
                                    deleteTask={deleteTask}
                                />
                            ))}
                        </StyledStack>
                    )}

                    {loading && (
                        <Stack
                            direction="row"
                            justifyContent="center"
                        >
                            <CircularProgress />
                        </Stack>
                    )}

                    {!loading && !tasks && userId !== null && (
                        <Stack
                            direction="row"
                            justifyContent="center"
                        >
                            <Button
                                onClick={loadTasks}
                                variant="contained"
                            >
                                {strings.button.refresh}
                            </Button>
                        </Stack>
                    )}
                </StyledPaper>
            </BottomScrollListener>

            {!!taskDialogData && (
                <TaskDialog
                    open={taskDialogOpen}
                    data={taskDialogData}
                    closeDialog={closeTaskDialog}
                />
            )}
        </>
    )
}

export default React.memo(TasksPage)
