import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material'
import { API } from '../../api/api'
import { Branch, User } from '../../api/response'
import { DatePicker, DateValidationError } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals'

const strings = {
    label: {
        all: 'Wszystkie',
        branch: 'Oddział',
        user: 'Użytkownik',
        pickDay: 'Podgląd dla dnia',
    },
    button: {
        requestLocation: 'Zażądaj lokalizacji',
    },
}

interface OwnProps {
    readonly updateUserId: (userId: number) => void
    readonly updateDate: (date: Dayjs) => void
    readonly requestLocation: () => void
    readonly showChooser: boolean
}

const UserDayHeader: React.FunctionComponent<OwnProps> = ({ updateUserId, updateDate, requestLocation, showChooser }) => {
    const [branches, setBranches] = useState<Branch[]>([])
    const [branchId, setBranchId] = useState<number | null>(null)
    const [users, setUsers] = useState<User[]>([])
    const [userId, setUserId] = useState<number | null>(null)

    const [date, setDate] = useState<Dayjs>(dayjs())

    const onBranchChange = useCallback((event: SelectChangeEvent) => {
        const number = parseInt(event.target.value)

        if (number === -1) {
            setBranchId(null)
        } else {
            setBranchId(number)
        }
    }, [])

    const onUserChange = useCallback((event: SelectChangeEvent) => {
        setUserId(parseInt(event.target.value))
    }, [])

    const onDateChange = useCallback((value: Dayjs | null, context: FieldChangeHandlerContext<DateValidationError>) => {
        if (value && context.validationError === null) {
            setDate(value)
        }
    }, [])

    useEffect(() => {
        if (!showChooser) {
            return
        }

        API.users.branches().then((response) => {
            setBranches(response)
        })

        setDate(dayjs())
    }, [showChooser])

    useEffect(() => {
        if (!showChooser) {
            return
        }

        API.users.users(branchId).then((users) => {
            setUsers(users)
        })
    }, [showChooser, branchId])

    useEffect(() => {
        if (userId !== null) {
            updateUserId(userId)
        }
    }, [updateUserId, userId])

    useEffect(() => {
        updateDate(date)
    }, [updateDate, date])

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            mb={3}
        >
            <Stack
                direction="row"
                spacing={1}
            >
                {showChooser && (
                    <Box sx={{ minWidth: 280 }}>
                        <FormControl fullWidth={true}>
                            <InputLabel>{strings.label.branch}</InputLabel>
                            <Select
                                id="branch"
                                name="branch"
                                value={branchId?.toString() ?? '-1'}
                                label={strings.label.branch}
                                onChange={onBranchChange}
                            >
                                <MenuItem
                                    key={0}
                                    value={-1}
                                >
                                    {strings.label.all}
                                </MenuItem>
                                {branches.map(
                                    (value: Branch, index: number): React.ReactNode => (
                                        <MenuItem
                                            key={index}
                                            value={value.id}
                                        >
                                            {value.name}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                )}

                {showChooser && (
                    <Box sx={{ minWidth: 280 }}>
                        <FormControl fullWidth={true}>
                            <InputLabel>{strings.label.user}</InputLabel>
                            <Select
                                id="user"
                                name="user"
                                value={userId?.toString() ?? ''}
                                label={strings.label.user}
                                onChange={onUserChange}
                            >
                                {users.map(
                                    (user: User, index: number): React.ReactNode => (
                                        <MenuItem
                                            key={index}
                                            value={user.id}
                                        >
                                            {user.firstName} {user.lastName}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                )}

                <DatePicker
                    label={strings.label.pickDay}
                    format="YYYY-MM-DD"
                    value={date}
                    onChange={onDateChange}
                />
            </Stack>

            {(!!userId || !showChooser) && date.isSame(dayjs(), 'd') && <Button onClick={requestLocation}>{strings.button.requestLocation}</Button>}
        </Stack>
    )
}

export default React.memo(UserDayHeader)
