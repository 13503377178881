import React from 'react'
import { API } from '../../api/api'
import { useStateDispatchContext } from '../../state/stateContext'
import localStore from '../../state/LocalStore'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import LoginForm from './LoginForm'
import LoginHeader from './LoginHeader'
import { setAccount } from '../../state/accountActions'
import { setAccessToken } from '../../state/authActions'
import { routesDetails } from '../../routes/routesDetails'
import { useStableNavigate } from '../../routes/StableNavigateContext'

const LoginPage: React.FunctionComponent = () => {
    const navigate = useStableNavigate()
    const { appDispatch, sessionDispatch } = useStateDispatchContext()

    const submit = React.useCallback(async (username: string, password: string): Promise<void> => {
        appDispatch(setInProgress())

        try {
            const accessToken = await API.auth.token(username, password)
            const account = await API.auth.account(accessToken.accessToken)

            localStore.storeAccessToken(accessToken)
            localStore.storeAccount(account)

            sessionDispatch(setAccessToken(accessToken))
            sessionDispatch(setAccount(account))

            appDispatch(setSuccess())

            navigate(routesDetails.authenticated.homePage.path, { state: { from: 'login' } })
        } catch (error) {
            appDispatch(setFailure())

            throw error
        }
    }, [])

    return (
        <>
            <LoginHeader />
            <LoginForm submit={submit} />
        </>
    )
}

export default React.memo(LoginPage)
