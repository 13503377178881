import React, { useCallback, useState } from 'react'
import { TableCell, TableRow, Tooltip, IconButton, MenuList, MenuItem, ListItemIcon, Menu, ListItemText, PopoverPosition } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import RestoreIcon from '@mui/icons-material/Restore'
import EditIcon from '@mui/icons-material/Edit'
import AcceptIcon from '@mui/icons-material/Check'
import DiscardIcon from '@mui/icons-material/Close'
import { Branch } from '../../api/response'

const strings = {
    label: {
        trackLocation: 'Śledzenie lokalizacji',
        allowLeaveBranch: 'Zezwalaj na opuszczanie oddziału',
    },
    button: {
        delete: 'Usuń',
        restore: 'Przywróć',
        edit: 'Edytuj',
        cancel: 'Anuluj',
    },
}

interface OwnProps {
    readonly branch: Branch
    readonly editBranch: (branch: Branch) => void
    readonly deleteBranch: (branch: Branch) => void
    readonly restoreBranch: (branch: Branch) => void
}

const BranchTableRow: React.FunctionComponent<OwnProps> = ({ branch, editBranch, deleteBranch, restoreBranch }) => {
    const [deleteMode, setDeleteMode] = useState<boolean>(false)
    const [restoreMode, setRestoreMode] = useState<boolean>(false)
    const [menuPosition, setMenuPosition] = useState<null | PopoverPosition>(null)

    const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault()
        event.stopPropagation()

        setMenuPosition({ top: event.clientY + 10, left: event.clientX + 10 })
    }, [])

    const handleEdit = useCallback(() => {
        editBranch(branch)
    }, [branch])

    const handleDelete = useCallback(
        (event: React.MouseEvent<HTMLElement>): void => {
            setDeleteMode(true)
            handleOpenMenu(event)
        },
        [branch]
    )

    const handleRestore = useCallback(
        (event: React.MouseEvent<HTMLElement>): void => {
            setRestoreMode(true)
            handleOpenMenu(event)
        },
        [branch]
    )

    const closeMenu = useCallback(() => {
        setMenuPosition(null)
        setDeleteMode(false)
        setRestoreMode(false)
    }, [])

    const handleAccept = useCallback((): void => {
        if (deleteMode) {
            deleteBranch(branch)
        } else if (restoreMode) {
            restoreBranch(branch)
        }

        closeMenu()
    }, [branch, deleteMode, restoreMode])

    return (
        <>
            <TableRow hover={true}>
                <TableCell>{branch.id}</TableCell>
                <TableCell>{branch.name}</TableCell>
                <TableCell>
                    {branch.location.latitude}, {branch.location.longitude}
                    <br />
                    {branch.range} m
                </TableCell>
                <TableCell>
                    {branch.isActive && (
                        <Tooltip title={strings.button.edit}>
                            <IconButton onClick={handleEdit}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {branch.isActive && (
                        <Tooltip title={strings.button.delete}>
                            <IconButton onClick={handleDelete}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {!branch.isActive && (
                        <Tooltip title={strings.button.restore}>
                            <IconButton onClick={handleRestore}>
                                <RestoreIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </TableCell>
            </TableRow>
            <Menu
                open={menuPosition !== null}
                anchorPosition={menuPosition ?? undefined}
                anchorReference="anchorPosition"
                onClose={closeMenu}
                autoFocus={false}
            >
                {(deleteMode || restoreMode) && (
                    <MenuList disablePadding={true}>
                        <MenuItem onClick={handleAccept}>
                            <ListItemIcon>
                                <AcceptIcon />
                            </ListItemIcon>
                            {deleteMode && <ListItemText>{strings.button.delete}</ListItemText>}
                            {restoreMode && <ListItemText>{strings.button.restore}</ListItemText>}
                        </MenuItem>
                        <MenuItem onClick={closeMenu}>
                            <ListItemIcon>
                                <DiscardIcon />
                            </ListItemIcon>
                            <ListItemText>{strings.button.cancel}</ListItemText>
                        </MenuItem>
                    </MenuList>
                )}
            </Menu>
        </>
    )
}

export default React.memo(BranchTableRow)
