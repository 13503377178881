import React, { useMemo } from 'react'
import { Holiday } from '../../api/response'
import dayjs from 'dayjs'
import { Box, Tooltip, Typography, styled } from '@mui/material'

const strings = {
    daysOfWeek: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
}

const colors = {
    blue: '#2196f3',
    red: '#d50000',
    pink: '#e91e63',
    brown: '#795548',
}

const StyledTableHeader = styled('th')<{ bgColor: string }>(({ bgColor }) => ({
    backgroundColor: bgColor,
}))

const StyledDate = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    fontWeight: 'bolder',
    color: '#fff',
}))

const StyledDay = styled('div')<{ isToday: boolean }>(({ theme, isToday }) => ({
    ...theme.typography.subtitle2,
    color: '#fff',
    fontWeight: isToday ? 'bolder' : 'normal',
    textDecoration: isToday ? 'underline' : 'none',
}))

interface OwnProps {
    readonly date: dayjs.Dayjs
    readonly holidays: Holiday[]
}

const ScheduleTableHeaderDay: React.FunctionComponent<OwnProps> = ({ date, holidays }) => {
    const holiday = useMemo(() => {
        return holidays.find((item) => {
            if (date.isSame(item.date, 'day')) {
                return true
            }

            return false
        })
    }, [date, holidays])

    const color = useMemo(() => {
        if (holiday !== undefined) {
            return colors.pink
        } else if (date.day() === 0) {
            return colors.red
        } else if (date.day() === 6) {
            return colors.brown
        }

        return colors.blue
    }, [date, holiday])

    const isToday = useMemo(() => {
        return date.isSame(dayjs(), 'day')
    }, [date])

    return (
        <StyledTableHeader bgColor={color}>
            <Tooltip
                title={holiday !== undefined ? holiday.name : null}
                arrow={true}
            >
                <Box>
                    <StyledDate>
                        {date.format('YYYY-MM-DD')} {holiday !== undefined && <span>ℹ️</span>}
                    </StyledDate>
                    <StyledDay isToday={isToday}>{strings.daysOfWeek[date.day()]}</StyledDay>
                </Box>
            </Tooltip>
        </StyledTableHeader>
    )
}

export default React.memo(ScheduleTableHeaderDay)
