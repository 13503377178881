import { DateTime } from '../DateTime'
import { Location } from './Location'
import { Status } from './Status'
import { UserInfo } from './UserInfo'

export interface TaskDetails {
    readonly id: number
    readonly user: UserInfo
    readonly status: Status
    readonly title: string
    readonly description: string | null
    readonly comments: string | null
    readonly start: DateTime
    readonly end: DateTime | null
    readonly state: TaskState
    readonly startLocation: Location | null
    readonly endLocation: Location | null
    readonly startedAt: DateTime | null
    readonly endedAt: DateTime | null
}

export enum TaskState {
    Idle = 'idle',
    InProgress = 'in_progress',
    Completed = 'completed',
    Denied = 'denied',
}
