import {
    Button,
    CircularProgress,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
    styled,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { API } from '../../api/api'
import { ForbiddenPlace } from '../../api/response'
import { MenuItemType } from '../../model/MenuItemType'
import { changeMenuItem } from '../../state/menuItemActions'
import { setTitle } from '../../state/titleActions'
import AddIcon from '@mui/icons-material/Add'
import { BottomScrollListener } from 'react-bottom-scroll-listener'
import { useStateDispatchContext } from '../../state/stateContext'
import { handleApiError } from '../../state/apiErrorActions'
import ForbiddenPlaceTableRow from './ForbiddenPlaceTableRow'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { useStableNavigate } from '../../routes/StableNavigateContext'
import { routesDetails } from '../../routes/routesDetails'

const strings = {
    button: {
        refresh: 'Odśwież',
        create: 'Utwórz',
    },
    column: {
        id: 'Id',
        name: 'Nazwa',
        actions: 'Akcje',
    },
    error: {
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
}))

const ForbiddenPlacesPage: React.FunctionComponent = () => {
    const { appDispatch } = useStateDispatchContext()
    const navigate = useStableNavigate()

    const [forbiddenPlaces, setForbiddenPlaces] = useState<ForbiddenPlace[] | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadMore, setLoadMore] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)

    const loadForbiddenPlaces = useCallback(() => {
        if (loading) {
            return
        }

        const limit = 100

        setLoading(true)
        setRefresh(false)
        API.forbiddenPlaces
            .list(limit, forbiddenPlaces?.length)
            .then((newForbiddenPlaces) => {
                if (forbiddenPlaces === null) {
                    setForbiddenPlaces(newForbiddenPlaces)
                } else {
                    setForbiddenPlaces(forbiddenPlaces.concat(newForbiddenPlaces))
                }
                setLoadMore(newForbiddenPlaces.length === limit)
            })
            .catch((error) => {
                setRefresh(true)
                appDispatch(handleApiError(error))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [forbiddenPlaces, loading])

    const addForbiddenPlace = useCallback((): void => {
        navigate(routesDetails.authenticated.forbiddenPlace.to(0))
    }, [])

    const editForbiddenPlace = useCallback((forbiddenPlace: ForbiddenPlace): void => {
        navigate(routesDetails.authenticated.forbiddenPlace.to(forbiddenPlace.id))
    }, [])

    const deleteForbiddenPlace = useCallback((forbiddenPlace: ForbiddenPlace): void => {
        appDispatch(setInProgress())
        API.forbiddenPlaces
            .delete(forbiddenPlace.id)
            .then(() => {
                setForbiddenPlaces((value) => {
                    if (value === null) {
                        return value
                    }

                    return value.filter((value) => value.id !== forbiddenPlace.id)
                })
                appDispatch(setSuccess())
            })
            .catch((error) => {
                appDispatch(handleApiError(error))
                appDispatch(setFailure())
            })
    }, [])

    useEffect(() => {
        appDispatch(changeMenuItem(MenuItemType.ForbiddenPlaces))
        appDispatch(setTitle(MenuItemType.ForbiddenPlaces))
    }, [])

    useEffect(() => {
        loadForbiddenPlaces()
    }, [])

    return (
        <BottomScrollListener
            // eslint-disable-next-line no-empty-function
            onBottom={loadMore ? loadForbiddenPlaces : (): void => {}}
            offset={300}
            debounce={100}
            debounceOptions={{ leading: false, trailing: true }}
            triggerOnNoScroll={false}
        >
            <StyledPaper elevation={2}>
                <Toolbar>
                    <Tooltip title={strings.button.create}>
                        <IconButton onClick={addForbiddenPlace}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>

                {!!forbiddenPlaces && (
                    <Table size="small">
                        <colgroup>
                            <col width="20%" />
                            <col width="50%" />
                            <col width="30%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>{strings.column.id}</TableCell>
                                <TableCell>{strings.column.name}</TableCell>
                                <TableCell>{strings.column.actions}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {forbiddenPlaces.map((forbiddenPlace, index) => (
                                <ForbiddenPlaceTableRow
                                    key={index}
                                    forbiddenPlace={forbiddenPlace}
                                    editForbiddenPlace={editForbiddenPlace}
                                    deleteForbiddenPlace={deleteForbiddenPlace}
                                />
                            ))}
                        </TableBody>
                    </Table>
                )}

                {loading && (
                    <Stack
                        direction="row"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Stack>
                )}

                {refresh && !loading && (
                    <Stack
                        direction="row"
                        justifyContent="center"
                    >
                        <Button
                            onClick={loadForbiddenPlaces}
                            variant="contained"
                        >
                            {strings.button.refresh}
                        </Button>
                    </Stack>
                )}
            </StyledPaper>
        </BottomScrollListener>
    )
}

export default React.memo(ForbiddenPlacesPage)
