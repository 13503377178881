import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useStateDispatchContext } from '../../state/stateContext'
import { changeMenuItem } from '../../state/menuItemActions'
import { MenuItemType } from '../../model/MenuItemType'
import { setTitle } from '../../state/titleActions'
import { API } from '../../api/api'
import { handleApiError } from '../../state/apiErrorActions'
import { Button, Checkbox, CircularProgress, FormControlLabel, Paper, Stack, TextField, styled } from '@mui/material'
import { useStableNavigate } from '../../routes/StableNavigateContext'
import { routesDetails } from '../../routes/routesDetails'
import _ from 'lodash'
import Validator from 'validator'
import { JobPositionRequest } from '../../api/request'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { JobPosition } from '../../api/response'
import { showSnackbarMessage } from '../../state/messageActions'

const strings = {
    button: {
        refresh: 'Odśwież',
        save: 'Zapisz',
    },
    error: {
        nameLength: 'Wymagana wartość w przedziale <1, 128> znaków',
        nameRequired: 'Nazwa jest wymagana',
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
    label: {
        name: 'Nazwa',
        trackLocation: 'Śledzenie lokalizacji',
        allowLeaveBranch: 'Zezwalaj na opuszczanie oddziału',
    },
    message: {
        added: 'Nowe stanowisko pracy zostało dodane',
        edited: 'Stanowisko pracy zostało zaktualizowane',
    },
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
}))

enum FormFieldNames {
    Name = 'name',
    TrackLocation = 'trackLocation',
    AllowLeaveBranch = 'allowLeaveBranch',
}

interface FormErrors {
    name?: string
}

const JobPositionDetailsPage: React.FunctionComponent = () => {
    const { appDispatch } = useStateDispatchContext()
    const navigate = useStableNavigate()
    const { id } = useParams()

    const [loading, setLoading] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)
    const [errors, setErrors] = useState<FormErrors>({})
    const [name, setName] = useState<string>('')
    const [trackLocation, setTrackLocation] = useState<boolean>(false)
    const [allowBranchLeave, setAllowBranchLeave] = useState<boolean>(false)

    const jobPositionId = useMemo(() => {
        return parseInt(id ?? '0', 10)
    }, [id])

    const loadJobPosition = useCallback(() => {
        if (jobPositionId === 0) {
            setLoaded(true)

            return
        }

        if (loading) {
            return
        }

        setLoading(true)
        setRefresh(false)
        API.jobPositions
            .details(jobPositionId)
            .then((jobPosition) => {
                setName(jobPosition.name)
                setTrackLocation(jobPosition.trackLocation)
                setAllowBranchLeave(jobPosition.allowBranchLeave)
                setLoaded(true)
            })
            .catch((error) => {
                setRefresh(true)
                appDispatch(handleApiError(error))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [jobPositionId, loading])

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        switch (event.currentTarget.name) {
            case FormFieldNames.Name: {
                setName(event.target.value)
                break
            }
        }
    }, [])

    const onCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        switch (event.currentTarget.name) {
            case FormFieldNames.TrackLocation: {
                setTrackLocation(checked)
                if (!checked) {
                    setAllowBranchLeave(false)
                }

                break
            }
            case FormFieldNames.AllowLeaveBranch: {
                setAllowBranchLeave(checked)
                break
            }
        }
    }, [])

    function handleSaveClick(): void {
        const errors: FormErrors = {}

        if (Validator.isEmpty(name)) {
            errors.name = strings.error.nameRequired
        } else if (!Validator.isLength(name, { min: 1, max: 128 })) {
            errors.name = strings.error.nameLength
        }

        setErrors(errors)

        if (!_.isEmpty(errors)) {
            return
        }

        const request = new JobPositionRequest(name, trackLocation, allowBranchLeave)

        appDispatch(setInProgress())

        const promise: Promise<JobPosition> = jobPositionId !== 0 ? API.jobPositions.edit(jobPositionId, request) : API.jobPositions.add(request)

        promise
            .then((response) => {
                appDispatch(setSuccess())

                if (jobPositionId === 0) {
                    appDispatch(showSnackbarMessage(strings.message.added))
                    navigate(routesDetails.authenticated.jobPosition.to(response.id), { replace: true })
                } else {
                    appDispatch(showSnackbarMessage(strings.message.edited))
                }
            })
            .catch((error) => {
                appDispatch(setFailure())
                appDispatch(handleApiError(error))

                if (error === null) {
                    appDispatch(showSnackbarMessage(strings.error.unknown))
                }
            })
    }

    useEffect(() => {
        appDispatch(changeMenuItem(MenuItemType.JobPositions))
        appDispatch(setTitle(MenuItemType.JobPositions))
    }, [])

    useEffect(() => {
        const number = parseInt(id ?? '0', 10)
        if (isNaN(number)) {
            navigate(routesDetails.authenticated.jobPosition.to(0), { replace: true })
        } else {
            loadJobPosition()
        }
    }, [])

    return (
        <StyledPaper elevation={2}>
            {loading && (
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Stack>
            )}

            {refresh && !loading && (
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <Button
                        onClick={loadJobPosition}
                        variant="contained"
                    >
                        {strings.button.refresh}
                    </Button>
                </Stack>
            )}

            {loaded && (
                <Stack
                    direction="column"
                    spacing={2}
                    ml={3}
                    mr={3}
                >
                    <TextField
                        id={FormFieldNames.Name}
                        name={FormFieldNames.Name}
                        value={name}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.name}
                        margin="none"
                        onChange={onTextFieldChange}
                        helperText={errors.name}
                        error={errors.name !== undefined}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                name={FormFieldNames.TrackLocation}
                                checked={trackLocation}
                                onChange={onCheckboxChange}
                            />
                        }
                        label={strings.label.trackLocation}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                name={FormFieldNames.AllowLeaveBranch}
                                checked={allowBranchLeave}
                                disabled={!trackLocation}
                                onChange={onCheckboxChange}
                            />
                        }
                        label={strings.label.allowLeaveBranch}
                    />

                    <Stack
                        direction="row"
                        justifyContent="left"
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSaveClick}
                        >
                            {strings.button.save}
                        </Button>
                    </Stack>
                </Stack>
            )}
        </StyledPaper>
    )
}

export default React.memo(JobPositionDetailsPage)
