import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { changeMenuItem } from '../../state/menuItemActions'
import { setTitle } from '../../state/titleActions'
import { useStateContext, useStateDispatchContext } from '../../state/stateContext'
import { MenuItemType } from '../../model/MenuItemType'
import { Button, CircularProgress, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, styled } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { API } from '../../api/api'
import { handleApiError } from '../../state/apiErrorActions'
import { BottomScrollListener } from 'react-bottom-scroll-listener'
import UsersHeader from './UsersHeader'
import { User, UserRole, UserStatus, hasRole } from '../../api/response'
import UserTableRow from './UserTableRow'
import { useStableNavigate } from '../../routes/StableNavigateContext'
import { routesDetails } from '../../routes/routesDetails'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import ForbiddenPlaceIcon from '@mui/icons-material/LocationOffOutlined'

const strings = {
    button: {
        refresh: 'Odśwież',
    },
    column: {
        id: 'Id',
        email: 'Email',
        status: 'Status',
        fullName: 'Imię i nazwisko',
        jobPosition: 'Stanowisko',
        branch: 'Oddział',
        employmentPeriod: 'Okres zatrudnienia',
        actions: 'Akcje',
    },
}

enum QueryParam {
    BranchId = 'branchId',
    CheckShifts = 'checkShifts',
    NoShiftDays = 'noShiftDays',
    ShowFiredLocked = 'showFiredLocked',
    ShowRemoved = 'showRemoved',
}

const getParamNumberValue = (searchParams: URLSearchParams, queryParam: QueryParam, defaultValue: number): number => {
    let param = parseInt(searchParams.get(queryParam) ?? '')
    if (isNaN(param)) {
        param = defaultValue
    }

    return param
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
}))

const UsersPage: React.FunctionComponent = () => {
    const { sessionState } = useStateContext()
    const { appDispatch } = useStateDispatchContext()
    const navigate = useStableNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const lastAbortController = useRef<AbortController>()

    const [branchId, setBranchId] = useState<number | null>(() => {
        if (searchParams.has(QueryParam.BranchId)) {
            const value = getParamNumberValue(searchParams, QueryParam.BranchId, -1)
            if (value > 0) {
                return value
            }

            return null
        }

        return sessionState.account!.branch.id
    })
    const [checkShifts, setCheckShifts] = useState<boolean>(() => {
        if (searchParams.has(QueryParam.CheckShifts)) {
            const value = getParamNumberValue(searchParams, QueryParam.CheckShifts, 0)

            return value === 1
        }

        return false
    })
    const [noShiftDays, setNoShiftDays] = useState<number>(() => {
        if (searchParams.has(QueryParam.NoShiftDays)) {
            const value = getParamNumberValue(searchParams, QueryParam.NoShiftDays, 0)
            if (value > 1) {
                return value
            }
        }

        return 7
    })
    const [showFiredLocked, setShowFiredLocked] = useState<boolean>(() => {
        if (searchParams.has(QueryParam.ShowFiredLocked)) {
            const value = getParamNumberValue(searchParams, QueryParam.ShowFiredLocked, 0)

            return value === 1
        }

        return false
    })
    const [showRemoved, setShowRemoved] = useState<boolean>(() => {
        if (searchParams.has(QueryParam.ShowRemoved)) {
            const value = getParamNumberValue(searchParams, QueryParam.ShowRemoved, 0)

            return value === 1
        }

        return false
    })
    const [users, setUsers] = useState<User[] | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadMore, setLoadMore] = useState<boolean>(false)

    const hasShowUserMapRole = useMemo(() => {
        return hasRole(sessionState.account!, UserRole.ShowUserMap)
    }, [])

    const hasResetUserPhoneRole = useMemo(() => {
        return hasRole(sessionState.account!, UserRole.ResetUserPhone)
    }, [])

    const hasManageAdditionalBranches = useMemo(() => {
        return hasRole(sessionState.account!, UserRole.ManageAdditionalBranches)
    }, [])

    const loadUsers = async (): Promise<void> => {
        const limit = 50

        setLoading(true)

        if (lastAbortController.current) {
            lastAbortController.current.abort()
        }

        const abortController = new AbortController()
        lastAbortController.current = abortController

        API.users
            .users(branchId, checkShifts ? noShiftDays : null, showFiredLocked, showRemoved, limit, users?.length ?? 0, abortController.signal)
            .then((newUsers) => {
                if (abortController.signal.aborted) {
                    return
                }

                if (users === null) {
                    setUsers(newUsers)
                } else {
                    setUsers(users.concat(newUsers))
                }

                setLoadMore(newUsers.length === limit)
            })
            .catch((error) => {
                if (abortController.signal.aborted) {
                    return
                }

                appDispatch(handleApiError(error))
            })
            .finally(() => {
                if (abortController.signal.aborted) {
                    return
                }

                setLoading(false)
            })
    }

    const updateBranchId = useCallback((newValue: number | null) => {
        setBranchId(newValue)
        setUsers(null)

        setSearchParams(
            (prev) => {
                prev.set(QueryParam.BranchId, newValue?.toString() ?? '')

                return prev
            },
            { replace: true }
        )
    }, [])

    const updateCheckShifts = useCallback((newValue: boolean) => {
        setCheckShifts(newValue)
        setUsers(null)

        setSearchParams(
            (prev) => {
                prev.set(QueryParam.CheckShifts, (newValue ? 1 : 0).toString())

                return prev
            },
            { replace: true }
        )
    }, [])

    const updateNoShiftDays = useCallback((newValue: number) => {
        setNoShiftDays(newValue)
        setUsers(null)

        setSearchParams(
            (prev) => {
                prev.set(QueryParam.NoShiftDays, newValue.toString())

                return prev
            },
            { replace: true }
        )
    }, [])

    const updateShowFiredLocked = useCallback((newValue: boolean) => {
        setShowFiredLocked(newValue)
        setUsers(null)

        setSearchParams(
            (prev) => {
                prev.set(QueryParam.ShowFiredLocked, (newValue ? 1 : 0).toString())

                return prev
            },
            { replace: true }
        )
    }, [])

    const updateShowRemoved = useCallback((newValue: boolean) => {
        setShowRemoved(newValue)
        setUsers(null)

        setSearchParams(
            (prev) => {
                prev.set(QueryParam.ShowRemoved, (newValue ? 1 : 0).toString())

                return prev
            },
            { replace: true }
        )
    }, [])

    const showUserMap = useCallback((user: User): void => {
        navigate(routesDetails.authenticated.userMap.to(user.id))
    }, [])

    const deleteUser = useCallback((user: User): void => {
        appDispatch(setInProgress())
        API.users
            .delete(user.id)
            .then(() => {
                setUsers((users) => {
                    if (users === null) {
                        return users
                    }

                    return users.filter((item) => item.id !== user.id)
                })
                appDispatch(setSuccess())
            })
            .catch((error) => {
                appDispatch(handleApiError(error))
                appDispatch(setFailure())
            })
    }, [])

    const addUser = useCallback((): void => {
        navigate(routesDetails.authenticated.user.to(0))
    }, [])

    const editUser = useCallback((user: User): void => {
        navigate(routesDetails.authenticated.user.to(user.id))
    }, [])

    const changePassword = useCallback((user: User): void => {
        navigate(routesDetails.authenticated.userPassword.to(user.id))
    }, [])

    const changeStatus = useCallback((user: User, status: UserStatus): void => {
        appDispatch(setInProgress())
        API.users
            .status(user.id, status)
            .then((newUser) => {
                setUsers((users) => {
                    if (users === null) {
                        return users
                    }

                    return users.map((user) => {
                        if (user.id === newUser.id) {
                            return newUser
                        }

                        return user
                    })
                })
                appDispatch(setSuccess())
            })
            .catch((error) => {
                appDispatch(handleApiError(error))
                appDispatch(setFailure())
            })
    }, [])

    const resetUserPhone = useCallback((user: User): void => {
        appDispatch(setInProgress())
        API.users
            .resetPhone(user.id)
            .then(() => {
                appDispatch(setSuccess())
            })
            .catch((error) => {
                appDispatch(handleApiError(error))
                appDispatch(setFailure())
            })
    }, [])

    const manageAdditionalBranches = useCallback((user: User): void => {
        navigate(routesDetails.authenticated.userBranches.to(user.id))
    }, [])

    useEffect(() => {
        appDispatch(changeMenuItem(MenuItemType.Users))
        appDispatch(setTitle(MenuItemType.Users))
    }, [])

    useEffect(() => {
        loadUsers()
    }, [branchId, checkShifts, noShiftDays, showFiredLocked, showRemoved])

    return (
        <>
            <BottomScrollListener
                // eslint-disable-next-line no-empty-function
                onBottom={loadMore ? loadUsers : (): void => {}}
                offset={300}
                debounce={100}
                debounceOptions={{ leading: false, trailing: true }}
                triggerOnNoScroll={false}
            >
                <StyledPaper elevation={2}>
                    <UsersHeader
                        addUser={addUser}
                        updateBranchId={updateBranchId}
                        updateCheckShifts={updateCheckShifts}
                        updateNoShiftDays={updateNoShiftDays}
                        updateShowFiredLocked={updateShowFiredLocked}
                        updateShowRemoved={updateShowRemoved}
                        branchId={branchId}
                        checkShifts={checkShifts}
                        noShiftDays={noShiftDays}
                        showFiredLocked={showFiredLocked}
                        showRemoved={showRemoved}
                    />

                    {!!users && (
                        <Table size="small">
                            <colgroup>
                                <col width="10%" />
                                <col width="25%" />
                                <col width="25%" />
                                <col width="20%" />
                                <col width="5%" />
                                <col width="15%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{strings.column.id}</TableCell>
                                    <TableCell>
                                        <Typography variant="inherit">{strings.column.email}</Typography>
                                        <Typography variant="inherit">{strings.column.fullName}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="inherit">{strings.column.branch}</Typography>
                                        <Typography variant="inherit">{strings.column.jobPosition}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="inherit">{strings.column.status}</Typography>
                                        <Typography variant="inherit">{strings.column.employmentPeriod}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <ForbiddenPlaceIcon />
                                    </TableCell>
                                    <TableCell>{strings.column.actions}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user, index) => (
                                    <UserTableRow
                                        key={index}
                                        user={user}
                                        hasShowUserMapRole={hasShowUserMapRole}
                                        hasResetUserPhoneRole={hasResetUserPhoneRole}
                                        hasManageAdditionalBranches={hasManageAdditionalBranches}
                                        editUser={editUser}
                                        deleteUser={deleteUser}
                                        changePassword={changePassword}
                                        changeStatus={changeStatus}
                                        showMap={showUserMap}
                                        resetPhone={resetUserPhone}
                                        manageAdditionalBranches={manageAdditionalBranches}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    )}

                    {loading && (
                        <Stack
                            direction="row"
                            justifyContent="center"
                        >
                            <CircularProgress />
                        </Stack>
                    )}

                    {!loading && !users && (
                        <Stack
                            direction="row"
                            justifyContent="center"
                        >
                            <Button
                                onClick={loadUsers}
                                variant="contained"
                            >
                                {strings.button.refresh}
                            </Button>
                        </Stack>
                    )}
                </StyledPaper>
            </BottomScrollListener>
        </>
    )
}

export default React.memo(UsersPage)
