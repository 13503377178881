export class BranchRequest {
    constructor(
        readonly name: string,
        readonly range: number,
        readonly latitude: number,
        readonly longitude: number,
        readonly outsideBranchMaxTime: number,
        readonly returnMaxTime: number
    ) {}
}
