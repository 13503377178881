import React, { useCallback } from 'react'
import { TableCell, TableRow, Tooltip, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Holiday, holidayTypeName } from '../../api/response'
import { formatDate } from '../../utils/formatDate'
import dayjs from 'dayjs'

const strings = {
    button: {
        delete: 'Usuń',
        edit: 'Edytuj',
    },
}

interface OwnProps {
    readonly holiday: Holiday
    readonly editHoliday: (item: Holiday) => void
    readonly deleteHoliday: (item: Holiday) => void
}

const HolidayTableRow: React.FunctionComponent<OwnProps> = ({ holiday, editHoliday, deleteHoliday }) => {
    const handleEdit = useCallback(() => {
        editHoliday(holiday)
    }, [holiday])

    const handleDelete = useCallback(() => {
        deleteHoliday(holiday)
    }, [holiday])

    return (
        <TableRow hover={true}>
            <TableCell>{holiday.id}</TableCell>
            <TableCell>{holiday.name}</TableCell>
            <TableCell>{formatDate(holiday.date)}</TableCell>
            <TableCell>{holidayTypeName(holiday.type)}</TableCell>
            <TableCell>
                {!dayjs(holiday.date).isBefore(dayjs()) && (
                    <>
                        <Tooltip title={strings.button.edit}>
                            <IconButton onClick={handleEdit}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={strings.button.delete}>
                            <IconButton onClick={handleDelete}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
            </TableCell>
        </TableRow>
    )
}

export default React.memo(HolidayTableRow)
