import { ScheduleUserDayShiftFlag } from '../response'

export class ScheduleShiftRequest {
    constructor(
        readonly userId: number,
        readonly day: number,
        readonly start: string,
        readonly end: string,
        readonly note: string | null,
        readonly flags: ScheduleUserDayShiftFlag[]
    ) {}
}
