import { Branch } from './Branch'
import { JobPosition } from './JobPosition'
import { UserRole } from './User'

export interface Account {
    readonly id: number
    readonly branch: Branch
    readonly jobPosition: JobPosition
    readonly email: string
    readonly roles: UserRole[]
    readonly firstName: string
    readonly lastName: string
}

export const hasRole: (account: Account, role: UserRole) => boolean = (account, role): boolean => {
    return account.roles.includes(role)
}

export const hasOneOfRoles: (account: Account, roles: UserRole[]) => boolean = (account, roles): boolean => {
    for (const role of roles) {
        if (account.roles.includes(role)) {
            return true
        }
    }

    return false
}
