import React, { useEffect, useMemo, useState } from 'react'
import { SplitShifts, UserDayShift } from '../../api/response'
import dayjs, { Dayjs } from 'dayjs'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField } from '@mui/material'
import { TimePicker, TimeValidationError } from '@mui/x-date-pickers'
import { useCallback } from 'react'
import { useStateDispatchContext } from '../../state/stateContext'
import { API } from '../../api/api'
import { SplitShiftRequest } from '../../api/request'
import { handleApiError } from '../../state/apiErrorActions'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals'
import { formatTime } from '../../utils/formatDate'

const strings = {
    label: {
        title: 'Czas pracy',
        content: (date: string, fullName: string): string => `Podziel okres czasu pracy dla ${fullName} w ${date}`,
        start: 'Od:',
        end: 'Do:',
    },
    button: {
        cancel: 'Anuluj',
        save: 'Zapisz',
    },
}

export interface SplitUserShiftDialogData {
    shift: UserDayShift
    date: dayjs.Dayjs
    user: { id: number; fullName: string }
    onSuccess: (splitShifts: SplitShifts) => void
}

interface OwnProps {
    readonly open: boolean
    readonly data: SplitUserShiftDialogData
    readonly closeDialog: () => void
}

const SplitUserShiftDialog: React.FunctionComponent<OwnProps> = ({ open, data, closeDialog }) => {
    const { date, user, onSuccess } = data

    const [oldEndTime, setOldEndTime] = useState<Dayjs | null>(null)
    const [newStartTime, setNewStartTime] = useState<Dayjs | null>(null)

    const { appDispatch } = useStateDispatchContext()

    useEffect(() => {
        if (!open) {
            setOldEndTime(null)
            setNewStartTime(null)
        }
    }, [open])

    const oldStartTime = useMemo(() => {
        const shift = data.shift

        if (shift.edited !== null) {
            return shift.edited.start
        }

        return shift.start
    }, [data])

    const newEndTime = useMemo(() => {
        const shift = data.shift

        if (shift.edited !== null) {
            return shift.edited.end
        }

        if (shift.end === null) {
            return null
        }

        return shift.end
    }, [data])

    const onOldEndTimeChange = useCallback(
        (value: Dayjs | null, context: FieldChangeHandlerContext<TimeValidationError>) => {
            if (value && (!context.validationError || context.validationError === 'minutesStep')) {
                let date = value

                if (!date.isSame(oldStartTime, 'day')) {
                    date = dayjs(oldStartTime).set('hour', value.hour()).set('minute', value.minute()).set('second', 0)
                }

                if (!date.isAfter(oldStartTime, 'minute')) {
                    date = date.add(1, 'day')
                }

                setOldEndTime(date)
            }
        },
        [oldStartTime]
    )

    const onNewStartTimeChange = useCallback(
        (value: Dayjs | null, context: FieldChangeHandlerContext<TimeValidationError>) => {
            if (value && (!context.validationError || context.validationError === 'minutesStep')) {
                let date = value

                if (!date.isSame(oldStartTime, 'day')) {
                    date = dayjs(oldStartTime).set('hour', value.hour()).set('minute', value.minute()).set('second', 0)
                }

                if (!date.isAfter(oldStartTime, 'minute')) {
                    date = date.add(1, 'day')
                }

                setNewStartTime(date)
            }
        },
        [oldStartTime]
    )

    function handleSaveClick(): void {
        if (oldEndTime === null || newStartTime === null) {
            return
        }

        const request = new SplitShiftRequest(oldEndTime.format('HH:mm'), newStartTime.format('HH:mm'))

        appDispatch(setInProgress())

        API.shift
            .split(data.shift!.uuid, request)
            .then((shift) => {
                onSuccess(shift)
                appDispatch(setSuccess())
                closeDialog()
            })
            .catch((error) => {
                appDispatch(setFailure())
                appDispatch(handleApiError(error))
            })
    }

    return (
        <Dialog
            open={open}
            disableEscapeKeyDown={true}
        >
            <DialogTitle>{strings.label.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{strings.label.content(date.format('YYYY-MM-DD'), user.fullName)}</DialogContentText>
                <Stack
                    direction="column"
                    spacing={2}
                >
                    <Stack
                        mt={2}
                        direction="row"
                        alignItems="stretch"
                        spacing={2}
                    >
                        <TextField
                            value={formatTime(oldStartTime)}
                            fullWidth={true}
                            label={strings.label.start}
                            margin="none"
                            disabled={true}
                        />

                        <TimePicker
                            ampm={false}
                            ampmInClock={false}
                            label={strings.label.end}
                            format="HH:mm"
                            minutesStep={1}
                            timeSteps={{ hours: 1, minutes: 1 }}
                            onChange={onOldEndTimeChange}
                            value={oldEndTime}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                },
                            }}
                        />
                    </Stack>

                    <Stack
                        mt={2}
                        direction="row"
                        alignItems="stretch"
                        spacing={2}
                    >
                        <TimePicker
                            ampm={false}
                            ampmInClock={false}
                            label={strings.label.start}
                            format="HH:mm"
                            minutesStep={1}
                            timeSteps={{ hours: 1, minutes: 1 }}
                            onChange={onNewStartTimeChange}
                            value={newStartTime}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                },
                            }}
                        />

                        <TextField
                            value={newEndTime !== null ? formatTime(newEndTime) : '‒‒:‒‒'}
                            fullWidth={true}
                            label={strings.label.end}
                            margin="none"
                            disabled={true}
                        />
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>{strings.button.cancel}</Button>
                <Button
                    onClick={handleSaveClick}
                    disabled={
                        oldEndTime === null ||
                        newStartTime === null ||
                        newEndTime === null ||
                        !oldEndTime.isAfter(oldStartTime, 'minute') ||
                        !oldEndTime.isBefore(newStartTime, 'minute') ||
                        !newStartTime.isBefore(newEndTime, 'minute')
                    }
                >
                    {strings.button.save}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(SplitUserShiftDialog)
