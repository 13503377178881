import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Paper, Stack, styled, TextField } from '@mui/material'
import _ from 'lodash'
import { useParams } from 'react-router'
import Validator from 'validator'
import { API } from '../../api/api'
import { MenuItemType } from '../../model/MenuItemType'
import { changeMenuItem } from '../../state/menuItemActions'
import { setTitle } from '../../state/titleActions'
import { UserPasswordRequest } from '../../api/request'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { showSnackbarMessage } from '../../state/messageActions'
import { useStateDispatchContext } from '../../state/stateContext'
import { useStableNavigate } from '../../routes/StableNavigateContext'
import { routesDetails } from '../../routes/routesDetails'
import { handleApiError } from '../../state/apiErrorActions'

const strings = {
    button: {
        save: 'Zapisz',
    },
    error: {
        passwordRequired: 'Hasło jest wymagane',
        passwordLength: 'Minimalna długość to 8 znaków',
        rePasswordInvalid: 'Hasła nie są takie same',
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
    label: {
        password: 'Hasło',
        rePassword: 'Powtórz hasło',
    },
    message: {
        success: 'Hasło zostało zaktualizowane',
    },
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
}))

enum FormFieldNames {
    Password = 'password',
    RePassword = 'rePassword',
}

interface FormErrors {
    password?: string
    rePassword?: string
}

const UserPasswordPage: React.FunctionComponent = () => {
    const { appDispatch } = useStateDispatchContext()
    const navigate = useStableNavigate()
    const { id } = useParams()

    const [errors, setErrors] = useState<FormErrors>({})
    const [password, setPassword] = useState<string>('')
    const [rePassword, setRePassword] = useState<string>('')

    const userId = useMemo(() => {
        return parseInt(id ?? '0', 10)
    }, [id])

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        switch (event.currentTarget.name) {
            case FormFieldNames.Password: {
                setPassword(event.target.value)
                break
            }
            case FormFieldNames.RePassword: {
                setRePassword(event.target.value)
                break
            }
        }
    }, [])

    function handleSaveClick(): void {
        const errors: FormErrors = {}

        if (Validator.isEmpty(password)) {
            errors.password = strings.error.passwordRequired
        } else if (!Validator.isLength(password, { min: 8 })) {
            errors.password = strings.error.passwordLength
        } else if (password !== rePassword) {
            errors.rePassword = strings.error.rePasswordInvalid
        }

        setErrors(errors)

        if (!_.isEmpty(errors)) {
            return
        }

        const request = new UserPasswordRequest(password)

        appDispatch(setInProgress())

        API.users
            .password(userId, request)
            .then(() => {
                appDispatch(setSuccess())
                appDispatch(showSnackbarMessage(strings.message.success))

                setPassword('')
                setRePassword('')
            })
            .catch((error) => {
                appDispatch(setFailure())
                appDispatch(handleApiError(error))

                if (error === null) {
                    appDispatch(showSnackbarMessage(strings.error.unknown))
                }
            })
    }

    useEffect(() => {
        appDispatch(changeMenuItem(MenuItemType.Users))
        appDispatch(setTitle(MenuItemType.Users))
    }, [])

    useEffect(() => {
        const number = parseInt(id ?? '0', 10)
        if (isNaN(number)) {
            navigate(routesDetails.authenticated.users.path, { replace: true })
        }
    }, [])

    return (
        <StyledPaper elevation={2}>
            <Stack
                direction="column"
                spacing={2}
                ml={3}
                mr={3}
            >
                <TextField
                    id={FormFieldNames.Password}
                    name={FormFieldNames.Password}
                    value={password}
                    multiline={false}
                    required={true}
                    fullWidth={true}
                    label={strings.label.password}
                    margin="none"
                    type="password"
                    onChange={onTextFieldChange}
                    helperText={errors.password}
                    error={errors.password !== undefined}
                />

                <TextField
                    id={FormFieldNames.RePassword}
                    name={FormFieldNames.RePassword}
                    value={rePassword}
                    multiline={false}
                    required={true}
                    fullWidth={true}
                    label={strings.label.rePassword}
                    margin="none"
                    type="password"
                    onChange={onTextFieldChange}
                    helperText={errors.rePassword}
                    error={errors.rePassword !== undefined}
                />

                <Stack
                    direction="row"
                    justifyContent="left"
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSaveClick}
                    >
                        {strings.button.save}
                    </Button>
                </Stack>
            </Stack>
        </StyledPaper>
    )
}

export default React.memo(UserPasswordPage)
