import { Grid, Typography, styled } from '@mui/material'
import React from 'react'
import logo from '../../assets/logo.png'

const strings = {
    text: {
        appName: 'cfrental',
    },
}

const StyledGrid = styled(Grid)({
    paddingBottom: 16,
    paddingTop: 128,
})

const StyledLogo = styled('img')({
    height: 200,
    width: 200,
})

const StyledTitle = styled(Typography)({
    fontSize: 72,
})

const LoginLogo: React.FunctionComponent = () => (
    <StyledGrid
        container={true}
        spacing={0}
        alignItems="center"
        justifyContent="center"
        direction="column"
    >
        <Grid item={true}>
            <StyledLogo
                src={logo}
                alt={strings.text.appName}
            />
        </Grid>
        <Grid item={true}>
            <StyledTitle>{strings.text.appName}</StyledTitle>
        </Grid>
    </StyledGrid>
)

export default React.memo(LoginLogo)
