import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { changeMenuItem } from '../../state/menuItemActions'
import { setTitle } from '../../state/titleActions'
import { useStateDispatchContext } from '../../state/stateContext'
import { MenuItemType } from '../../model/MenuItemType'
import { useParams } from 'react-router'
import { Button, Grid, Paper, Stack, styled } from '@mui/material'
import { useStableNavigate } from '../../routes/StableNavigateContext'
import { routesDetails } from '../../routes/routesDetails'
import { API } from '../../api/api'
import { handleApiError } from '../../state/apiErrorActions'
import { Location, UserDay } from '../../api/response'
import dayjs, { Dayjs } from 'dayjs'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { showSnackbarMessage } from '../../state/messageActions'
import UserDayHeader from './UserDayHeader'
import UserMapContent from './UserMapContent'
import UserLogContent from './UserLogContent'

const strings = {
    title: 'Mapa',
    button: {
        refresh: 'Odśwież',
    },
    message: {
        requestLocationSuccess: 'Prośba o lokalizację została wysłana.',
    },
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
}))

export interface InfoWindowData {
    markerId: string
    location: google.maps.LatLngLiteral
}

const UserDayPage: React.FunctionComponent = () => {
    const { appDispatch } = useStateDispatchContext()
    const navigate = useStableNavigate()
    const { id } = useParams()

    const [refresh, setRefresh] = useState<boolean>(false)
    const [data, setData] = useState<UserDay | null>(null)

    const [previousUserId, setPreviousUserId] = useState<number | null>(null)
    const [userId, setUserId] = useState<number | null>(null)

    const [date, setDate] = useState<Dayjs>(dayjs())
    const [infoWindow, setInfoWindow] = useState<InfoWindowData | null>(null)
    const [location, setLocation] = useState<Location | null>(null)

    const paramId = useMemo(() => id, [id])

    const openInfoWindow = useCallback((markerId: string, location: google.maps.LatLngLiteral): void => {
        setInfoWindow((current) => {
            if (current?.markerId === markerId) {
                return null
            }

            return { markerId, location }
        })
    }, [])

    const closeInfoWindow = useCallback((): void => {
        setInfoWindow(null)
    }, [])

    const showLocation = useCallback((location: Location): void => {
        setLocation(location)
    }, [])

    const clearLocation = useCallback((): void => {
        setLocation(null)
    }, [])

    const load = useCallback(() => {
        if (userId === null) {
            return
        }

        appDispatch(setInProgress())
        setRefresh(false)

        if (previousUserId !== userId) {
            setData(null)
        }

        setPreviousUserId(userId)

        API.users
            .userDay(userId, date.format('YYYY-MM-DD'))
            .then((userDayLocations) => {
                setData(userDayLocations)
                setInfoWindow(null)
                setLocation(null)
                appDispatch(setSuccess())
            })
            .catch((error) => {
                setRefresh(true)
                appDispatch(setFailure())
                appDispatch(handleApiError(error))
            })
    }, [userId, date])

    const onRequestLocationClick = useCallback(() => {
        if (userId === null) {
            return
        }

        appDispatch(setInProgress())

        API.users
            .requestLocation(userId)
            .then(() => {
                appDispatch(setSuccess())
                appDispatch(showSnackbarMessage(strings.message.requestLocationSuccess))
            })
            .catch((error) => {
                appDispatch(setFailure())
                appDispatch(handleApiError(error))
            })
    }, [userId])

    useEffect(() => {
        if (data !== null) {
            appDispatch(setTitle(`${strings.title} - ${data.user.firstName} ${data.user.lastName}`))
        } else {
            appDispatch(setTitle(strings.title))
        }
    }, [data])

    useEffect(() => {
        load()
    }, [userId, date])

    useEffect(() => {
        if (id !== undefined) {
            appDispatch(changeMenuItem(MenuItemType.Users))

            const number = parseInt(id, 10)
            if (isNaN(number)) {
                navigate(routesDetails.error.notFound.path, { replace: true })
            } else {
                setUserId(number)
            }
        } else {
            appDispatch(changeMenuItem(MenuItemType.Map))

            setPreviousUserId(null)
            setUserId(null)
            setData(null)
            setDate(dayjs())
        }
    }, [paramId])

    return (
        <StyledPaper elevation={2}>
            <UserDayHeader
                updateUserId={setUserId}
                updateDate={setDate}
                requestLocation={onRequestLocationClick}
                showChooser={id === undefined}
            />

            {refresh && (
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <Button
                        onClick={load}
                        variant="contained"
                    >
                        {strings.button.refresh}
                    </Button>
                </Stack>
            )}

            {!!data && (
                <Grid
                    container={true}
                    direction="row"
                    spacing={2}
                >
                    <Grid
                        item={true}
                        xs={8}
                        onMouseEnter={(): void => {
                            setLocation(null)
                        }}
                    >
                        <UserMapContent
                            data={data}
                            infoWindow={infoWindow}
                            openInfoWindow={openInfoWindow}
                            closeInfoWindow={closeInfoWindow}
                            location={location}
                        />
                    </Grid>
                    <Grid
                        item={true}
                        xs={4}
                    >
                        <UserLogContent
                            data={data}
                            showLocation={showLocation}
                            clearLocation={clearLocation}
                        />
                    </Grid>
                </Grid>
            )}
        </StyledPaper>
    )
}

export default React.memo(UserDayPage)
