import { Reducer } from 'react'
import { AccessToken } from '../api/response'
import { Action } from './stateContext'

enum AuthActionType {
    SetAccessToken = 'SetAccessToken',
    ClearAccessToken = 'ClearAccessToken',
}

interface SetAccessTokenAction extends Action {
    type: AuthActionType.SetAccessToken
    token: AccessToken
}

interface ClearAccessTokenAction extends Action {
    type: AuthActionType.ClearAccessToken
}

type AuthAction = SetAccessTokenAction | ClearAccessTokenAction

export const setAccessToken = (token: AccessToken): SetAccessTokenAction => ({
    type: AuthActionType.SetAccessToken,
    token,
})
export const clearAccessToken = (): ClearAccessTokenAction => ({ type: AuthActionType.ClearAccessToken })

type AuthState = AccessToken | null

export const authReducer: Reducer<AuthState, AuthAction> = (state: AuthState, action: AuthAction): AuthState => {
    switch (action.type) {
        case AuthActionType.SetAccessToken: {
            return action.token
        }
        case AuthActionType.ClearAccessToken: {
            return null
        }
        default: {
            return state
        }
    }
}
