import { Reducer } from 'react'
import { Action } from './stateContext'

enum ProgressActionType {
    SetInProgress = 'SetInProgress',
    SetSuccess = 'SetSuccess',
    SetFailure = 'SetFailure',
}

interface InProgressAction extends Action {
    type: ProgressActionType.SetInProgress
}

interface SuccessAction extends Action {
    type: ProgressActionType.SetSuccess
}

interface FailureAction {
    type: ProgressActionType.SetFailure
}

type ProgressAction = InProgressAction | SuccessAction | FailureAction

export const setInProgress = (): InProgressAction => ({ type: ProgressActionType.SetInProgress })
export const setSuccess = (): SuccessAction => ({ type: ProgressActionType.SetSuccess })
export const setFailure = (): FailureAction => ({ type: ProgressActionType.SetFailure })

type ProgressState = number

export const progressReducer: Reducer<ProgressState, ProgressAction> = (state: ProgressState, action: ProgressAction): ProgressState => {
    switch (action.type) {
        case ProgressActionType.SetInProgress: {
            return state + 1
        }
        case ProgressActionType.SetSuccess: {
            return state - 1
        }
        case ProgressActionType.SetFailure: {
            return state - 1
        }
        default: {
            return state
        }
    }
}
