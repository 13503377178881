import dayjs from 'dayjs'
import _ from 'lodash'

export const formatDate = (date: Date | null): string | null => dayjs(date).format('YYYY-MM-DD')

export const formatTime = (date: Date | null): string | null => dayjs(date).format('HH:mm')

export const formatDateTime = (date: Date | null): string | null => dayjs(date).format('YYYY-MM-DD HH:mm')

export const formatOptionalDate = (date: Date | null): string | null => {
    if (date !== null) {
        return dayjs(date).format('YYYY-MM-DD')
    }

    return null
}

export const formatPeriod = (period: number): string => {
    if (period < 60) {
        return '00:00'
    }

    let minutes = Math.floor(period / 60)
    const hours = Math.floor(minutes / 60)
    minutes = minutes - hours * 60

    const hoursString = _.padStart(hours.toString(), 2, '0')
    const minutesString = _.padStart(minutes.toString(), 2, '0')

    return `${hoursString}:${minutesString}`
}
