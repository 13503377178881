import React, { PropsWithChildren, Reducer } from 'react'
import { initializeClient, configureClient } from '../api/client'
import { AccessToken, Account } from '../api/response'
import { MenuItemType } from '../model/MenuItemType'
import { authReducer, clearAccessToken } from './authActions'
import localStore from './LocalStore'
import { menuItemReducer } from './menuItemActions'
import { Message, messageReducer } from './messageActions'
import { progressReducer } from './progressActions'
import { titleReducer } from './titleActions'
import { accountReducer, clearAccount } from './accountActions'
import { apiErrorReducer } from './apiErrorActions'
import { ApiError } from '../api/ApiError'

export interface Action {
    type: string
}

export interface SessionState {
    readonly auth: AccessToken | null
    readonly account: Account | null
}

export interface AppState {
    readonly progress: number
    readonly apiError: ApiError | null
    readonly menuItem: MenuItemType | null
    readonly message: Message | null
    readonly title: string | null
}

const initialSessionState: SessionState = {
    auth: localStore.accessToken(),
    account: localStore.account(),
}

const initialAppState: AppState = {
    progress: 0,
    apiError: null,
    menuItem: null,
    message: null,
    title: null,
}

const combineReducers: <S>(reducers: { [K in keyof S]: Reducer<any, any> }) => (state: S, action: Action) => S = (reducers) => (state, action) => {
    const keys = Object.keys(reducers)

    return keys.reduce<typeof state>(
        (acc, prop) => ({
            ...acc,
            // @ts-ignore
            [prop]: reducers[prop](acc[prop], action),
        }),
        state
    )
}

const appReducer = combineReducers<AppState>({
    progress: progressReducer,
    apiError: apiErrorReducer,
    menuItem: menuItemReducer,
    message: messageReducer,
    title: titleReducer,
})

const sessionReducer = combineReducers<SessionState>({
    auth: authReducer,
    account: accountReducer,
})

export interface StateContextType {
    appState: AppState
    sessionState: SessionState
}

export interface StateDispatchContextType {
    appDispatch: React.Dispatch<Action>
    sessionDispatch: React.Dispatch<Action>
}

// @ts-ignore
export const StateContext = React.createContext<StateContextType>(undefined)
StateContext.displayName = 'StateContext'

// @ts-ignore
export const StateDispatchContext = React.createContext<StateDispatchContextType>(undefined)
StateDispatchContext.displayName = 'StateDispatchContext'

export const StateProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
    const [appState, appDispatch] = React.useReducer(appReducer, initialAppState)
    const [sessionState, sessionDispatch] = React.useReducer(sessionReducer, initialSessionState)

    const stateValue = React.useMemo<StateContextType>(() => ({ appState, sessionState }), [appState, sessionState])

    const stateDispatchValue = React.useMemo<StateDispatchContextType>(() => ({ appDispatch, sessionDispatch }), [appDispatch, sessionDispatch])

    React.useMemo(() => {
        initializeClient()
    }, [])

    React.useMemo(() => {
        configureClient(stateValue)
    }, [sessionState.auth])

    return (
        <StateDispatchContext.Provider value={stateDispatchValue}>
            <StateContext.Provider value={stateValue}>{children}</StateContext.Provider>
        </StateDispatchContext.Provider>
    )
}

export const logout = (sessionDispatch: React.Dispatch<Action>): void => {
    localStore.clearStore()
    sessionDispatch(clearAccessToken())
    sessionDispatch(clearAccount())
}

export function useStateContext(): StateContextType {
    const context = React.useContext(StateContext)
    if (context === undefined) {
        throw new Error('useStateContext must be used within a Provider')
    }

    return context
}

export function useStateDispatchContext(): StateDispatchContextType {
    const context = React.useContext(StateDispatchContext)
    if (context === undefined) {
        throw new Error('useStateDispatchContext must be used within a Provider')
    }

    return context
}
