import React from 'react'
import { logout, useStateContext, useStateDispatchContext } from '../../state/stateContext'
import { isErrorResponse } from '../../api/response'
import _ from 'lodash'
import { clearApiError } from '../../state/apiErrorActions'
import { showDialogMessage } from '../../state/messageActions'

const strings = {
    button: {
        ok: 'OK',
    },
    error400: 'Przesłano błędne dane, spróbuj ponownie',
    error401: 'Twoja sesja wygasła, nastąpi wylogowanie',
    error403: 'Nie masz uprawnień do dostępu do tego zasobu',
    error404: 'Wybrany zasób nie istnieje',
    error5xx: 'Coś poszło nie tak, proszę spróbuj ponownie później',
    errorUnexpected: 'Wystąpił niespodziewany błąd, proszę spróbuj ponownie',
    errorNetwork: 'Brak połączenia internetowego',
    errorTimeout: 'Proszę spróbować ponownie',
}

const ApiErrorHandler: React.FunctionComponent = () => {
    const { appState, sessionState } = useStateContext()
    const { appDispatch, sessionDispatch } = useStateDispatchContext()

    const { apiError } = appState

    React.useEffect(() => {
        if (apiError === null) {
            return
        }

        const error = apiError.parent

        if (error.message === 'Network Error') {
            appDispatch(showDialogMessage(strings.errorNetwork))
        } else if (_.startsWith(error.message, 'timeout')) {
            appDispatch(showDialogMessage(strings.errorTimeout))
        } else if (error.response !== undefined) {
            const { status, data } = error.response

            if (status === 401 && sessionState.auth !== null) {
                appDispatch(
                    showDialogMessage(strings.error401, () => {
                        logout(sessionDispatch)
                    })
                )
            } else if (status >= 400 && status < 500 && isErrorResponse(data) && data.message.length > 0) {
                appDispatch(showDialogMessage(data.message))
            } else if (status === 400) {
                appDispatch(showDialogMessage(strings.error400))
            } else if (status === 403) {
                appDispatch(showDialogMessage(strings.error403))
            } else if (status === 404) {
                appDispatch(showDialogMessage(strings.error404))
            } else if (status < 500) {
                appDispatch(showDialogMessage(strings.errorUnexpected))
            } else if (status >= 500) {
                appDispatch(showDialogMessage(strings.error5xx))
            }
        } else {
            appDispatch(showDialogMessage(strings.errorUnexpected))
        }

        appDispatch(clearApiError())
    }, [apiError])

    return null
}

export default React.memo(ApiErrorHandler)
