import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useStateDispatchContext } from '../../state/stateContext'
import { changeMenuItem } from '../../state/menuItemActions'
import { MenuItemType } from '../../model/MenuItemType'
import { setTitle } from '../../state/titleActions'
import { API } from '../../api/api'
import { handleApiError } from '../../state/apiErrorActions'
import { Button, CircularProgress, Paper, Stack, TextField, styled } from '@mui/material'
import { useStableNavigate } from '../../routes/StableNavigateContext'
import { routesDetails } from '../../routes/routesDetails'
import _, { toInteger } from 'lodash'
import Validator from 'validator'
import { BranchRequest } from '../../api/request'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { Branch } from '../../api/response'
import { showSnackbarMessage } from '../../state/messageActions'
import { AdvancedMarker, Map, MapMouseEvent, Pin } from '@vis.gl/react-google-maps'
import BusinessIcon from '@mui/icons-material/BusinessOutlined'
import { Circle } from '../../components/Circle'

const strings = {
    button: {
        refresh: 'Odśwież',
        save: 'Zapisz',
    },
    error: {
        nameLength: 'Wymagana wartość w przedziale <1, 128> znaków',
        nameRequired: 'Nazwa jest wymagana',
        rangeRequired: 'Zasięg jest wymagany',
        rangeInvalid: 'Podano błędny zasięg',
        locationInvalid: 'Podano błędne współrzędne geograficzne',
        outsideBranchMaxTimeRequired: 'Wartość jest wymagana',
        returnMaxTimeRequired: 'Wartość jest wymagana',
        invalidNumber: 'Podano błędną wartość',
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
    label: {
        name: 'Nazwa',
        range: 'Zasięg oddziału',
        latitude: 'Szerokość geograficzna',
        longitude: 'Długość geograficzna',
        outsideBranchMaxTime: 'Maksymalny czas poza oddziałem bez zlecenia (minuty)',
        returnMaxTime: 'Maksymalny czas powrotu do oddziału po ostatnim zleceniu (minuty)',
    },
    message: {
        added: 'Nowy oddział został dodany',
        edited: 'Oddział został zaktualizowany',
    },
}

const colors = {
    orange: { background: '#e16632', border: '#c04d1c' },
    white: '#ffffff',
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
}))

enum FormFieldNames {
    Name = 'name',
    Range = 'range',
    Latitude = 'latitude',
    Longitude = 'longitude',
    OutsideBranchMaxTime = 'outsideBranchMaxTime',
    ReturnMaxTime = 'returnMaxTime',
}

interface FormErrors {
    name?: string
    range?: string
    latitude?: string
    longitude?: string
    outsideBranchMaxTime?: string
    returnMaxTime?: string
}

const BranchDetailsPage: React.FunctionComponent = () => {
    const { appDispatch } = useStateDispatchContext()
    const navigate = useStableNavigate()
    const { id } = useParams()

    const [loading, setLoading] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)
    const [errors, setErrors] = useState<FormErrors>({})
    const [name, setName] = useState<string>('')
    const [range, setRange] = useState<string>('')
    const [latitude, setLatitude] = useState<string>('')
    const [longitude, setLongitude] = useState<string>('')
    const [outsideBranchMaxTime, setOutsideBranchMaxTime] = useState<string>('')
    const [returnMaxTime, setReturnMaxTime] = useState<string>('')

    const branchId = useMemo(() => {
        return parseInt(id ?? '0', 10)
    }, [id])

    const location: google.maps.LatLngLiteral | null = useMemo(() => {
        const latitudeNumber = parseFloat(latitude)
        const longitudeNumber = parseFloat(longitude)

        if (!Validator.isFloat(latitude) || !Validator.isFloat(longitude) || !Validator.isLatLong(`${latitude},${longitude}`)) {
            return null
        }

        return { lat: latitudeNumber, lng: longitudeNumber }
    }, [latitude, longitude])

    const loadBranch = useCallback(() => {
        if (branchId === 0) {
            setLoaded(true)

            return
        }

        if (loading) {
            return
        }

        setLoading(true)
        setRefresh(false)
        API.branches
            .details(branchId)
            .then((branch) => {
                setName(branch.name)
                setRange(branch.range.toString())
                setLatitude(branch.location.latitude.toString())
                setLongitude(branch.location.longitude.toString())
                setOutsideBranchMaxTime(branch.outsideBranchMaxTime.toString())
                setReturnMaxTime(branch.returnMaxTime.toString())
                setLoaded(true)
            })
            .catch((error) => {
                setRefresh(true)
                appDispatch(handleApiError(error))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [branchId, loading])

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        switch (event.currentTarget.name) {
            case FormFieldNames.Name: {
                setName(event.target.value)
                break
            }
            case FormFieldNames.Range: {
                setRange(event.target.value)
                break
            }
            case FormFieldNames.Latitude: {
                setLatitude(event.target.value)
                break
            }
            case FormFieldNames.Longitude: {
                setLongitude(event.target.value)
                break
            }
            case FormFieldNames.OutsideBranchMaxTime: {
                setOutsideBranchMaxTime(event.target.value)
                break
            }
            case FormFieldNames.ReturnMaxTime: {
                setReturnMaxTime(event.target.value)
                break
            }
        }
    }, [])

    const onMapClick = useCallback((event: MapMouseEvent): void => {
        const latLng = event.detail.latLng
        if (latLng === null) {
            return
        }

        setLatitude(latLng.lat.toString())
        setLongitude(latLng.lng.toString())
    }, [])

    function handleSaveClick(): void {
        const errors: FormErrors = {}

        if (Validator.isEmpty(name)) {
            errors.name = strings.error.nameRequired
        } else if (!Validator.isLength(name, { min: 1, max: 128 })) {
            errors.name = strings.error.nameLength
        }

        if (Validator.isEmpty(range)) {
            errors.range = strings.error.rangeRequired
            // eslint-disable-next-line camelcase
        } else if (!Validator.isNumeric(range, { no_symbols: true })) {
            errors.range = strings.error.rangeInvalid
        } else {
            const intValue = toInteger(range)
            if (intValue <= 0) {
                errors.range = strings.error.rangeInvalid
            }
        }

        const latitudeNumber = parseFloat(latitude)
        const longitudeNumber = parseFloat(longitude)

        if (!Validator.isFloat(latitude) || !Validator.isFloat(longitude) || !Validator.isLatLong(`${latitude},${longitude}`)) {
            errors.latitude = strings.error.locationInvalid
            errors.longitude = strings.error.locationInvalid
        }

        if (Validator.isEmpty(outsideBranchMaxTime)) {
            errors.outsideBranchMaxTime = strings.error.outsideBranchMaxTimeRequired
            // eslint-disable-next-line camelcase
        } else if (!Validator.isNumeric(outsideBranchMaxTime, { no_symbols: true })) {
            errors.outsideBranchMaxTime = strings.error.invalidNumber
        } else {
            const intValue = toInteger(outsideBranchMaxTime)
            if (intValue <= 0) {
                errors.outsideBranchMaxTime = strings.error.invalidNumber
            }
        }

        if (Validator.isEmpty(returnMaxTime)) {
            errors.returnMaxTime = strings.error.returnMaxTimeRequired
            // eslint-disable-next-line camelcase
        } else if (!Validator.isNumeric(returnMaxTime, { no_symbols: true })) {
            errors.returnMaxTime = strings.error.invalidNumber
        } else {
            const intValue = toInteger(returnMaxTime)
            if (intValue <= 0) {
                errors.returnMaxTime = strings.error.invalidNumber
            }
        }

        setErrors(errors)

        if (!_.isEmpty(errors)) {
            return
        }

        const request = new BranchRequest(
            name,
            toInteger(range),
            latitudeNumber,
            longitudeNumber,
            toInteger(outsideBranchMaxTime),
            toInteger(returnMaxTime)
        )

        appDispatch(setInProgress())

        const promise: Promise<Branch> = branchId !== 0 ? API.branches.edit(branchId, request) : API.branches.add(request)

        promise
            .then((response) => {
                appDispatch(setSuccess())

                if (branchId === 0) {
                    appDispatch(showSnackbarMessage(strings.message.added))
                    navigate(routesDetails.authenticated.branch.to(response.id), { replace: true })
                } else {
                    appDispatch(showSnackbarMessage(strings.message.edited))
                }
            })
            .catch((error) => {
                appDispatch(setFailure())
                appDispatch(handleApiError(error))

                if (error === null) {
                    appDispatch(showSnackbarMessage(strings.error.unknown))
                }
            })
    }

    useEffect(() => {
        appDispatch(changeMenuItem(MenuItemType.Branches))
        appDispatch(setTitle(MenuItemType.Branches))
    }, [])

    useEffect(() => {
        const number = parseInt(id ?? '0', 10)
        if (isNaN(number)) {
            navigate(routesDetails.authenticated.branch.to(0), { replace: true })
        } else {
            loadBranch()
        }
    }, [])

    return (
        <StyledPaper elevation={2}>
            {loading && (
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Stack>
            )}

            {refresh && !loading && (
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <Button
                        onClick={loadBranch}
                        variant="contained"
                    >
                        {strings.button.refresh}
                    </Button>
                </Stack>
            )}

            {loaded && (
                <Stack
                    direction="column"
                    spacing={2}
                    ml={3}
                    mr={3}
                >
                    <TextField
                        id={FormFieldNames.Name}
                        name={FormFieldNames.Name}
                        value={name}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.name}
                        margin="none"
                        onChange={onTextFieldChange}
                        helperText={errors.name}
                        error={errors.name !== undefined}
                    />

                    <TextField
                        id={FormFieldNames.Range}
                        name={FormFieldNames.Range}
                        value={range}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.range}
                        margin="none"
                        type="number"
                        onChange={onTextFieldChange}
                        helperText={errors.range}
                        error={errors.range !== undefined}
                    />

                    <TextField
                        id={FormFieldNames.OutsideBranchMaxTime}
                        name={FormFieldNames.OutsideBranchMaxTime}
                        value={outsideBranchMaxTime}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.outsideBranchMaxTime}
                        margin="none"
                        type="number"
                        onChange={onTextFieldChange}
                        helperText={errors.outsideBranchMaxTime}
                        error={errors.outsideBranchMaxTime !== undefined}
                    />

                    <TextField
                        id={FormFieldNames.ReturnMaxTime}
                        name={FormFieldNames.ReturnMaxTime}
                        value={returnMaxTime}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.returnMaxTime}
                        margin="none"
                        type="number"
                        onChange={onTextFieldChange}
                        helperText={errors.returnMaxTime}
                        error={errors.returnMaxTime !== undefined}
                    />

                    <TextField
                        id={FormFieldNames.Latitude}
                        name={FormFieldNames.Latitude}
                        value={latitude}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.latitude}
                        margin="none"
                        type="number"
                        onChange={onTextFieldChange}
                        helperText={errors.latitude}
                        error={errors.latitude !== undefined}
                    />

                    <TextField
                        id={FormFieldNames.Longitude}
                        name={FormFieldNames.Longitude}
                        value={longitude}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.longitude}
                        margin="none"
                        type="number"
                        onChange={onTextFieldChange}
                        helperText={errors.longitude}
                        error={errors.longitude !== undefined}
                    />

                    <Map
                        defaultZoom={location !== null ? 18 : 10}
                        defaultCenter={location ?? { lat: 52.175165, lng: 20.99861 }}
                        gestureHandling={'greedy'}
                        style={{ width: '100%', height: 400 }}
                        mapId="7a0c8b575cfb7279"
                        onClick={onMapClick}
                    >
                        {!!location && (
                            <AdvancedMarker
                                position={location}
                                title={name}
                            >
                                <Pin
                                    background={colors.orange.background}
                                    borderColor={colors.orange.border}
                                    glyphColor={colors.white}
                                >
                                    <BusinessIcon style={{ width: 16 }} />
                                </Pin>
                            </AdvancedMarker>
                        )}

                        {!!location && !!range && (
                            <Circle
                                radius={parseInt(range)}
                                center={location}
                                strokeColor={'#0c4cb3'}
                                strokeOpacity={1}
                                strokeWeight={3}
                                clickable={false}
                                fillColor={'#3b82f6'}
                                fillOpacity={0.3}
                            />
                        )}
                    </Map>

                    <Stack
                        direction="row"
                        justifyContent="left"
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSaveClick}
                        >
                            {strings.button.save}
                        </Button>
                    </Stack>
                </Stack>
            )}
        </StyledPaper>
    )
}

export default React.memo(BranchDetailsPage)
