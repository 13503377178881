import React, { useCallback, useEffect, useState } from 'react'
import { useStateContext } from '../../state/stateContext'
import {
    Box,
    CircularProgress,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    TextField,
    Tooltip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Branch } from '../../api/response'
import { API } from '../../api/api'
import { toInteger } from 'lodash'
import Validator from 'validator'

const strings = {
    button: {
        create: 'Utwórz',
    },
    label: {
        all: 'Wszystkie',
        checkShifts: 'Sprawdź czas pracy',
        noShiftDays: 'Liczba dni bez pracy',
        showFiredLocked: 'Pokaż wszystkich',
        showRemoved: 'Pokaż usuniętych',
        branch: 'Oddział',
    },
}

enum FieldNames {
    CheckShifts = 'checkShifts',
    ShowFiredLocked = 'showFiredLocked',
    ShowRemoved = 'showRemoved',
}

interface OwnProps {
    readonly addUser: () => void
    readonly updateBranchId: (branchId: number | null) => void
    readonly updateCheckShifts: (checkShifts: boolean) => void
    readonly updateNoShiftDays: (noShiftDays: number) => void
    readonly updateShowFiredLocked: (showFiredLocked: boolean) => void
    readonly updateShowRemoved: (showRemoved: boolean) => void
    readonly branchId: number | null
    readonly checkShifts: boolean
    readonly noShiftDays: number
    readonly showFiredLocked: boolean
    readonly showRemoved: boolean
}

const UsersHeader: React.FunctionComponent<OwnProps> = ({
    addUser,
    updateBranchId,
    updateCheckShifts,
    updateNoShiftDays,
    updateShowFiredLocked,
    updateShowRemoved,
    branchId,
    checkShifts,
    noShiftDays,
    showFiredLocked,
    showRemoved,
}) => {
    const { sessionState } = useStateContext()

    const [branches, setBranches] = useState<Branch[]>(() => [sessionState.account!.branch])
    const [loading, setLoading] = useState<boolean>(false)

    const onBranchChange = useCallback((event: SelectChangeEvent) => {
        const number = parseInt(event.target.value)

        if (number === -1) {
            updateBranchId(null)
        } else {
            updateBranchId(number)
        }
    }, [])

    const onSwitchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        switch (event.currentTarget.name) {
            case FieldNames.CheckShifts: {
                updateCheckShifts(checked)

                break
            }
            case FieldNames.ShowFiredLocked: {
                updateShowFiredLocked(checked)

                if (!checked) {
                    updateShowRemoved(false)
                }

                break
            }
            case FieldNames.ShowRemoved: {
                updateShowRemoved(checked)

                break
            }
        }
    }, [])

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = event.target.value

        // eslint-disable-next-line camelcase
        if (Validator.isNumeric(value, { no_symbols: true })) {
            const intValue = toInteger(value)
            if (intValue > 0 && intValue.toString() === value) {
                updateNoShiftDays(intValue)
            }
        }
    }, [])

    useEffect(() => {
        setLoading(true)
        API.users
            .branches()
            .then((response) => {
                setBranches(response)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return (
        <Stack
            direction="row"
            spacing={1}
            ml={3}
            mr={3}
        >
            {loading && (
                <Box>
                    <CircularProgress />
                </Box>
            )}

            <Box
                display="flex"
                justifyContent="center"
            >
                <Tooltip title={strings.button.create}>
                    <IconButton onClick={addUser}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            <Box sx={{ minWidth: 280 }}>
                <FormControl fullWidth={true}>
                    <InputLabel>{strings.label.branch}</InputLabel>
                    <Select
                        id="branch"
                        name="branch"
                        value={branchId?.toString() ?? '-1'}
                        label={strings.label.branch}
                        onChange={onBranchChange}
                    >
                        <MenuItem
                            key={0}
                            value={-1}
                        >
                            {strings.label.all}
                        </MenuItem>
                        {branches.map(
                            (value: Branch, index: number): React.ReactNode => (
                                <MenuItem
                                    key={index}
                                    value={value.id}
                                >
                                    {value.name}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
            </Box>

            <Box
                display="flex"
                justifyContent="center"
            >
                <FormControlLabel
                    control={
                        <Switch
                            id={FieldNames.CheckShifts}
                            name={FieldNames.CheckShifts}
                            checked={checkShifts}
                            onChange={onSwitchChange}
                        />
                    }
                    label={strings.label.checkShifts}
                />
            </Box>

            {checkShifts && (
                <Box>
                    <TextField
                        value={noShiftDays}
                        multiline={false}
                        fullWidth={false}
                        label={strings.label.noShiftDays}
                        margin="none"
                        type="number"
                        onChange={onTextFieldChange}
                    />
                </Box>
            )}

            <Box
                display="flex"
                justifyContent="center"
            >
                <FormControlLabel
                    control={
                        <Switch
                            id={FieldNames.ShowFiredLocked}
                            name={FieldNames.ShowFiredLocked}
                            checked={showFiredLocked}
                            onChange={onSwitchChange}
                        />
                    }
                    label={strings.label.showFiredLocked}
                />
            </Box>

            {showFiredLocked && (
                <Box
                    display="flex"
                    justifyContent="center"
                >
                    <FormControlLabel
                        control={
                            <Switch
                                id={FieldNames.ShowRemoved}
                                name={FieldNames.ShowRemoved}
                                checked={showRemoved}
                                onChange={onSwitchChange}
                            />
                        }
                        label={strings.label.showRemoved}
                    />
                </Box>
            )}
        </Stack>
    )
}

export default React.memo(UsersHeader)
