import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, CircularProgress, Paper, Stack, styled, TextField } from '@mui/material'
import _ from 'lodash'
import Validator from 'validator'
import { API } from '../../api/api'
import { MenuItemType } from '../../model/MenuItemType'
import { changeMenuItem } from '../../state/menuItemActions'
import { setTitle } from '../../state/titleActions'
import { CarRequest } from '../../api/request'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { routesDetails } from '../../routes/routesDetails'
import { showSnackbarMessage } from '../../state/messageActions'
import { Car } from '../../api/response'
import { useStateDispatchContext } from '../../state/stateContext'
import { useStableNavigate } from '../../routes/StableNavigateContext'
import { useParams } from 'react-router'
import { handleApiError } from '../../state/apiErrorActions'

const strings = {
    button: {
        refresh: 'Odśwież',
        save: 'Zapisz',
    },
    error: {
        nameLength: 'Wymagana wartość w przedziale <1, 64> znaki',
        nameRequired: 'Nazwa jest wymagana',
        licensePlateLength: 'Wymagana wartość w przedziale <1, 16> znaki',
        licensePlateRequired: 'Numer rejestracyjny jest wymagany',
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
    label: {
        name: 'Nazwa',
        licensePlate: 'Numer rejestracyjny',
    },
    message: {
        added: 'Nowy samochód został dodany',
        edited: 'Nowy samochód został zaktualizowany',
    },
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
}))

enum FormFieldNames {
    Name = 'name',
    LicensePlate = 'licensePlate',
}

interface FormErrors {
    name?: string
    licensePlate?: string
}

const CarDetailsPage: React.FunctionComponent = () => {
    const { appDispatch } = useStateDispatchContext()
    const navigate = useStableNavigate()
    const { id } = useParams()

    const [loading, setLoading] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)
    const [errors, setErrors] = useState<FormErrors>({})
    const [name, setName] = useState<string>('')
    const [licensePlate, setLicensePlate] = useState<string>('')

    const carId = useMemo(() => {
        return parseInt(id ?? '0', 10)
    }, [id])

    const loadCar = useCallback(() => {
        if (carId === 0) {
            setLoaded(true)

            return
        }

        if (loading) {
            return
        }

        setLoading(true)
        setRefresh(false)
        API.cars
            .details(carId)
            .then((car) => {
                setName(car.name)
                setLicensePlate(car.licensePlate)
                setLoaded(true)
            })
            .catch((error) => {
                setRefresh(true)
                appDispatch(handleApiError(error))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [carId, loading])

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        switch (event.currentTarget.name) {
            case FormFieldNames.Name: {
                setName(event.target.value)
                break
            }
            case FormFieldNames.LicensePlate: {
                setLicensePlate(event.target.value)
                break
            }
        }
    }, [])

    function handleSaveClick(): void {
        const errors: FormErrors = {}

        if (Validator.isEmpty(name)) {
            errors.name = strings.error.nameRequired
        } else if (!Validator.isLength(name, { min: 1, max: 64 })) {
            errors.name = strings.error.nameLength
        }

        if (Validator.isEmpty(licensePlate)) {
            errors.licensePlate = strings.error.licensePlateRequired
        } else if (!Validator.isLength(licensePlate, { min: 1, max: 16 })) {
            errors.licensePlate = strings.error.licensePlateLength
        }

        setErrors(errors)

        if (!_.isEmpty(errors)) {
            return
        }

        const request = new CarRequest(name, licensePlate)

        appDispatch(setInProgress())

        const promise: Promise<Car> = carId !== 0 ? API.cars.edit(carId, request) : API.cars.add(request)

        promise
            .then((response) => {
                appDispatch(setSuccess())

                if (carId === 0) {
                    appDispatch(showSnackbarMessage(strings.message.added))
                    navigate(routesDetails.authenticated.car.to(response.id), { replace: true })
                } else {
                    appDispatch(showSnackbarMessage(strings.message.edited))
                }
            })
            .catch((error) => {
                appDispatch(setFailure())
                appDispatch(handleApiError(error))

                if (error === null) {
                    appDispatch(showSnackbarMessage(strings.error.unknown))
                }
            })
    }

    useEffect(() => {
        appDispatch(changeMenuItem(MenuItemType.Cars))
        appDispatch(setTitle(MenuItemType.Cars))
    }, [])

    useEffect(() => {
        const number = parseInt(id ?? '0', 10)
        if (isNaN(number)) {
            navigate(routesDetails.authenticated.car.to(0), { replace: true })
        } else {
            loadCar()
        }
    }, [])

    return (
        <StyledPaper elevation={2}>
            {loading && (
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Stack>
            )}

            {refresh && !loading && (
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <Button
                        onClick={loadCar}
                        variant="contained"
                    >
                        {strings.button.refresh}
                    </Button>
                </Stack>
            )}

            {loaded && (
                <Stack
                    direction="column"
                    spacing={2}
                    ml={3}
                    mr={3}
                >
                    <TextField
                        id={FormFieldNames.Name}
                        name={FormFieldNames.Name}
                        value={name}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.name}
                        margin="none"
                        onChange={onTextFieldChange}
                        helperText={errors.name}
                        error={errors.name !== undefined}
                        inputProps={{ maxLength: 64 }}
                    />

                    <TextField
                        id={FormFieldNames.LicensePlate}
                        name={FormFieldNames.LicensePlate}
                        value={licensePlate}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.licensePlate}
                        margin="none"
                        onChange={onTextFieldChange}
                        helperText={errors.licensePlate}
                        error={errors.licensePlate !== undefined}
                        inputProps={{ maxLength: 16 }}
                    />

                    <Stack
                        direction="row"
                        justifyContent="left"
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSaveClick}
                        >
                            {strings.button.save}
                        </Button>
                    </Stack>
                </Stack>
            )}
        </StyledPaper>
    )
}

export default React.memo(CarDetailsPage)
