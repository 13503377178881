import { Reducer } from 'react'
import { Account } from '../api/response'
import { Action } from './stateContext'

enum AccountActionType {
    SetAccount = 'SetAccount',
    ClearAccount = 'ClearAccount',
}

interface SetAccountAction extends Action {
    type: AccountActionType.SetAccount
    account: Account
}

interface ClearAccountAction extends Action {
    type: AccountActionType.ClearAccount
}

type AccountAction = SetAccountAction | ClearAccountAction

export const setAccount = (account: Account): SetAccountAction => ({
    type: AccountActionType.SetAccount,
    account,
})
export const clearAccount = (): ClearAccountAction => ({ type: AccountActionType.ClearAccount })

type AccountState = Account | null

export const accountReducer: Reducer<AccountState, AccountAction> = (state: AccountState, action: AccountAction): AccountState => {
    switch (action.type) {
        case AccountActionType.SetAccount: {
            return action.account
        }
        case AccountActionType.ClearAccount: {
            return null
        }
        default: {
            return state
        }
    }
}
