import React, { useCallback } from 'react'
import { TableCell, TableRow, Tooltip, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import TrackLocationIcon from '@mui/icons-material/NavigationOutlined'
import AllowLeaveBranchIcon from '@mui/icons-material/ModeOfTravelOutlined'
import { JobPosition } from '../../api/response'

const strings = {
    label: {
        trackLocation: 'Śledzenie lokalizacji',
        allowLeaveBranch: 'Zezwalaj na opuszczanie oddziału',
    },
    button: {
        delete: 'Usuń',
        edit: 'Edytuj',
    },
}

interface OwnProps {
    readonly jobPosition: JobPosition
    readonly editJobPosition: (jobPosition: JobPosition) => void
    readonly deleteJobPosition: (jobPosition: JobPosition) => void
}

const JobPositionTableRow: React.FunctionComponent<OwnProps> = ({ jobPosition, editJobPosition, deleteJobPosition }) => {
    const handleEdit = useCallback(() => {
        editJobPosition(jobPosition)
    }, [jobPosition])

    const handleDelete = useCallback(() => {
        deleteJobPosition(jobPosition)
    }, [jobPosition])

    return (
        <TableRow hover={true}>
            <TableCell>{jobPosition.id}</TableCell>
            <TableCell>{jobPosition.name}</TableCell>
            <TableCell>
                {jobPosition.trackLocation && (
                    <Tooltip title={strings.label.trackLocation}>
                        <TrackLocationIcon />
                    </Tooltip>
                )}
                {jobPosition.allowBranchLeave && (
                    <Tooltip title={strings.label.allowLeaveBranch}>
                        <AllowLeaveBranchIcon />
                    </Tooltip>
                )}
            </TableCell>
            <TableCell>
                <Tooltip title={strings.button.edit}>
                    <IconButton onClick={handleEdit}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={strings.button.delete}>
                    <IconButton onClick={handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}

export default React.memo(JobPositionTableRow)
