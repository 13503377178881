import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Paper,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { API } from '../../api/api'
import { Car } from '../../api/response'
import { MenuItemType } from '../../model/MenuItemType'
import { changeMenuItem } from '../../state/menuItemActions'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { setTitle } from '../../state/titleActions'
import AddIcon from '@mui/icons-material/Add'
import ImportIcon from '@mui/icons-material/FileUpload'
import { BottomScrollListener } from 'react-bottom-scroll-listener'
import CarTableRow from './CarTableRow'
import { routesDetails } from '../../routes/routesDetails'
import { useStateDispatchContext } from '../../state/stateContext'
import { useStableNavigate } from '../../routes/StableNavigateContext'
import { handleApiError } from '../../state/apiErrorActions'
import Dropzone, { DropzoneState } from 'react-dropzone'
import { showSnackbarMessage } from '../../state/messageActions'

const strings = {
    button: {
        refresh: 'Odśwież',
        create: 'Utwórz',
        import: 'Importuj',
    },
    column: {
        id: 'Id',
        name: 'Nazwa',
        licensePlate: 'Rejestracja',
        actions: 'Akcje',
    },
    error: {
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
    message: {
        imported: 'Dane zostały zaimportowane',
    },
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
}))

const CarsPage: React.FunctionComponent = () => {
    const { appDispatch } = useStateDispatchContext()
    const navigate = useStableNavigate()

    const [cars, setCars] = useState<Car[] | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadMore, setLoadMore] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)

    const loadCars = useCallback(() => {
        if (loading) {
            return
        }

        const limit = 100

        setLoading(true)
        setRefresh(false)
        API.cars
            .list(limit, cars?.length)
            .then((newCars) => {
                if (cars === null) {
                    setCars(newCars)
                } else {
                    setCars(cars.concat(newCars))
                }
                setLoadMore(newCars.length === limit)
            })
            .catch((error) => {
                setRefresh(true)
                appDispatch(handleApiError(error))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [cars, loading])

    const addCar = useCallback((): void => {
        navigate(routesDetails.authenticated.car.to(0))
    }, [])

    const editCar = useCallback((car: Car): void => {
        navigate(routesDetails.authenticated.car.to(car.id))
    }, [])

    const deleteCar = useCallback((car: Car): void => {
        appDispatch(setInProgress())
        API.cars
            .delete(car.id)
            .then(() => {
                setCars((value) => {
                    if (value === null) {
                        return value
                    }
                    return value.filter((value) => value.id !== car.id)
                })
                appDispatch(setSuccess())
            })
            .catch((error) => {
                appDispatch(handleApiError(error))
                appDispatch(setFailure())
            })
    }, [])

    const onExcelFile = useCallback((files: File[]): void => {
        const limit = 100

        if (files.length === 1) {
            appDispatch(setInProgress())
            API.cars
                .import(files[0])
                .then((cars) => {
                    setCars(cars)
                    setLoadMore(cars.length === limit)
                    appDispatch(setSuccess())
                    appDispatch(showSnackbarMessage(strings.message.imported))
                })
                .catch((error) => {
                    appDispatch(handleApiError(error))
                    appDispatch(setFailure())
                })
        }
    }, [])

    useEffect(() => {
        appDispatch(changeMenuItem(MenuItemType.Cars))
        appDispatch(setTitle(MenuItemType.Cars))
    }, [])

    useEffect(() => {
        loadCars()
    }, [])

    return (
        <BottomScrollListener
            // eslint-disable-next-line no-empty-function
            onBottom={loadMore ? loadCars : (): void => {}}
            offset={300}
            debounce={100}
            debounceOptions={{ leading: false, trailing: true }}
            triggerOnNoScroll={false}
        >
            <StyledPaper elevation={2}>
                <Toolbar>
                    <Tooltip title={strings.button.create}>
                        <IconButton onClick={addCar}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>

                    <Dropzone
                        multiple={false}
                        onDropAccepted={onExcelFile}
                        accept={{ xlsx: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] }}
                    >
                        {(state: DropzoneState): JSX.Element => (
                            <Box {...state.getRootProps()}>
                                <Tooltip title={strings.button.import}>
                                    <IconButton>
                                        <ImportIcon />
                                    </IconButton>
                                </Tooltip>
                                <input {...state.getInputProps()} />
                            </Box>
                        )}
                    </Dropzone>
                </Toolbar>

                {!!cars && (
                    <Table size="small">
                        <colgroup>
                            <col width="20%" />
                            <col width="30%" />
                            <col width="30%" />
                            <col width="20%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>{strings.column.id}</TableCell>
                                <TableCell>{strings.column.name}</TableCell>
                                <TableCell>{strings.column.licensePlate}</TableCell>
                                <TableCell>{strings.column.actions}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cars.map((car, index) => (
                                <CarTableRow
                                    key={index}
                                    car={car}
                                    editCar={editCar}
                                    deleteCar={deleteCar}
                                />
                            ))}
                        </TableBody>
                    </Table>
                )}

                {loading && (
                    <Stack
                        direction="row"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Stack>
                )}

                {refresh && !loading && (
                    <Stack
                        direction="row"
                        justifyContent="center"
                    >
                        <Button
                            onClick={loadCars}
                            variant="contained"
                        >
                            {strings.button.refresh}
                        </Button>
                    </Stack>
                )}
            </StyledPaper>
        </BottomScrollListener>
    )
}

export default React.memo(CarsPage)
