import {
    Button,
    CircularProgress,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
    styled,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { API } from '../../api/api'
import { JobPosition } from '../../api/response'
import { MenuItemType } from '../../model/MenuItemType'
import { changeMenuItem } from '../../state/menuItemActions'
import { setTitle } from '../../state/titleActions'
import AddIcon from '@mui/icons-material/Add'
import { BottomScrollListener } from 'react-bottom-scroll-listener'
import { useStateDispatchContext } from '../../state/stateContext'
import { handleApiError } from '../../state/apiErrorActions'
import JobPositionTableRow from './JobPositionTableRow'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { useStableNavigate } from '../../routes/StableNavigateContext'
import { routesDetails } from '../../routes/routesDetails'

const strings = {
    button: {
        refresh: 'Odśwież',
        create: 'Utwórz',
    },
    column: {
        id: 'Id',
        name: 'Nazwa',
        details: 'Szczegóły',
        actions: 'Akcje',
    },
    error: {
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
}))

const JobPositionsPage: React.FunctionComponent = () => {
    const { appDispatch } = useStateDispatchContext()
    const navigate = useStableNavigate()

    const [jobPositions, setJobPositions] = useState<JobPosition[] | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadMore, setLoadMore] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)

    const loadJobPositions = useCallback(() => {
        if (loading) {
            return
        }

        const limit = 100

        setLoading(true)
        setRefresh(false)
        API.jobPositions
            .list(limit, jobPositions?.length)
            .then((newJobPositions) => {
                if (jobPositions === null) {
                    setJobPositions(newJobPositions)
                } else {
                    setJobPositions(jobPositions.concat(newJobPositions))
                }
                setLoadMore(newJobPositions.length === limit)
            })
            .catch((error) => {
                setRefresh(true)
                appDispatch(handleApiError(error))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [jobPositions, loading])

    const addJobPosition = useCallback((): void => {
        navigate(routesDetails.authenticated.jobPosition.to(0))
    }, [])

    const editJobPosition = useCallback((jobPosition: JobPosition): void => {
        navigate(routesDetails.authenticated.jobPosition.to(jobPosition.id))
    }, [])

    const deleteJobPosition = useCallback((jobPosition: JobPosition): void => {
        appDispatch(setInProgress())
        API.jobPositions
            .delete(jobPosition.id)
            .then(() => {
                setJobPositions((value) => {
                    if (value === null) {
                        return value
                    }
                    return value.filter((value) => value.id !== jobPosition.id)
                })
                appDispatch(setSuccess())
            })
            .catch((error) => {
                appDispatch(handleApiError(error))
                appDispatch(setFailure())
            })
    }, [])

    useEffect(() => {
        appDispatch(changeMenuItem(MenuItemType.JobPositions))
        appDispatch(setTitle(MenuItemType.JobPositions))
    }, [])

    useEffect(() => {
        loadJobPositions()
    }, [])

    return (
        <BottomScrollListener
            // eslint-disable-next-line no-empty-function
            onBottom={loadMore ? loadJobPositions : (): void => {}}
            offset={300}
            debounce={100}
            debounceOptions={{ leading: false, trailing: true }}
            triggerOnNoScroll={false}
        >
            <StyledPaper elevation={2}>
                <Toolbar>
                    <Tooltip title={strings.button.create}>
                        <IconButton onClick={addJobPosition}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>

                {!!jobPositions && (
                    <Table size="small">
                        <colgroup>
                            <col width="20%" />
                            <col width="40%" />
                            <col width="20%" />
                            <col width="20%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>{strings.column.id}</TableCell>
                                <TableCell>{strings.column.name}</TableCell>
                                <TableCell>{strings.column.details}</TableCell>
                                <TableCell>{strings.column.actions}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {jobPositions.map((jobPosition, index) => (
                                <JobPositionTableRow
                                    key={index}
                                    jobPosition={jobPosition}
                                    editJobPosition={editJobPosition}
                                    deleteJobPosition={deleteJobPosition}
                                />
                            ))}
                        </TableBody>
                    </Table>
                )}

                {loading && (
                    <Stack
                        direction="row"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Stack>
                )}

                {refresh && !loading && (
                    <Stack
                        direction="row"
                        justifyContent="center"
                    >
                        <Button
                            onClick={loadJobPositions}
                            variant="contained"
                        >
                            {strings.button.refresh}
                        </Button>
                    </Stack>
                )}
            </StyledPaper>
        </BottomScrollListener>
    )
}

export default React.memo(JobPositionsPage)
