import React, { useEffect, useState } from 'react'
import { UserDayShift } from '../../api/response'
import dayjs, { Dayjs } from 'dayjs'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material'
import { TimePicker, TimeValidationError } from '@mui/x-date-pickers'
import { useCallback } from 'react'
import { useStateDispatchContext } from '../../state/stateContext'
import { API } from '../../api/api'
import { handleApiError } from '../../state/apiErrorActions'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals'
import { UserShiftRequest } from '../../api/request'

const strings = {
    label: {
        title: 'Czas pracy',
        content: (date: string, fullName: string): string => `Ustaw dane czasu pracy dla ${fullName} w ${date}`,
        start: 'Od:',
        end: 'Do:',
        nextDay: 'Okres na przełomie dni',
    },
    button: {
        cancel: 'Anuluj',
        save: 'Zapisz',
    },
}

export interface UserShiftDialogData {
    scheduleId: string
    date: dayjs.Dayjs
    user: { id: number; fullName: string }
    shift: UserDayShift | null
    onSuccess: (shift: UserDayShift) => void
}

interface OwnProps {
    readonly open: boolean
    readonly data: UserShiftDialogData
    readonly closeDialog: () => void
}

const UserShiftDialog: React.FunctionComponent<OwnProps> = ({ open, data, closeDialog }) => {
    const { date, user, onSuccess } = data

    const [startTime, setStartTime] = useState<Dayjs | null>(null)
    const [endTime, setEndTime] = useState<Dayjs | null>(null)

    const { appDispatch } = useStateDispatchContext()

    useEffect(() => {
        const shift = data.shift

        if (open && shift !== null) {
            if (shift.edited !== null) {
                setStartTime(dayjs(shift.edited.start))
                setEndTime(dayjs(shift.edited.end))
            } else {
                setStartTime(dayjs(shift.start))
                setEndTime(dayjs(shift.end))
            }
        } else {
            setStartTime(null)
            setEndTime(null)
        }
    }, [open, data])

    const onStartTimeChange = useCallback((value: Dayjs | null, context: FieldChangeHandlerContext<TimeValidationError>) => {
        if (value && (!context.validationError || context.validationError === 'minutesStep')) {
            setStartTime(value)
        }
    }, [])

    const onEndTimeChange = useCallback(
        (value: Dayjs | null, context: FieldChangeHandlerContext<TimeValidationError>) => {
            if (value && (!context.validationError || context.validationError === 'minutesStep')) {
                let date = value

                if (!date.isSame(startTime, 'day')) {
                    date = dayjs(startTime).set('hour', value.hour()).set('minute', value.minute()).set('second', 0)
                }

                if (!date.isAfter(startTime, 'minute')) {
                    date = date.add(1, 'day')
                }

                setEndTime(date)
            }
        },
        [startTime]
    )

    function handleSaveClick(): void {
        if (startTime === null || endTime === null) {
            return
        }

        const request = new UserShiftRequest(data.date.date(), startTime.format('HH:mm'), endTime.format('HH:mm'))

        const shift = data.shift
        const promise: Promise<UserDayShift> =
            shift !== null ? API.shift.edit(shift.uuid, request) : API.shift.add(data.scheduleId, data.user.id, request)

        appDispatch(setInProgress())

        promise
            .then((shift) => {
                onSuccess(shift)
                appDispatch(setSuccess())
                closeDialog()
            })
            .catch((error) => {
                appDispatch(setFailure())
                appDispatch(handleApiError(error))
            })
    }

    return (
        <Dialog
            open={open}
            disableEscapeKeyDown={true}
        >
            <DialogTitle>{strings.label.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{strings.label.content(date.format('YYYY-MM-DD'), user.fullName)}</DialogContentText>
                <Stack
                    direction="column"
                    spacing={2}
                >
                    <Stack
                        mt={2}
                        direction="row"
                        alignItems="stretch"
                        spacing={2}
                    >
                        <TimePicker
                            ampm={false}
                            ampmInClock={false}
                            label={strings.label.start}
                            format="HH:mm"
                            minutesStep={1}
                            timeSteps={{ hours: 1, minutes: 1 }}
                            sx={{ width: '100%' }}
                            onChange={onStartTimeChange}
                            value={startTime}
                        />

                        <TimePicker
                            ampm={false}
                            sx={{ width: '100%' }}
                            ampmInClock={false}
                            label={strings.label.end}
                            format="HH:mm"
                            minutesStep={1}
                            timeSteps={{ hours: 1, minutes: 1 }}
                            onChange={onEndTimeChange}
                            slotProps={{
                                textField: {
                                    helperText:
                                        startTime !== null &&
                                        endTime !== null &&
                                        (!endTime.isAfter(startTime, 'minutes') || !endTime.isSame(startTime, 'date'))
                                            ? strings.label.nextDay
                                            : null,
                                },
                            }}
                            value={endTime}
                        />
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>{strings.button.cancel}</Button>
                <Button
                    onClick={handleSaveClick}
                    disabled={startTime === null || endTime === null || (startTime >= endTime && !(endTime.hour() === 0 && endTime.minute() === 0))}
                >
                    {strings.button.save}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(UserShiftDialog)
