import React from 'react'
import { AppBar, Button, Toolbar, Typography, styled } from '@mui/material'
import { drawerWidth, drawerWidthCollapsed } from './AppDrawer'
import { logout, useStateContext, useStateDispatchContext } from '../state/stateContext'

const strings = {
    button: {
        logout: 'Wyloguj',
    },
    text: {
        appTitle: 'cfrental',
    },
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
    flexGrow: 1,
    paddingLeft: drawerWidth,
    [theme.breakpoints.down('md')]: {
        paddingLeft: drawerWidthCollapsed,
    },
}))

const GlobalAppBar: React.FunctionComponent = () => {
    const { appState } = useStateContext()
    const { sessionDispatch } = useStateDispatchContext()
    const { title } = appState

    const onLogoutClick = React.useCallback((): void => {
        logout(sessionDispatch)
    }, [])

    return (
        <StyledAppBar position="fixed">
            <Toolbar>
                <StyledTitle
                    variant="h6"
                    color="inherit"
                    noWrap={true}
                >
                    {title !== null ? title : strings.text.appTitle}
                </StyledTitle>
                <Button
                    color="inherit"
                    onClick={onLogoutClick}
                >
                    {strings.button.logout}
                </Button>
            </Toolbar>
        </StyledAppBar>
    )
}

export default React.memo(GlobalAppBar)
