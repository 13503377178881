import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Stack,
    styled,
    TextField,
} from '@mui/material'
import _ from 'lodash'
import Validator from 'validator'
import { API } from '../../api/api'
import { MenuItemType } from '../../model/MenuItemType'
import { changeMenuItem } from '../../state/menuItemActions'
import { setTitle } from '../../state/titleActions'
import { HolidayRequest } from '../../api/request'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { routesDetails } from '../../routes/routesDetails'
import { showSnackbarMessage } from '../../state/messageActions'
import dayjs, { Dayjs } from 'dayjs'
import { Holiday, HolidayType, HolidayTypeId, holidayTypes } from '../../api/response'
import { DatePicker, DateValidationError } from '@mui/x-date-pickers'
import { useStateDispatchContext } from '../../state/stateContext'
import { useStableNavigate } from '../../routes/StableNavigateContext'
import { useParams } from 'react-router'
import { handleApiError } from '../../state/apiErrorActions'
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals'

const strings = {
    button: {
        refresh: 'Odśwież',
        save: 'Zapisz',
    },
    error: {
        nameLength: 'Wymagana wartość w przedziale <1, 64> znaki',
        nameRequired: 'Nazwa jest wymagana',
        dateRequired: 'Data jest wymagana',
        dateInvalid: 'Dozwolone są tylko przyszłe daty',
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
    label: {
        name: 'Nazwa',
        date: 'Data',
        type: 'Typ',
    },
    message: {
        added: 'Nowe świąto zostało dodane',
        edited: 'Święto zostało zaktualizowane',
    },
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
}))

enum FormFieldNames {
    Name = 'name',
    Date = 'date',
    Type = 'type',
}

interface FormErrors {
    name?: string
    date?: string
}

const HolidayDetailsPage: React.FunctionComponent = () => {
    const { appDispatch } = useStateDispatchContext()
    const navigate = useStableNavigate()
    const { id } = useParams()

    const [loading, setLoading] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)
    const [errors, setErrors] = useState<FormErrors>({})
    const [name, setName] = useState<string>('')
    const [date, setDate] = useState<Dayjs | null>(dayjs())
    const [type, setType] = useState<HolidayTypeId>(HolidayTypeId.National)

    const holidayId = useMemo(() => {
        return parseInt(id ?? '0', 10)
    }, [id])

    const loadHoliday = useCallback(() => {
        if (holidayId === 0) {
            setLoaded(true)

            return
        }

        if (loading) {
            return
        }

        setLoading(true)
        setRefresh(false)
        API.holidays
            .details(holidayId)
            .then((holiday) => {
                setName(holiday.name)
                setDate(dayjs(holiday.date))
                setType(holiday.type)
                setLoaded(true)
            })
            .catch((error) => {
                setRefresh(true)
                appDispatch(handleApiError(error))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [holidayId, loading])

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        switch (event.currentTarget.name) {
            case FormFieldNames.Name: {
                setName(event.target.value)
                break
            }
        }
    }, [])

    const onTypeChange = useCallback((event: SelectChangeEvent) => {
        setType(event.target.value as HolidayTypeId)
    }, [])

    const onDateChange = useCallback((value: Dayjs | null, context: FieldChangeHandlerContext<DateValidationError>) => {
        if (value && context.validationError === null) {
            setDate(value)
        } else {
            setDate(null)
        }
    }, [])

    function handleSaveClick(): void {
        const errors: FormErrors = {}

        if (Validator.isEmpty(name)) {
            errors.name = strings.error.nameRequired
        } else if (!Validator.isLength(name, { min: 1, max: 64 })) {
            errors.name = strings.error.nameLength
        }

        if (date === null) {
            errors.date = strings.error.dateRequired
        } else if (holidayId === 0 && date.isBefore(dayjs())) {
            errors.date = strings.error.dateInvalid
        }

        setErrors(errors)

        if (!_.isEmpty(errors)) {
            return
        }

        const request = new HolidayRequest(name, date!.toDate(), type)

        appDispatch(setInProgress())

        const promise: Promise<Holiday> = holidayId !== 0 ? API.holidays.edit(holidayId, request) : API.holidays.add(request)

        promise
            .then((response) => {
                appDispatch(setSuccess())

                if (holidayId === 0) {
                    appDispatch(showSnackbarMessage(strings.message.added))
                    navigate(routesDetails.authenticated.holiday.to(response.id), { replace: true })
                } else {
                    appDispatch(showSnackbarMessage(strings.message.edited))
                }
            })
            .catch((error) => {
                appDispatch(setFailure())
                appDispatch(handleApiError(error))

                if (error === null) {
                    appDispatch(showSnackbarMessage(strings.error.unknown))
                }
            })
    }

    useEffect(() => {
        appDispatch(changeMenuItem(MenuItemType.Holidays))
        appDispatch(setTitle(MenuItemType.Holidays))
    }, [])

    useEffect(() => {
        const number = parseInt(id ?? '0', 10)
        if (isNaN(number)) {
            navigate(routesDetails.authenticated.holiday.to(0), { replace: true })
        } else {
            loadHoliday()
        }
    }, [])

    return (
        <StyledPaper elevation={2}>
            {loading && (
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Stack>
            )}

            {refresh && !loading && (
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <Button
                        onClick={loadHoliday}
                        variant="contained"
                    >
                        {strings.button.refresh}
                    </Button>
                </Stack>
            )}

            {loaded && (
                <Stack
                    direction="column"
                    spacing={2}
                    ml={3}
                    mr={3}
                >
                    <TextField
                        id={FormFieldNames.Name}
                        name={FormFieldNames.Name}
                        value={name}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.name}
                        margin="none"
                        onChange={onTextFieldChange}
                        helperText={errors.name}
                        error={errors.name !== undefined}
                        inputProps={{ maxLength: 64 }}
                    />

                    <FormControl fullWidth={true}>
                        <InputLabel>{strings.label.type}</InputLabel>
                        <Select
                            id={FormFieldNames.Type}
                            name={FormFieldNames.Type}
                            value={type}
                            onChange={onTypeChange}
                        >
                            {holidayTypes.map(
                                (value: HolidayType, index: number): React.ReactNode => (
                                    <MenuItem
                                        key={index}
                                        value={value.id}
                                    >
                                        {value.name}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>

                    <DatePicker
                        label={strings.label.date}
                        format="YYYY-MM-DD"
                        value={date}
                        slotProps={{
                            textField: {
                                helperText: errors.date,
                                error: errors.date !== undefined,
                            },
                        }}
                        onChange={onDateChange}
                    />

                    <Stack
                        direction="row"
                        justifyContent="left"
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSaveClick}
                        >
                            {strings.button.save}
                        </Button>
                    </Stack>
                </Stack>
            )}
        </StyledPaper>
    )
}

export default React.memo(HolidayDetailsPage)
