import React from 'react'
import { useStateContext } from '../state/stateContext'
import { routesDetails } from './routesDetails'
import { Navigate } from 'react-router'

interface ChildrenProps {
    children: React.ReactElement
}

const GuestRoute = ({ children }: ChildrenProps): React.ReactElement => {
    const { sessionState } = useStateContext()

    if (sessionState.auth !== null) {
        return <Navigate to={routesDetails.authenticated.homePage.path} />
    }

    return children
}

export default React.memo(GuestRoute)
