import React, { useEffect, useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
} from '@mui/material'
import { useCallback } from 'react'
import { Status, TaskDetails } from '../../api/response'
import dayjs, { Dayjs } from 'dayjs'
import { DatePicker, DateValidationError, TimePicker, TimeValidationError } from '@mui/x-date-pickers'
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals'
import { API } from '../../api/api'
import { TaskRequest } from '../../api/request'
import Validator from 'validator'
import _ from 'lodash'
import { useStateDispatchContext } from '../../state/stateContext'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { handleApiError } from '../../state/apiErrorActions'
import { DateTime } from '../../api/DateTime'

const strings = {
    label: {
        title: (editing: boolean): string => (editing ? 'Edycja zadania' : 'Dodawanie zadania'),
        taskTitle: 'Tytuł',
        taskDescription: 'Opis',
        start: 'Start',
        end: 'Koniec',
        date: 'Data',
        status: 'Status',
    },
    button: {
        cancel: 'Anuluj',
        save: 'Zapisz',
    },
    error: {
        statusRequired: 'Proszę wybrać status',
        dateRequired: 'Proszę ustawić datę zadania',
        startRequired: 'Proszę ustawić czas rozpoczęcia zadania',
        titleRequired: 'Proszę podać tytuł zadania',
        dateInvalid: 'Nie można ustawić daty w przeszłości',
        startInvalid: 'Nie można ustawić czasu w przeszłości',
        endInvalid: 'Podano błędną datę zakończenia zadania',
    },
}

enum FormFieldNames {
    Status = 'status',
    Title = 'title',
    Description = 'description',
}

interface FormErrors {
    status?: string
    date?: string
    start?: string
    end?: string
    title?: string
}

export interface TaskDialogData {
    userId: number
    task: TaskDetails | null
    onSuccess: (task: TaskDetails) => void
}

interface OwnProps {
    readonly open: boolean
    readonly data: TaskDialogData
    readonly closeDialog: () => void
}

const TaskDialog: React.FunctionComponent<OwnProps> = ({ open, data, closeDialog }) => {
    const { userId, task, onSuccess } = data

    const [statuses, setStatuses] = useState<Status[]>([])
    const [errors, setErrors] = useState<FormErrors>({})
    const [statusId, setStatusId] = useState<number | null>(null)
    const [date, setDate] = useState<Dayjs>(dayjs())
    const [startTime, setStartTime] = useState<Dayjs>(dayjs())
    const [endTime, setEndTime] = useState<Dayjs | null>(null)
    const [title, setTitle] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [dateInvalid, setDateInvalid] = useState<boolean>(false)
    const [startInvalid, setStartInvalid] = useState<boolean>(false)
    const [endInvalid, setEndInvalid] = useState<boolean>(false)

    const { appDispatch } = useStateDispatchContext()

    useEffect(() => {
        API.tasks.statuses().then((response) => {
            setStatuses(response)
        })
    }, [])

    useEffect(() => {
        const task = data.task

        if (open && task !== null) {
            setStatusId(task.status.id)
            setDate(dayjs(task.start))
            setStartTime(dayjs(task.start))
            if (task.end !== null) {
                setEndTime(dayjs(task.end))
            } else {
                setEndTime(null)
            }
            setTitle(task.title)
            setDescription(task.description ?? '')
        } else {
            setStatusId(null)
            setDate(dayjs())
            setStartTime(dayjs())
            setEndTime(null)
            setTitle('')
            setDescription('')
        }
    }, [open, data])

    const onStatusChange = useCallback(
        (event: SelectChangeEvent) => {
            const selectedStatusId = parseInt(event.target.value)

            setStatusId(selectedStatusId)

            const status = statuses.find((value: Status): boolean => {
                return value.id === selectedStatusId
            })

            if (status !== undefined) {
                setTitle(status.name)
            }
        },
        [statuses]
    )

    const onDateChange = useCallback((value: Dayjs | null, context: FieldChangeHandlerContext<DateValidationError>) => {
        if (value && context.validationError === null) {
            setDateInvalid(false)
            setDate(value)
        } else {
            setDateInvalid(true)
        }
    }, [])

    const onStartTimeChange = useCallback((value: Dayjs | null, context: FieldChangeHandlerContext<TimeValidationError>) => {
        if (value && context.validationError === null) {
            setStartInvalid(false)
            setStartTime(value)
        } else {
            setStartInvalid(true)
        }
    }, [])

    const onEndTimeChange = useCallback((value: Dayjs | null, context: FieldChangeHandlerContext<TimeValidationError>) => {
        if (context.validationError === null) {
            setEndInvalid(false)
            setEndTime(value)
        } else {
            setEndInvalid(true)
        }
    }, [])

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        switch (event.currentTarget.name) {
            case FormFieldNames.Title: {
                setTitle(event.target.value)
                break
            }
            case FormFieldNames.Description: {
                setDescription(event.target.value)
                break
            }
        }
    }, [])

    function handleSaveClick(): void {
        const errors: FormErrors = {}

        if (statusId === null) {
            errors.status = strings.error.statusRequired
        }

        if (Validator.isEmpty(title)) {
            errors.title = strings.error.titleRequired
        }

        if (dateInvalid) {
            errors.date = strings.error.dateRequired
        }

        if (startInvalid) {
            errors.start = strings.error.startRequired
        }

        if (endInvalid) {
            errors.end = strings.error.endInvalid
        }

        const start = date.set('hour', startTime.hour()).set('minutes', startTime.minute()).set('seconds', 0)

        if (!dateInvalid && !startInvalid) {
            if (date.isBefore(dayjs(), 'date')) {
                errors.date = strings.error.dateInvalid
            } else if (start.isBefore(dayjs(), 'minutes')) {
                errors.start = strings.error.startInvalid
            }
        }

        let end: Dayjs | null = null
        if (endTime !== null) {
            end = date.set('hour', endTime.hour()).set('minutes', endTime.minute()).set('seconds', 0)
        }

        if (end !== null && !end.isAfter(start, 'minutes')) {
            errors.end = strings.error.endInvalid
        }

        setErrors(errors)

        if (!_.isEmpty(errors)) {
            return
        }

        const request = new TaskRequest(
            statusId!!,
            new DateTime(start.toDate()),
            end !== null ? new DateTime(end.toDate()) : null,
            title,
            description.length > 0 ? description : null
        )

        appDispatch(setInProgress())

        const promise: Promise<TaskDetails> = task !== null ? API.tasks.edit(task.id, request) : API.tasks.add(userId, request)

        promise
            .then((response) => {
                onSuccess(response)
                appDispatch(setSuccess())
                closeDialog()
            })
            .catch((error) => {
                appDispatch(setFailure())
                appDispatch(handleApiError(error))
            })
    }

    return (
        <Dialog
            open={open}
            disableEscapeKeyDown={true}
        >
            <DialogTitle>{strings.label.title(task !== null)}</DialogTitle>
            <DialogContent>
                <DialogContentText>&nbsp;</DialogContentText>
                <Stack
                    direction="column"
                    spacing={2}
                >
                    <FormControl
                        fullWidth={true}
                        error={errors.status !== undefined}
                    >
                        <InputLabel>{strings.label.status}</InputLabel>
                        <Select
                            id={FormFieldNames.Status}
                            name={FormFieldNames.Status}
                            value={statusId?.toString() ?? ''}
                            label={strings.label.status}
                            onChange={onStatusChange}
                        >
                            {statuses.map(
                                (value: Status, index: number): React.ReactNode => (
                                    <MenuItem
                                        key={index}
                                        value={value.id}
                                    >
                                        {value.name}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                        {!!errors.status && <FormHelperText>{errors.status}</FormHelperText>}
                    </FormControl>

                    <DatePicker
                        label={strings.label.date}
                        format="YYYY-MM-DD"
                        value={date}
                        disablePast={true}
                        slotProps={{
                            textField: {
                                helperText: errors.date,
                                error: errors.date !== undefined,
                            },
                        }}
                        onChange={onDateChange}
                    />
                    <Stack
                        mt={2}
                        direction="row"
                        alignItems="stretch"
                        spacing={2}
                    >
                        <TimePicker
                            ampm={false}
                            ampmInClock={false}
                            label={strings.label.start}
                            format="HH:mm"
                            minutesStep={1}
                            timeSteps={{ hours: 1, minutes: 1 }}
                            sx={{ width: '100%' }}
                            onChange={onStartTimeChange}
                            value={startTime}
                            slotProps={{
                                textField: {
                                    helperText: errors.start,
                                    error: errors.start !== undefined,
                                },
                            }}
                        />

                        <TimePicker
                            ampm={false}
                            sx={{ width: '100%' }}
                            ampmInClock={false}
                            label={strings.label.end}
                            format="HH:mm"
                            minutesStep={1}
                            timeSteps={{ hours: 1, minutes: 1 }}
                            onChange={onEndTimeChange}
                            slotProps={{
                                textField: {
                                    helperText: errors.end,
                                    error: errors.end !== undefined,
                                },
                            }}
                            value={endTime}
                        />
                    </Stack>

                    <TextField
                        id={FormFieldNames.Title}
                        name={FormFieldNames.Title}
                        value={title}
                        multiline={false}
                        required={false}
                        fullWidth={true}
                        label={strings.label.taskTitle}
                        margin="none"
                        onChange={onTextFieldChange}
                        helperText={errors.title}
                        error={errors.title !== undefined}
                    />

                    <TextField
                        id={FormFieldNames.Description}
                        name={FormFieldNames.Description}
                        value={description}
                        multiline={true}
                        required={false}
                        fullWidth={true}
                        label={strings.label.taskDescription}
                        margin="none"
                        onChange={onTextFieldChange}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>{strings.button.cancel}</Button>
                <Button onClick={handleSaveClick}>{strings.button.save}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(TaskDialog)
