import React from 'react'
import { Navigate } from 'react-router'
import { useStateContext } from '../state/stateContext'
import { AccountHasAccess, routesDetails } from './routesDetails'

interface OwnProps {
    children: React.ReactElement
    hasAccess: AccountHasAccess
}

const AuthenticatedRoute = ({ children, hasAccess }: OwnProps): React.ReactElement => {
    const { sessionState } = useStateContext()

    if (sessionState.auth === null || sessionState.account === null) {
        return <Navigate to={routesDetails.guest.login.path} />
    } else if (!hasAccess(sessionState.account)) {
        return (
            <Navigate
                to={routesDetails.error.unauthorized.path}
                replace={true}
            />
        )
    }

    return children
}

export default React.memo(AuthenticatedRoute)
