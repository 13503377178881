import { Reducer } from 'react'
import { Action } from './stateContext'

enum MessageActionType {
    ShowMessage = 'ShowMessage',
    ShowDialogMessage = 'ShowDialogMessage',
    ShowSnackbarMessage = 'ShowSnackbarMessage',
    HideMessage = 'HideMessage',
}

interface ShowMessageAction extends Action {
    type: MessageActionType.ShowMessage
    message: Message
}

interface ShowDialogMessageAction extends Action {
    type: MessageActionType.ShowDialogMessage
    message: string
    handler?: () => void
}

interface ShowSnackbarMessageAction extends Action {
    type: MessageActionType.ShowSnackbarMessage
    message: string
    handler?: () => void
}

interface HideMessageAction extends Action {
    type: MessageActionType.HideMessage
}

type MessageAction = ShowMessageAction | ShowDialogMessageAction | ShowSnackbarMessageAction | HideMessageAction

export const showMessage = (message: Message): ShowMessageAction => ({
    type: MessageActionType.ShowMessage,
    message,
})
export const showDialogMessage = (message: string, handler?: () => void): ShowDialogMessageAction => ({
    type: MessageActionType.ShowDialogMessage,
    message,
    handler,
})
export const showSnackbarMessage = (message: string, handler?: () => void): ShowSnackbarMessageAction => ({
    type: MessageActionType.ShowSnackbarMessage,
    message,
    handler,
})
export const hideMessage = (): HideMessageAction => ({ type: MessageActionType.HideMessage })

export enum MessageDisplayType {
    Dialog,
    Snackbar,
}

export interface Message {
    message: string
    type: MessageDisplayType
    handler?: () => void
}

type MessageState = Message | null

export const messageReducer: Reducer<MessageState, MessageAction> = (state: MessageState, action: MessageAction): MessageState => {
    switch (action.type) {
        case MessageActionType.ShowMessage: {
            return action.message
        }
        case MessageActionType.ShowDialogMessage: {
            return { message: action.message, type: MessageDisplayType.Dialog, handler: action.handler }
        }
        case MessageActionType.ShowSnackbarMessage: {
            return { message: action.message, type: MessageDisplayType.Snackbar, handler: action.handler }
        }
        case MessageActionType.HideMessage: {
            return null
        }
        default: {
            return state
        }
    }
}
