import {
    Button,
    CircularProgress,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
    styled,
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { API } from '../../api/api'
import { MenuItemType } from '../../model/MenuItemType'
import { changeMenuItem } from '../../state/menuItemActions'
import { setTitle } from '../../state/titleActions'
import AddIcon from '@mui/icons-material/Add'
import { useStateDispatchContext } from '../../state/stateContext'
import { handleApiError } from '../../state/apiErrorActions'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { useParams } from 'react-router'
import UserBranchTableRow from './UserBranchTableRow'
import { UserBranch } from '../../api/response'
import UserBranchDialog, { UserBranchDialogData } from './UserBranchDialog'

const strings = {
    title: 'Oddziały dodatkowe',
    button: {
        refresh: 'Odśwież',
        create: 'Dodaj',
    },
    column: {
        id: 'Id',
        name: 'Oddział',
        period: 'Okres',
        actions: 'Akcje',
    },
    error: {
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
}))

const UserBranchesPage: React.FunctionComponent = () => {
    const { appDispatch } = useStateDispatchContext()
    const { id } = useParams()

    const [userBranches, setUserBranches] = useState<UserBranch[] | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const [dialogData, setDialogData] = useState<UserBranchDialogData | null>(null)

    const userId = useMemo(() => {
        return parseInt(id ?? '0', 10)
    }, [id])

    const loadUserBranches = useCallback(() => {
        if (loading) {
            return
        }

        setLoading(true)
        setRefresh(false)
        API.userBranches
            .list(userId)
            .then((newUserBranches) => {
                setUserBranches(newUserBranches)
            })
            .catch((error) => {
                setRefresh(true)
                appDispatch(handleApiError(error))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [userId, userBranches, loading])

    const openAddDialog = useCallback(() => {
        setDialogOpen(true)
        setDialogData({
            userId: userId,
            userBranch: null,
            onSuccess() {
                setUserBranches(null)
                loadUserBranches()
            },
        })
    }, [userId])

    const openEditDialog = useCallback(
        (userBranch: UserBranch): void => {
            setDialogOpen(true)
            setDialogData({
                userId: userId,
                userBranch: userBranch,
                onSuccess(updatedUserBranch) {
                    setUserBranches((userBranches) => {
                        if (userBranches === null) {
                            return null
                        }

                        return userBranches.map((value) => {
                            if (value.id === updatedUserBranch.id) {
                                return updatedUserBranch
                            }

                            return value
                        })
                    })
                },
            })
        },
        [userId]
    )

    const deleteUserBranch = useCallback(
        (additionalBranch: UserBranch): void => {
            appDispatch(setInProgress())
            API.userBranches
                .delete(userId, additionalBranch.id)
                .then(() => {
                    setUserBranches((value) => {
                        if (value === null) {
                            return value
                        }
                        return value.filter((value) => value.id !== additionalBranch.id)
                    })
                    appDispatch(setSuccess())
                })
                .catch((error) => {
                    appDispatch(handleApiError(error))
                    appDispatch(setFailure())
                })
        },
        [userId]
    )

    const closeDialog = useCallback(() => {
        setDialogOpen(false)
    }, [])

    useEffect(() => {
        appDispatch(changeMenuItem(MenuItemType.Users))
        appDispatch(setTitle(strings.title))
    }, [])

    useEffect(() => {
        loadUserBranches()
    }, [])

    return (
        <>
            <StyledPaper elevation={2}>
                <Toolbar>
                    <Tooltip title={strings.button.create}>
                        <IconButton onClick={openAddDialog}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>

                {!!userBranches && (
                    <Table size="small">
                        <colgroup>
                            <col width="20%" />
                            <col width="40%" />
                            <col width="20%" />
                            <col width="20%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>{strings.column.id}</TableCell>
                                <TableCell>{strings.column.name}</TableCell>
                                <TableCell>{strings.column.period}</TableCell>
                                <TableCell>{strings.column.actions}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userBranches.map((additionalBranch, index) => (
                                <UserBranchTableRow
                                    key={index}
                                    additionalBranch={additionalBranch}
                                    editUserBranch={openEditDialog}
                                    deleteUserBranch={deleteUserBranch}
                                />
                            ))}
                        </TableBody>
                    </Table>
                )}

                {loading && (
                    <Stack
                        direction="row"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Stack>
                )}

                {refresh && !loading && (
                    <Stack
                        direction="row"
                        justifyContent="center"
                    >
                        <Button
                            onClick={loadUserBranches}
                            variant="contained"
                        >
                            {strings.button.refresh}
                        </Button>
                    </Stack>
                )}
            </StyledPaper>
            {!!dialogData && (
                <UserBranchDialog
                    open={dialogOpen}
                    data={dialogData}
                    closeDialog={closeDialog}
                />
            )}
        </>
    )
}

export default React.memo(UserBranchesPage)
