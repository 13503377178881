import { Divider, Drawer, List, styled } from '@mui/material'
import React from 'react'
import BusinessIcon from '@mui/icons-material/BusinessOutlined'
import WorkIcon from '@mui/icons-material/WorkOutlineOutlined'
import ScheduleIcon from '@mui/icons-material/ScheduleOutlined'
import TaskIcon from '@mui/icons-material/TaskAltOutlined'
import UserIcon from '@mui/icons-material/PersonOutlined'
import MapIcon from '@mui/icons-material/MapOutlined'
import TodayIcon from '@mui/icons-material/TodayOutlined'
import LabelIcon from '@mui/icons-material/LabelOutlined'
import CarIcon from '@mui/icons-material/DirectionsCarOutlined'
import ForbiddenPlaceIcon from '@mui/icons-material/LocationOffOutlined'
import { MenuItemType } from '../model/MenuItemType'
import DrawerListItem from './DrawerListItem'
import { routesDetails } from '../routes/routesDetails'
import { useStateContext } from '../state/stateContext'
import { useStableNavigate } from '../routes/StableNavigateContext'

export const drawerWidth = 260
export const drawerWidthCollapsed = 60

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    flexShrink: 0,
    width: drawerWidth,
    [theme.breakpoints.down('md')]: {
        width: drawerWidthCollapsed,
    },
    '& .MuiPaper-root': {
        width: drawerWidth,
        [theme.breakpoints.down('md')]: {
            width: drawerWidthCollapsed,
        },
    },
}))

const ToolbarDiv = styled('div')(({ theme }) => ({
    ...theme.mixins.toolbar,
}))

const AppDrawer: React.FunctionComponent = () => {
    const navigate = useStableNavigate()
    const { appState, sessionState } = useStateContext()
    const { menuItem } = appState

    const account = sessionState.account!
    const groups = React.useMemo(
        () =>
            [
                [
                    {
                        icon: BusinessIcon,
                        menuItem: MenuItemType.Branches,
                        path: routesDetails.authenticated.branches.path,
                        hasAccess: routesDetails.authenticated.branches.hasAccess(account),
                    },
                    {
                        icon: WorkIcon,
                        menuItem: MenuItemType.JobPositions,
                        path: routesDetails.authenticated.jobPositions.path,
                        hasAccess: routesDetails.authenticated.jobPositions.hasAccess(account),
                    },
                    {
                        icon: CarIcon,
                        menuItem: MenuItemType.Cars,
                        path: routesDetails.authenticated.cars.path,
                        hasAccess: routesDetails.authenticated.cars.hasAccess(account),
                    },
                    {
                        icon: ForbiddenPlaceIcon,
                        menuItem: MenuItemType.ForbiddenPlaces,
                        path: routesDetails.authenticated.forbiddenPlaces.path,
                        hasAccess: routesDetails.authenticated.forbiddenPlaces.hasAccess(account),
                    },
                    {
                        icon: ScheduleIcon,
                        menuItem: MenuItemType.Schedule,
                        path: routesDetails.authenticated.schedule.path,
                        hasAccess: routesDetails.authenticated.schedule.hasAccess(account),
                    },
                    {
                        icon: TaskIcon,
                        menuItem: MenuItemType.Tasks,
                        path: routesDetails.authenticated.tasks.path,
                        hasAccess: routesDetails.authenticated.tasks.hasAccess(account),
                    },
                    {
                        icon: UserIcon,
                        menuItem: MenuItemType.Users,
                        path: routesDetails.authenticated.users.path,
                        hasAccess: routesDetails.authenticated.users.hasAccess(account),
                    },
                    {
                        icon: MapIcon,
                        menuItem: MenuItemType.Map,
                        path: routesDetails.authenticated.map.path,
                        hasAccess: routesDetails.authenticated.map.hasAccess(account),
                    },
                    {
                        icon: LabelIcon,
                        menuItem: MenuItemType.Statuses,
                        path: routesDetails.authenticated.statuses.path,
                        hasAccess: routesDetails.authenticated.statuses.hasAccess(account),
                    },
                    {
                        icon: TodayIcon,
                        menuItem: MenuItemType.Holidays,
                        path: routesDetails.authenticated.holidays.path,
                        hasAccess: routesDetails.authenticated.holidays.hasAccess(account),
                    },
                ].filter((item) => item.hasAccess),
            ].filter((group) => group.length > 0),
        [sessionState.account]
    )

    const onChange = React.useCallback((_: MenuItemType, path: string): void => {
        navigate(path)
    }, [])

    return (
        <StyledDrawer variant="permanent">
            <ToolbarDiv />
            {groups.map((group, groupIndex) => (
                <React.Fragment key={groupIndex}>
                    <List>
                        {group.map((item, itemIndex) => (
                            <DrawerListItem
                                key={itemIndex}
                                onChange={onChange}
                                menuItem={item.menuItem}
                                isSelected={menuItem === item.menuItem}
                                SvgIcon={item.icon}
                                path={item.path}
                            />
                        ))}
                    </List>
                    {groupIndex < groups.length - 1 && <Divider />}
                </React.Fragment>
            ))}
        </StyledDrawer>
    )
}

export default React.memo(AppDrawer)
