export interface Status {
    readonly id: number
    readonly name: string
    readonly requiredPhotos: number
    readonly isMain: boolean
    readonly flags: StatusFlag[]
}

export enum StatusFlag {
    Main = 'main',
    EndShiftRelocation = 'end_shift_relocation',
    ExtendTimeWhenOutsideBranch = 'extend_time_when_outside_branch',
}
