import { GeoPoint, geoPointLatLngLiteral } from './GeoPoint'

export interface Branch {
    readonly id: number
    readonly name: string
    readonly range: number
    readonly location: GeoPoint
    readonly outsideBranchMaxTime: number
    readonly returnMaxTime: number
    readonly isActive: boolean
}

export const branchLatLngLiteral: (branch: Branch) => google.maps.LatLngLiteral = (branch): google.maps.LatLngLiteral =>
    geoPointLatLngLiteral(branch.location)
