import React from 'react'
import { createRoot } from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import HttpsRedirect from './components/HttpsRedirect'
import App from './modules/app/App'

const root = createRoot(document.getElementById('root')!)

root.render(
    <React.StrictMode>
        <HttpsRedirect>
            <App />
        </HttpsRedirect>
    </React.StrictMode>
)

reportWebVitals()
