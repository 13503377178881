import { DateTime } from '../DateTime'

export class TaskRequest {
    constructor(
        readonly statusId: number,
        readonly start: DateTime,
        readonly end: DateTime | null,
        readonly title: string,
        readonly description: string | null
    ) {}
}
