import React from 'react'
import { isLocalhost } from '../utils/isLocalhost'

interface ChildrenProps {
    children: React.ReactElement
}

const HttpsRedirect: React.FunctionComponent<ChildrenProps> = ({ children }) => {
    if (window.location.protocol === 'http:' && !isLocalhost(window.location.hostname)) {
        window.location.protocol = 'https:'

        return null
    }

    return children
}

export default React.memo(HttpsRedirect)
