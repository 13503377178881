import { Reducer } from 'react'
import { MenuItemType } from '../model/MenuItemType'
import { Action } from './stateContext'

enum MenuItemActionType {
    ChangeMenuItem = 'ChangeMenuItem',
    ClearMenuItem = 'ClearMenuItem',
}

interface ChangeMenuItemAction extends Action {
    type: MenuItemActionType.ChangeMenuItem
    menuItem: MenuItemType
}

interface ClearMenuItemAction extends Action {
    type: MenuItemActionType.ClearMenuItem
}

type MenuItemAction = ChangeMenuItemAction | ClearMenuItemAction

export const changeMenuItem = (menuItem: MenuItemType): ChangeMenuItemAction => ({
    type: MenuItemActionType.ChangeMenuItem,
    menuItem,
})
export const clearMenuItem = (): ClearMenuItemAction => ({ type: MenuItemActionType.ClearMenuItem })

type MenuItemState = MenuItemType | null

export const menuItemReducer: Reducer<MenuItemState, MenuItemAction> = (state: MenuItemState, action: MenuItemAction): MenuItemState => {
    switch (action.type) {
        case MenuItemActionType.ChangeMenuItem: {
            return action.menuItem
        }
        case MenuItemActionType.ClearMenuItem: {
            return null
        }
        default: {
            return state
        }
    }
}
