import { Reducer } from 'react'
import { Action } from './stateContext'
import { ApiError } from '../api/ApiError'

enum ApiErrorActionType {
    HandleApiError = 'HandleApiError',
    ClearApiError = 'ClearApiError',
}

interface HandleApiErrorAction extends Action {
    type: ApiErrorActionType.HandleApiError
    error: ApiError
}

interface ClearApiErrorAction extends Action {
    type: ApiErrorActionType.ClearApiError
}

type ApiErrorAction = HandleApiErrorAction | ClearApiErrorAction

export const handleApiError = (error: ApiError): HandleApiErrorAction => ({
    type: ApiErrorActionType.HandleApiError,
    error,
})
export const clearApiError = (): ClearApiErrorAction => ({ type: ApiErrorActionType.ClearApiError })

type ApiErrorState = ApiError | null

export const apiErrorReducer: Reducer<ApiErrorState, ApiErrorAction> = (state: ApiErrorState, action: ApiErrorAction): ApiErrorState => {
    switch (action.type) {
        case ApiErrorActionType.HandleApiError: {
            return action.error
        }
        case ApiErrorActionType.ClearApiError: {
            return null
        }
        default: {
            return state
        }
    }
}
