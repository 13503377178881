import {
    Button,
    CircularProgress,
    IconButton,
    Paper,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { API } from '../../api/api'
import { Status } from '../../api/response'
import { MenuItemType } from '../../model/MenuItemType'
import { changeMenuItem } from '../../state/menuItemActions'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { setTitle } from '../../state/titleActions'
import AddIcon from '@mui/icons-material/Add'
import { BottomScrollListener } from 'react-bottom-scroll-listener'
import StatusTableRow from './StatusTableRow'
import { routesDetails } from '../../routes/routesDetails'
import { useStateDispatchContext } from '../../state/stateContext'
import { useStableNavigate } from '../../routes/StableNavigateContext'
import { handleApiError } from '../../state/apiErrorActions'

const strings = {
    button: {
        refresh: 'Odśwież',
        create: 'Utwórz',
    },
    column: {
        id: 'Id',
        name: 'Nazwa',
        requiredPhotos: 'Wymagane zdjęcia',
        details: 'Szczegóły',
        actions: 'Akcje',
    },
    error: {
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
}))

const StatusesPage: React.FunctionComponent = () => {
    const { appDispatch } = useStateDispatchContext()
    const navigate = useStableNavigate()

    const [statuses, setStatuses] = useState<Status[] | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadMore, setLoadMore] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)

    const loadStatuses = useCallback(() => {
        if (loading) {
            return
        }

        const limit = 100

        setLoading(true)
        setRefresh(false)
        API.statuses
            .list(limit, statuses?.length)
            .then((newStatuses) => {
                if (statuses === null) {
                    setStatuses(newStatuses)
                } else {
                    setStatuses(statuses.concat(newStatuses))
                }
                setLoadMore(newStatuses.length === limit)
            })
            .catch((error) => {
                setRefresh(true)
                appDispatch(handleApiError(error))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [statuses, loading])

    const addStatus = useCallback((): void => {
        navigate(routesDetails.authenticated.status.to(0))
    }, [])

    const editStatus = useCallback((status: Status): void => {
        navigate(routesDetails.authenticated.status.to(status.id))
    }, [])

    const deleteStatus = useCallback((status: Status): void => {
        appDispatch(setInProgress())
        API.statuses
            .delete(status.id)
            .then(() => {
                setStatuses((value) => {
                    if (value === null) {
                        return value
                    }
                    return value.filter((value) => value.id !== status.id)
                })
                appDispatch(setSuccess())
            })
            .catch((error) => {
                appDispatch(handleApiError(error))
                appDispatch(setFailure())
            })
    }, [])

    useEffect(() => {
        appDispatch(changeMenuItem(MenuItemType.Statuses))
        appDispatch(setTitle(MenuItemType.Statuses))
    }, [])

    useEffect(() => {
        loadStatuses()
    }, [])

    return (
        <BottomScrollListener
            // eslint-disable-next-line no-empty-function
            onBottom={loadMore ? loadStatuses : (): void => {}}
            offset={300}
            debounce={100}
            debounceOptions={{ leading: false, trailing: true }}
            triggerOnNoScroll={false}
        >
            <StyledPaper elevation={2}>
                <Toolbar>
                    <Tooltip title={strings.button.create}>
                        <IconButton onClick={addStatus}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>

                {!!statuses && (
                    <Table size="small">
                        <colgroup>
                            <col width="10%" />
                            <col width="40%" />
                            <col width="15%" />
                            <col width="15%" />
                            <col width="20%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>{strings.column.id}</TableCell>
                                <TableCell>{strings.column.name}</TableCell>
                                <TableCell>{strings.column.requiredPhotos}</TableCell>
                                <TableCell>{strings.column.details}</TableCell>
                                <TableCell>{strings.column.actions}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {statuses.map((status, index) => (
                                <StatusTableRow
                                    key={index}
                                    status={status}
                                    editStatus={editStatus}
                                    deleteStatus={deleteStatus}
                                />
                            ))}
                        </TableBody>
                    </Table>
                )}

                {loading && (
                    <Stack
                        direction="row"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Stack>
                )}

                {refresh && !loading && (
                    <Stack
                        direction="row"
                        justifyContent="center"
                    >
                        <Button
                            onClick={loadStatuses}
                            variant="contained"
                        >
                            {strings.button.refresh}
                        </Button>
                    </Stack>
                )}
            </StyledPaper>
        </BottomScrollListener>
    )
}

export default React.memo(StatusesPage)
