import { Account, UserRole, hasOneOfRoles, hasRole } from '../api/response'
import HomePage from '../modules/app/HomePage'
import BranchDetailsPage from '../modules/branches/BranchDetailsPage'
import BranchesPage from '../modules/branches/BranchesPage'
import CarDetailsPage from '../modules/car/CarDetailsPage'
import CarsPage from '../modules/car/CarsPage'
import NotFoundPage from '../modules/error/NotFoundPage'
import UnauthorizedPage from '../modules/error/UnauthorizedPage'
import ForbiddenPlaceDetailsPage from '../modules/forbiddenPlaces/ForbiddenPlaceDetailsPage'
import ForbiddenPlacesPage from '../modules/forbiddenPlaces/ForbiddenPlacesPage'
import HolidayDetailsPage from '../modules/holidays/HolidayDetailsPage'
import HolidaysPage from '../modules/holidays/HolidaysPage'
import JobPositionDetailsPage from '../modules/jobPositions/JobPositionDetailsPage'
import JobPositionsPage from '../modules/jobPositions/JobPositionsPage'
import LoginPage from '../modules/login/LoginPage'
import SchedulePage from '../modules/schedule/SchedulePage'
import StatusDetailsPage from '../modules/statueses/StatusDetailsPage'
import StatusesPage from '../modules/statueses/StatusesPage'
import TasksPage from '../modules/tasks/TasksPage'
import UserBranchesPage from '../modules/userBranches/UserBranchesPage'
import UserDayPage from '../modules/users/UserDayPage'
import UserDetailsPage from '../modules/users/UserDetailsPage'
import UserPasswordPage from '../modules/users/UserPasswordPage'
import UsersPage from '../modules/users/UsersPage'

export type AccountHasAccess = (account: Account) => boolean

export const routesDetails = {
    authenticated: {
        homePage: {
            Component: HomePage,
            path: '/',
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.Panel),
        },
        branches: {
            Component: BranchesPage,
            path: '/branches',
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ManageBranches),
        },
        branch: {
            Component: BranchDetailsPage,
            path: '/branches/:id',
            to: (branchId: number): string => `/branches/${branchId}`,
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ManageBranches),
        },
        statuses: {
            Component: StatusesPage,
            path: '/statuses',
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ManageStatuses),
        },
        status: {
            Component: StatusDetailsPage,
            path: '/statuses/:id',
            to: (statusId: number): string => `/statuses/${statusId}`,
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ManageStatuses),
        },
        holidays: {
            Component: HolidaysPage,
            path: '/holidays',
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ManageHolidays),
        },
        holiday: {
            Component: HolidayDetailsPage,
            path: '/holidays/:id',
            to: (holidayId: number): string => `/holidays/${holidayId}`,
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ManageHolidays),
        },
        cars: {
            Component: CarsPage,
            path: '/cars',
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ManageCars),
        },
        car: {
            Component: CarDetailsPage,
            path: '/cars/:id',
            to: (holidayId: number): string => `/cars/${holidayId}`,
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ManageCars),
        },
        jobPositions: {
            Component: JobPositionsPage,
            path: '/jobpositions',
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ManageJobPositions),
        },
        jobPosition: {
            Component: JobPositionDetailsPage,
            path: '/jobpositions/:id',
            to: (jobPositionId: number): string => `/jobpositions/${jobPositionId}`,
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ManageJobPositions),
        },
        forbiddenPlaces: {
            Component: ForbiddenPlacesPage,
            path: '/forbiddenplaces',
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ManageForbiddenPlaces),
        },
        forbiddenPlace: {
            Component: ForbiddenPlaceDetailsPage,
            path: '/forbiddenplaces/:id',
            to: (jobPositionId: number): string => `/forbiddenplaces/${jobPositionId}`,
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ManageForbiddenPlaces),
        },
        schedule: {
            Component: SchedulePage,
            path: '/schedule',
            hasAccess: (account: Account): boolean =>
                hasOneOfRoles(account, [
                    UserRole.ManageBranchSchedules,
                    UserRole.ManageAllSchedules,
                    UserRole.ViewBranchSchedules,
                    UserRole.ViewAllSchedules,
                ]),
        },
        tasks: {
            Component: TasksPage,
            path: '/tasks',
            hasAccess: (account: Account): boolean => hasOneOfRoles(account, [UserRole.ManageBranchTasks, UserRole.ManageAllTasks]),
        },
        users: {
            Component: UsersPage,
            path: '/users',
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ManageUsers),
        },
        user: {
            Component: UserDetailsPage,
            path: '/users/:id',
            to: (userId: number): string => `/users/${userId}`,
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ManageUsers),
        },
        userPassword: {
            Component: UserPasswordPage,
            path: '/users/:id/password',
            to: (userId: number): string => `/users/${userId}/password`,
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ManageUsers),
        },
        userMap: {
            Component: UserDayPage,
            path: '/users/:id/map',
            to: (userId: number): string => `/users/${userId}/map`,
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ShowUserMap),
        },
        userBranches: {
            Component: UserBranchesPage,
            path: '/users/:id/branches',
            to: (userId: number): string => `/users/${userId}/branches`,
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ManageAdditionalBranches),
        },
        map: {
            Component: UserDayPage,
            path: '/map',
            hasAccess: (account: Account): boolean => hasRole(account, UserRole.ShowUserMap),
        },
    },
    error: {
        notFound: {
            Component: NotFoundPage,
            path: '/error/404',
        },
        unauthorized: {
            Component: UnauthorizedPage,
            path: '/error/401',
        },
    },
    guest: {
        login: {
            Component: LoginPage,
            path: '/login',
        },
    },
}
